import { CurrencyEntity } from 'graphql/generated';
import { CurrencyType } from 'types/baseTypes';

export const getIdCurrency = (
  allCurrency: CurrencyEntity[],
  currencyName: CurrencyType,
) => {
  const targetRow = allCurrency?.find((i) => i.name === currencyName);
  return targetRow ? +targetRow.id : 1;
};
