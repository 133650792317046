import { Box, styled } from '@mui/material';

export const AmountBtnGroupCSS = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '6px',
  border: '1px solid rgba(150, 160, 181, 0.4)',
  height: '40px',
  width: '113px',
  userSelect: 'none',
  [theme.breakpoints.down('laptop')]: {
    height: '30px',
    width: '95px',
  },
}));
export const AmountBtn = styled(Box)(({ theme }) => ({
  width: '34px',
  height: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('laptop')]: {
    width: '28px',
  },
}));

export const AmountCountField = styled(Box)(({ theme }) => ({
  padding: '0 5px',
  minWidth: '43px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  borderInlineEnd: '1px solid rgba(150, 160, 181, 0.4)',
  borderInlineStart: '1px solid rgba(150, 160, 181, 0.4)',
  [theme.breakpoints.down('laptop')]: {
    minWidth: '37px',
  },
}));
