import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { CartEmptyIcon } from 'components/icons';
import { Wrapper } from './EmptyCart.styled';
import { useRouter } from 'next/router';
import { PATHS } from 'utils';

export const EmptyCart = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleClick = () => {
    router.push(PATHS.home);
  };

  return (
    <Wrapper>
      <Box sx={{ mb: '19px', textAlign: 'center' }}>
        <CartEmptyIcon />
      </Box>
      <Typography
        mb={'10px'}
        variant={'big350'}
      >{t`app.cart.cart_is_empty`}</Typography>
      <Typography mb={'20px'} variant={'average400'} color={'#96A0B5'}>
        {t`app.cart.lets_get_to_shopping`}.
      </Typography>
      <Button
        onClick={handleClick}
        sx={{ width: '100%' }}
      >{t`app.go_to_products`}</Button>
    </Wrapper>
  );
};
