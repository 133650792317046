import React from 'react';
import { Button } from '@mui/material';
import {
  BottomNavigate,
  ButtonCart,
  InnerWrapper,
} from './BottomCartNavigate.styled';
import { ArrowBackIcon, ArrowIcon } from 'components/icons';
import { useTranslation } from 'next-i18next';
import { CircularProgressCustom } from 'components';
import { useRouter } from 'next/router';

interface BottomCartNavigateProps {
  loading: boolean;
  onClickCheckout: () => void;
  disabledCheckoutBtn?: boolean;
}

export const BottomCartNavigate = ({
  loading,
  onClickCheckout,
  disabledCheckoutBtn = false,
}: BottomCartNavigateProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const goBack = () => {
    router.back();
  };

  return (
    <BottomNavigate>
      <InnerWrapper>
        <ButtonCart
          startIcon={<ArrowBackIcon />}
          variant={'back'}
          sx={{ height: '40px' }}
          onClick={goBack}
        >
          {t('app.cart.continue_shopping')}
        </ButtonCart>
        <Button
          onClick={onClickCheckout}
          disabled={disabledCheckoutBtn}
          endIcon={
            loading ? (
              <CircularProgressCustom loading={true} color={'#fff'} />
            ) : (
              <ArrowIcon />
            )
          }
          size={'small'}
        >
          {t('app.cart.checkout')}
        </Button>
      </InnerWrapper>
    </BottomNavigate>
  );
};
