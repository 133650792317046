import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { client } from 'graphql/client';
import { ThemeProvider } from '@mui/system';
import { theme } from 'theme';
import { CssBaseline } from '@mui/material';
import 'utils/i18n';
import {
  AlertProvider,
  AuthProvider,
  CartProvider,
  ModalProvider,
} from 'context';
import { LayoutProvider, Meta } from 'components';
import { IntlProvider } from 'context/IntlContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { SiteIdProvider } from 'context/SiteIdContext';
import { GOOGLE_AUTH_CLIENT_KEY } from 'utils';
import { appWithTranslation } from 'next-i18next';
import { Poppins } from 'next/font/google';

const font = Poppins({
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  subsets: ['latin'],
  display: 'swap',
});

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${font.style.fontFamily};
        }
      `}</style>

      <Meta {...pageProps}>
        <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_KEY}>
          <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
              <CssBaseline enableColorScheme />
              <SiteIdProvider>
                <AuthProvider>
                  <IntlProvider>
                    <CartProvider>
                      <ModalProvider>
                        <AlertProvider>
                          <LayoutProvider>
                            <Component {...pageProps} />
                          </LayoutProvider>
                        </AlertProvider>
                      </ModalProvider>
                    </CartProvider>
                  </IntlProvider>
                </AuthProvider>
              </SiteIdProvider>
            </ThemeProvider>
          </ApolloProvider>
        </GoogleOAuthProvider>
      </Meta>
    </>
  );
}

export default appWithTranslation(App);
