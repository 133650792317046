export const ClipboardCopyIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 2.75C8.86193 2.75 8.75 2.86193 8.75 3V5C8.75 5.13807 8.86193 5.25 9 5.25H15C15.1381 5.25 15.25 5.13807 15.25 5V3C15.25 2.86193 15.1381 2.75 15 2.75H9ZM16.75 3.25V3C16.75 2.0335 15.9665 1.25 15 1.25H9C8.0335 1.25 7.25 2.0335 7.25 3V3.25H6C5.27065 3.25 4.57118 3.53973 4.05546 4.05546C3.53973 4.57118 3.25 5.27065 3.25 6V20C3.25 20.7293 3.53973 21.4288 4.05546 21.9445C4.57118 22.4603 5.27065 22.75 6 22.75H18C18.7293 22.75 19.4288 22.4603 19.9445 21.9445C20.4603 21.4288 20.75 20.7293 20.75 20V18C20.75 17.5858 20.4142 17.25 20 17.25C19.5858 17.25 19.25 17.5858 19.25 18V20C19.25 20.3315 19.1183 20.6495 18.8839 20.8839C18.6495 21.1183 18.3315 21.25 18 21.25H6C5.66848 21.25 5.35054 21.1183 5.11612 20.8839C4.8817 20.6495 4.75 20.3315 4.75 20V6C4.75 5.66848 4.8817 5.35054 5.11612 5.11612C5.35054 4.8817 5.66848 4.75 6 4.75H7.25V5C7.25 5.9665 8.0335 6.75 9 6.75H15C15.9665 6.75 16.75 5.9665 16.75 5V4.75H18C18.3315 4.75 18.6495 4.8817 18.8839 5.11612C19.1183 5.35054 19.25 5.66848 19.25 6V10C19.25 10.4142 19.5858 10.75 20 10.75C20.4142 10.75 20.75 10.4142 20.75 10V6C20.75 5.27065 20.4603 4.57118 19.9445 4.05546C19.4288 3.53973 18.7293 3.25 18 3.25H16.75ZM15.5303 9.46967C15.8232 9.76256 15.8232 10.2374 15.5303 10.5303L12.8107 13.25H21C21.4142 13.25 21.75 13.5858 21.75 14C21.75 14.4142 21.4142 14.75 21 14.75H12.8107L15.5303 17.4697C15.8232 17.7626 15.8232 18.2374 15.5303 18.5303C15.2374 18.8232 14.7626 18.8232 14.4697 18.5303L10.4697 14.5303C10.1768 14.2374 10.1768 13.7626 10.4697 13.4697L14.4697 9.46967C14.7626 9.17678 15.2374 9.17678 15.5303 9.46967Z'
        fill='#96A0B5'
      />
    </svg>
  );
};
