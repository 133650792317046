import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const GameIcon = ({ color = '#96A0B5' }: IconsTypes) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.15244 11.7587C1.57188 9.49504 3.54644 7.85275 5.84861 7.85275H18.1294C20.4315 7.85275 22.4061 9.49504 22.8255 11.7587L23.8996 17.5552C24.4093 20.306 22.2979 22.8446 19.5003 22.8446C17.1261 22.8446 15.1652 20.99 15.0331 18.6195L15.0204 18.3919H8.95762L8.94493 18.6195C8.81274 20.99 6.85192 22.8446 4.47767 22.8446C1.68008 22.8446 -0.431353 20.306 0.0783573 17.5552L1.15244 11.7587ZM5.84861 9.35275C4.26947 9.35275 2.91504 10.4793 2.62733 12.032L1.55325 17.8285C1.21442 19.657 2.61798 21.3446 4.47767 21.3446C6.05594 21.3446 7.35938 20.1118 7.44725 18.536L7.48392 17.8784C7.51478 17.3249 7.97262 16.8919 8.527 16.8919H15.451C16.0054 16.8919 16.4632 17.3249 16.4941 17.8784L16.5307 18.536C16.6186 20.1118 17.922 21.3446 19.5003 21.3446C21.36 21.3446 22.7636 19.657 22.4247 17.8285L21.3506 12.032C21.0629 10.4793 19.7085 9.35275 18.1294 9.35275H5.84861Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.73456 10.9109C7.14878 10.9109 7.48456 11.2467 7.48456 11.6609V12.7613H8.56009C8.9743 12.7613 9.31009 13.097 9.31009 13.5113C9.31009 13.9255 8.9743 14.2613 8.56009 14.2613H7.48456V15.3616C7.48456 15.7758 7.14878 16.1116 6.73456 16.1116C6.32035 16.1116 5.98456 15.7758 5.98456 15.3616V14.2613H4.90369C4.48947 14.2613 4.15369 13.9255 4.15369 13.5113C4.15369 13.097 4.48947 12.7613 4.90369 12.7613H5.98456V11.6609C5.98456 11.2467 6.32035 10.9109 6.73456 10.9109Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.3328 14.421C17.8362 14.421 18.2443 14.0128 18.2443 13.5094C18.2443 13.006 17.8362 12.5978 17.3328 12.5978C16.8293 12.5978 16.4212 13.006 16.4212 13.5094C16.4212 14.0128 16.8293 14.421 17.3328 14.421ZM19.7443 13.5094C19.7443 14.8413 18.6646 15.921 17.3328 15.921C16.0009 15.921 14.9212 14.8413 14.9212 13.5094C14.9212 12.1775 16.0009 11.0978 17.3328 11.0978C18.6646 11.0978 19.7443 12.1775 19.7443 13.5094Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.017 2.59274C10.7671 2.59274 9.71065 3.10187 9.10732 3.81155C8.83903 4.12713 8.3657 4.16547 8.05012 3.89718C7.73454 3.62889 7.6962 3.15557 7.96449 2.83998C8.88423 1.75812 10.378 1.09274 12.017 1.09274C13.656 1.09274 15.1497 1.75812 16.0695 2.83998C16.3378 3.15557 16.2994 3.62889 15.9838 3.89718C15.6683 4.16547 15.1949 4.12713 14.9266 3.81155C14.3233 3.10187 13.2669 2.59274 12.017 2.59274Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.017 5.61871C11.701 5.61871 11.469 5.74815 11.3581 5.87866C11.0898 6.19424 10.6164 6.23258 10.3009 5.96429C9.98527 5.696 9.94693 5.22268 10.2152 4.90709C10.6426 4.4044 11.3119 4.11871 12.017 4.11871C12.7221 4.11871 13.3914 4.4044 13.8188 4.90709C14.0871 5.22268 14.0487 5.696 13.7331 5.96429C13.4176 6.23258 12.9442 6.19424 12.6759 5.87866C12.565 5.74815 12.333 5.61871 12.017 5.61871Z'
        fill={color}
      />
    </svg>
  );
};
