import { Box, styled } from '@mui/material';

export const MultiSearchWrapper = styled(Box)(({ border }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: border ? '#96A0B5' : 'rgba(150, 160, 181, 0.4)',
  height: '56px',
  '&:hover': {
    border: '1px solid #96A0B5',
  },
}));

export const DividerStyled = styled(Box)({
  height: '24px',
  display: 'flex',
  background: '#96A0B5',
  width: '1px',
  marginInlineEnd: '12px',
  marginInlineStart: '20px',
});
export const SelectBlock = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minWidth: '320px',
});
export const SelectBlockMobileCss = styled(Box)({
  display: 'flex',
  position: 'relative',
  marginBottom: '12px',
  height: '42px',
});

export const SelectCloseCSS = styled(Box)({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '50%',
  transform: 'translate(0, -50%)',
  right: '30px',
  width: '30px',
  height: '30px',
  cursor: 'pointer',
});
