import { Accordion } from '@mui/material';
import { ChevronDownIcon } from '../icons';
import React from 'react';
import {
  AccordionDetailsStyled,
  AccordionSummaryStyled,
  DescriptionTypographyCSS,
  TitleTypographyCSS,
} from 'components/AccordionCustom/AccordionCustom.styled';
import { DefaultTFuncReturn } from 'i18next';

interface AccordionCustomProps {
  defaultExpanded?: boolean;
  isOverLaptop: boolean;
  title?: string | DefaultTFuncReturn;
  subtitle?: string | DefaultTFuncReturn;
  subtitle2?: string | DefaultTFuncReturn;
}

export const AccordionCustom = ({
  defaultExpanded = false,
  isOverLaptop,
  title = '',
  subtitle = '',
  subtitle2 = '',
}: AccordionCustomProps) => {
  return (
    <>
      <Accordion
        defaultExpanded={defaultExpanded}
        variant={'elevation'}
        disableGutters
      >
        <AccordionSummaryStyled
          expandIcon={
            <ChevronDownIcon
              size={isOverLaptop ? '24' : '20'}
              style={{ rotate: '-90deg' }}
            />
          }
        >
          <TitleTypographyCSS variant={isOverLaptop ? 'big400' : 'big300'}>
            {title}
          </TitleTypographyCSS>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>
          <DescriptionTypographyCSS
            as={'p'}
            mb={1}
            variant={isOverLaptop ? 'average400' : 'average200'}
          >
            {subtitle}
          </DescriptionTypographyCSS>
          {subtitle2 && (
            <DescriptionTypographyCSS
              as={'p'}
              variant={isOverLaptop ? 'average400' : 'average200'}
            >
              {subtitle2}
            </DescriptionTypographyCSS>
          )}
        </AccordionDetailsStyled>
      </Accordion>
    </>
  );
};
