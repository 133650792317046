import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { ArrowBackIcon, ArrowIcon } from '../../icons';
import { Form, Formik } from 'formik';
import { TextFieldFormik } from 'components/uiKit/TextFieldFormik/TextFieldFormik';
import { CheckboxFormik } from 'components/uiKit/CheckBoxFormik/CheckboxFormik';
import { useCartContext, useModalContext } from 'context';

import {
  AUTH_PATHS,
  countriesData,
  getErrorName,
  MODAL_NAME,
  PATHS,
} from 'utils';
import { useRouter } from 'next/router';
import {
  ButtonFW,
  FormWrapper,
  LinkTypography,
} from 'components/Form/SignInForm.styled';
import { signUpSchema } from 'validation/signUp.schema';
import { FormHeader } from '..';
import { CountrySelectFormik } from 'components/uiKit';
import { AlertCustom, CircularProgressCustom } from 'components';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useLaptop } from 'hooks';
import { UserLanguagesEnum, useSignUpMutation } from 'graphql/generated';
import { ErrorMessage } from 'types/baseTypes';
import { useSiteIdContext } from 'context/SiteIdContext';
import { useAuthVariablesStore } from 'Store';
import { useCaptchaV3 } from '../../../hooks/useCaptchaV3';

export const SignUpForm = () => {
  const { isOverLaptop } = useLaptop();
  const { openModal, onOpenModal, onCloseModal } = useModalContext();
  const { isProductInTheCart } = useCartContext();
  const { t } = useTranslation();
  const { isENLang } = useCurrentLanguage();
  const { siteId } = useSiteIdContext();
  const router = useRouter();
  const captcha = useCaptchaV3();

  const [setEmail] = useAuthVariablesStore((state) => [state.setEmail]);

  const [signUpMutation, { data, error }] = useSignUpMutation();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async (values: any) => {
    if (isSubmitting || data) {
      return;
    }
    setSubmitting(true);

    const captchaToken = await captcha.getToken();

    signUpMutation({
      variables: {
        input: {
          email: values.email,
          password: values.password,
          country: values.countrySelect,
          captcha: captchaToken,
          language: isENLang ? UserLanguagesEnum.En : UserLanguagesEnum.Ar,
          siteId: siteId,
        },
      },
      onCompleted: () => {
        setSubmitting(false);
        setEmail(values.email);

        if (openModal) {
          onCloseModal();
        }

        if (!isOverLaptop || !isProductInTheCart) {
          router.push(AUTH_PATHS.confirmEmail);
        } else {
          onOpenModal(MODAL_NAME.confirmEmail);
        }
      },
      onError: () => setSubmitting(false),
    });
  };

  const handleNavigateToSignInPage = () => {
    if (openModal) {
      onCloseModal();
    }

    // * if mobile and cart is empty
    if (!isOverLaptop || !isProductInTheCart) {
      router.push('/sign-in');
    } else {
      onOpenModal(MODAL_NAME.signIn);
    }
  };

  const goBack = () => {
    if (router.query?.from) {
      router.back();
    } else {
      router.push(PATHS.home);
    }
  };

  return (
    <FormWrapper>
      {isOverLaptop && !openModal?.open && (
        <Button
          sx={{ marginBottom: '40px' }}
          startIcon={<ArrowBackIcon />}
          variant={'back'}
          size={'medium'}
          onClick={goBack}
        >
          {t('app.back')}
        </Button>
      )}
      <FormHeader
        isOverLaptop={isOverLaptop}
        title={t('app.sign_up')}
        subTitle={t('app.auth.please_enter_your_details')}
      />

      <Formik
        initialValues={{
          email: '',
          password: '',
          repeatPassword: '',
          countrySelect: '',
          confirm: false,
        }}
        validationSchema={signUpSchema(isENLang)}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <TextFieldFormik
                name={'email'}
                label={t('app.auth.email_address')}
                placeholder={t('app.auth.ey_email_address')}
                size={!isOverLaptop ? 'small' : 'medium'}
              />
              <TextFieldFormik
                name={'password'}
                label={t('app.auth.password')}
                placeholder={t('app.auth.ey_password')}
                type={'password'}
                size={!isOverLaptop ? 'small' : 'medium'}
              />
              <TextFieldFormik
                name={'repeatPassword'}
                label={t('app.auth.repeat_password')}
                placeholder={t('app.auth.ey_password')}
                type={'password'}
                size={!isOverLaptop ? 'small' : 'medium'}
              />
              <CountrySelectFormik
                options={countriesData}
                name={'countrySelect'}
                isOverLaptop={isOverLaptop}
                label={'Country'}
              />

              <Box
                sx={{
                  display: 'flex',
                  paddingTop: !isOverLaptop ? '8px' : '0',
                }}
              >
                <CheckboxFormik
                  name={'confirm'}
                  label={
                    <>
                      {t('app.auth.i_declare_agree')}
                      <LinkTypography as={'span'} color={'#4663F4'}>
                        <a
                          href={PATHS.privacyPolicy}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          &nbsp;{t('app.auth.terms_of_use')}
                        </a>
                      </LinkTypography>
                    </>
                  }
                />
              </Box>

              {captcha.renderContainer()}

              <ButtonFW
                type={'submit'}
                endIcon={
                  isSubmitting ? (
                    <CircularProgressCustom loading={true} color={'#fff'} />
                  ) : (
                    <ArrowIcon />
                  )
                }
                size={!isOverLaptop ? 'medium' : 'large'}
              >
                {t('app.sign_up')}
              </ButtonFW>
            </Form>
          );
        }}
      </Formik>

      {error ? (
        <AlertCustom type={'error'} mt={isOverLaptop ? '24px' : '16px'}>
          {getErrorMessage()}
        </AlertCustom>
      ) : null}

      <Typography
        mt={'24px'}
        variant={isOverLaptop ? 'average400' : 'average200'}
      >
        {t('app.auth.already_have_an_account')}?{' '}
        <LinkTypography
          as={'span'}
          color={'#4663F4'}
          onClick={handleNavigateToSignInPage}
        >
          {t('app.sign_in')}
        </LinkTypography>
      </Typography>
    </FormWrapper>
  );

  function getErrorMessage() {
    const exception = error?.graphQLErrors[0]?.extensions?.exception as
      | ErrorMessage
      | undefined;

    if (exception?.name) {
      return getErrorName(exception.name);
    }

    if (error?.message) {
      return error.message;
    }

    return getErrorName('');
  }
};
