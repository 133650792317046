import { Box, styled } from '@mui/material';

export const ImageBlock = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '6px',
  overflow: 'hidden',
  marginInlineEnd: '10px',
  maxWidth: 'fit-content',
}));
