import React from 'react';

export const MastercardIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={35} height={24} fill='none'>
      <rect
        width={34}
        height={23}
        x={0.5}
        y={0.5}
        fill='#fff'
        stroke='#D9D9D9'
        rx={3.5}
      />
      <path
        fill='#ED0006'
        fillRule='evenodd'
        d='M17.75 17.157a6.75 6.75 0 0 1-4.426 1.649c-3.769 0-6.824-3.09-6.824-6.903C6.5 8.091 9.555 5 13.324 5a6.75 6.75 0 0 1 4.426 1.649A6.75 6.75 0 0 1 22.176 5C25.945 5 29 8.09 29 11.903c0 3.812-3.055 6.903-6.824 6.903a6.75 6.75 0 0 1-4.426-1.65Z'
        clipRule='evenodd'
      />
      <path
        fill='#F9A000'
        fillRule='evenodd'
        d='M17.75 17.157a6.92 6.92 0 0 0 2.398-5.254 6.92 6.92 0 0 0-2.398-5.254A6.75 6.75 0 0 1 22.176 5C25.945 5 29 8.09 29 11.903c0 3.812-3.055 6.903-6.824 6.903a6.75 6.75 0 0 1-4.426-1.65Z'
        clipRule='evenodd'
      />
      <path
        fill='#FF5E00'
        fillRule='evenodd'
        d='M17.75 6.649a6.92 6.92 0 0 1 2.398 5.254 6.92 6.92 0 0 1-2.398 5.254 6.92 6.92 0 0 1-2.398-5.254 6.92 6.92 0 0 1 2.398-5.254Z'
        clipRule='evenodd'
      />
    </svg>
  );
};
