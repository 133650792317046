import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { ButtonFW, FormWrapper } from 'components/Form/SignInForm.styled';
import { ArrowIcon } from '../../icons';
import {
  AlertCustom,
  CircularProgressCustom,
  TextFieldFormik,
} from 'components';
import { useRouter } from 'next/router';
import { useCartContext, useModalContext } from 'context';
import { useTranslation } from 'next-i18next';
import { MODAL_NAME, AUTH_PATHS, getErrorName } from 'utils';
import { newPasswordSchema } from 'validation/newPassword.schema';
import { useLaptop } from 'hooks';
import { FormHeader } from '..';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useChangePasswordMutation } from 'graphql/generated';
import { useAuthVariablesStore } from 'Store';

export const NewPasswordForm = () => {
  const router = useRouter();
  const { isOverLaptop } = useLaptop();

  const { onOpenModal } = useModalContext();
  const { isProductInTheCart } = useCartContext();
  const { t } = useTranslation();
  const { isENLang } = useCurrentLanguage();

  const [email, code] = useAuthVariablesStore((state) => [
    state.email,
    state.code,
  ]);

  useEffect(() => {
    if (!email && !code) {
      router.push(AUTH_PATHS.forgotPassword);
    } else if (email && !code) {
      router.push(AUTH_PATHS.confirmPassword);
    }
  }, []);

  const [changePasswordMutation, { data, error, loading }] =
    useChangePasswordMutation();

  const handleSubmit = async (values: Record<string, string>) => {
    if (loading || !!data) return;
    await changePasswordMutation({
      variables: {
        input: {
          email: email,
          code: code,
          password: values.password,
        },
      },
      onCompleted: () => {
        // * if mobile and cart is empty
        if (!isOverLaptop || !isProductInTheCart) {
          router.push(AUTH_PATHS.successPassword);
        } else {
          onOpenModal(MODAL_NAME.successPassword);
        }
      },
    });
  };

  return (
    <FormWrapper>
      <FormHeader
        isOverLaptop={isOverLaptop}
        title={t('app.auth.set_new_password')}
        subTitle={t('app.auth.please_set_new_password')}
      />
      <Formik
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        validationSchema={newPasswordSchema(isENLang)}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextFieldFormik
            name={'password'}
            label={t('app.auth.password')}
            placeholder={t('app.auth.ey_password')}
            size={!isOverLaptop ? 'small' : 'medium'}
            type={'password'}
          />
          <TextFieldFormik
            name={'repeatPassword'}
            label={t('app.auth.repeat_password')}
            placeholder={t('app.auth.repeat_new_password')}
            size={!isOverLaptop ? 'small' : 'medium'}
            type={'password'}
          />
          <ButtonFW
            type={'submit'}
            endIcon={
              loading ? (
                <CircularProgressCustom loading={true} color={'#fff'} />
              ) : (
                <ArrowIcon />
              )
            }
            size={!isOverLaptop ? 'medium' : 'large'}
          >
            {t('app.apply')}
          </ButtonFW>
        </Form>
      </Formik>
      {error ? (
        <AlertCustom type={'error'} mt={isOverLaptop ? '24px' : '16px'}>
          {getErrorName('default')}
        </AlertCustom>
      ) : null}
    </FormWrapper>
  );
};
