import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const ChevronDownIcon = ({ size = '14', ...props }: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.96967 4.71967C3.26256 4.42678 3.73744 4.42678 4.03033 4.71967L7 7.68934L9.96967 4.71967C10.2626 4.42678 10.7374 4.42678 11.0303 4.71967C11.3232 5.01256 11.3232 5.48744 11.0303 5.78033L7.53033 9.28033C7.23744 9.57322 6.76256 9.57322 6.46967 9.28033L2.96967 5.78033C2.67678 5.48744 2.67678 5.01256 2.96967 4.71967Z'
        fill='#96A0B5'
      />
    </svg>
  );
};
