import React from 'react';

export const CardDivider = () => {
  return (
    <svg
      width='515'
      height='2'
      viewBox='0 0 515 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M0 1H515'
        stroke='#96A0B5'
        strokeWidth='2'
        strokeDasharray='4 4'
      />
    </svg>
  );
};
