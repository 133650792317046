import { Box, styled } from '@mui/material';

export const BadgeStyled = styled(Box)({
  width: '37px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 6px',
  textTransform: 'uppercase',
  background: '#F03758',
  boxShadow: '4px 8px 16px rgba(240, 55, 88, 0.15)',
  borderRadius: '6px',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '12px',
  color: '#fff',
});
