import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const OtherProductIcon = ({ color = '#96A0B5' }: IconsTypes) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_452_15014)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.8588 1.92835C12.309 1.70083 11.6914 1.70038 11.1413 1.92711L3.54207 5.05874C3.48639 5.08169 3.43199 5.1067 3.37895 5.13367L6.24269 6.31129L14.6365 2.66393L12.8588 1.92835ZM16.5644 3.46168L8.17648 7.10649L11.9351 8.65209C12.0784 8.71101 12.1936 8.8092 12.2736 8.92908C12.6332 8.5481 13.0754 8.23954 13.5803 8.03301L20.6276 5.15035C20.5757 5.12398 20.5225 5.09948 20.468 5.07696L16.5644 3.46168ZM21.7014 6.33174L14.1482 9.42136C13.3025 9.76726 12.75 10.5902 12.75 11.5039V22.0563C12.9127 22.0182 13.0731 21.9674 13.2298 21.9039L20.453 18.9758C21.3021 18.6316 21.8577 17.8068 21.8577 16.8906V7.156C21.8577 6.86826 21.8029 6.58947 21.7014 6.33174ZM11.25 22.0521V11.5039C11.25 11.0078 11.3478 10.5277 11.5283 10.086C11.4733 10.0769 11.4184 10.0615 11.3646 10.0394L7.00074 8.24489V11.4887C7.00074 11.9029 6.66495 12.2387 6.25074 12.2387C5.83653 12.2387 5.50074 11.9029 5.50074 11.4887V7.62806L2.30588 6.31428C2.20423 6.57214 2.14935 6.85109 2.14935 7.13902V16.8519C2.14935 17.7649 2.70096 18.5873 3.54559 18.9337L10.7756 21.8989C10.9305 21.9624 11.0892 22.0135 11.25 22.0521ZM10.5697 0.540254C11.4867 0.162382 12.516 0.163124 13.4324 0.542319L21.0416 3.69094C22.4435 4.27105 23.3577 5.63878 23.3577 7.156V16.8906C23.3577 18.4177 22.4317 19.7922 21.0165 20.3659L13.7933 23.294C12.6427 23.7604 11.3551 23.7578 10.2064 23.2867L2.97642 20.3215C1.5687 19.7442 0.649353 18.3735 0.649353 16.8519V7.13902C0.649353 5.61983 1.56594 4.25073 2.97054 3.67189L10.5697 0.540254Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_452_15014'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
