import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { RedRemoveIcon } from '../icons/RedRemoveIcon';
import {
  AmountWrap,
  DescriptionBlock,
  DescriptionTypography,
  MobileFooterCardBlock,
  MobileFooterCardWrapper,
  ProductCell,
  ProductImageBlock,
  ProductRow,
} from 'components/CartProductRow/CartProductRow.styled';
import { AmountBtnGroup } from 'components/AmountBtnGroup/AmountBtnGroup';
import { useTranslation } from 'next-i18next';
import { DefaultTFuncReturn } from 'i18next';
import Image from 'next/image';
import {
  Maybe,
  useRemoveProductFromCartMutation,
  useUpdateProductQuantityMutation,
} from 'graphql/generated';
import { getAmount } from 'utils';
import { useCartStore, useIsDisabledProductRowStore } from 'Store';
import { productDataProps } from 'Store/cart';
import { useAlertContext, useAuthContext } from 'context';
import { GET_ALL_PRODUCT_FROM_CART } from 'graphql/queries';

export interface CartProductRowProps {
  isOverLaptop: boolean;
  disabled?: boolean;
  imgSrc?: Maybe<string>;
  name: string | DefaultTFuncReturn | undefined;
  description: string | DefaultTFuncReturn | null | undefined;
  pricePerUnit: number;
  currency: Maybe<string>;
  id: string;
  idForRemoveProductIfIsAuth?: string;
  quantity?: string;
}

export const CartProductRow = ({
  isOverLaptop,
  disabled = false,
  imgSrc,
  name = '',
  description = '',
  pricePerUnit,
  currency,
  id,
  quantity = '1',
}: CartProductRowProps) => {
  const { t } = useTranslation();
  const { isAuth } = useAuthContext();
  const { onOpenAlert } = useAlertContext();

  const [removeId] = useIsDisabledProductRowStore((state) => [state.removeId]);

  const [productData, removeLocalCartItemById, increase, decrease] =
    useCartStore((state) => [
      state.productData,
      state.removeLocalCartItemById,
      state.increase,
      state.decrease,
    ]);

  const targetObj = productData.find((prod) => prod.skuId === id);
  const count = (targetObj as productDataProps)?.count;

  const [removeProductFromCartMutation] = useRemoveProductFromCartMutation();
  const [updateProductQuantityMutation] = useUpdateProductQuantityMutation();
  // const { refetch: ss } = useGetSkusByIdsQuery({});

  const handleRemove = async (id: string) => {
    removeLocalCartItemById(id);

    // * DO AUTHENTICATION USER
    if (isAuth) {
      await removeProductFromCartMutation({
        variables: {
          skuId: id,
        },
        refetchQueries: [GET_ALL_PRODUCT_FROM_CART],
        onCompleted: () =>
          onOpenAlert({
            subTitle: t`app.product_has_been_removed_from_you_cart`,
          }),
      });
    }
    removeId(id);
  };

  const handleCount = (e: 'add' | 'subtract'): void => {
    if (!isAuth) {
      if (e === 'add') {
        increase(id);
      } else {
        if ((targetObj as productDataProps).count === 1) return;
        decrease(id);
      }
    } else {
      updateProductQuantityMutation({
        variables: {
          skuId: id,
          input: {
            quantity: calculateQuantity(e, +quantity),
          },
        },
      });
    }
  };

  function calculateQuantity(e: 'add' | 'subtract', quantity: number): number {
    let res = quantity;
    if (e === 'add') {
      res += 1;
    } else {
      if (res === 1) return 1;
      res -= 1;
    }
    return res;
  }

  return (
    <ProductRow disabled={disabled}>
      <ProductCell>
        <ProductImageBlock>
          {imgSrc && (
            <Image
              alt={''}
              src={`${process.env.NEXT_PUBLIC_BASE_URL}/uploads/images/products/${imgSrc}`}
              width={1}
              height={1}
              priority={true}
              unoptimized={true}
            />
          )}
        </ProductImageBlock>
        <DescriptionBlock>
          <Typography variant={isOverLaptop ? 'average500' : 'big800'}>
            {name}
          </Typography>
          <DescriptionTypography
            color={'#96A0B5'}
            variant={isOverLaptop ? 'averageMob450' : 'bottomFooterLaptop'}
          >
            {description}
          </DescriptionTypography>
          {!isOverLaptop ? (
            <Box mt={'6px'}>
              <AmountBtnGroup
                setCount={handleCount}
                count={count}
                quantity={quantity}
              />
            </Box>
          ) : null}
        </DescriptionBlock>
      </ProductCell>
      {!isOverLaptop && <Divider sx={{ width: '100%' }} />}
      {isOverLaptop ? (
        <>
          <Box>
            <Typography variant={isOverLaptop ? 'big900' : 'big200'}>
              {Number(pricePerUnit).toFixed(2)}{' '}
              <Typography
                variant={isOverLaptop ? 'big900' : 'big200'}
                sx={{ fontWeight: 300 }}
                component={'span'}
              >
                {currency}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <AmountBtnGroup
              setCount={handleCount}
              count={count}
              quantity={quantity}
            />
          </Box>
          <AmountWrap>
            <Typography
              variant={isOverLaptop ? 'big900' : 'big200'}
              sx={{ minWidth: '200px', textAlign: 'end' }}
            >
              {getAmount(pricePerUnit, isAuth ? quantity : count)}{' '}
              <Typography
                variant={isOverLaptop ? 'big900' : 'big200'}
                sx={{ fontWeight: 300 }}
                component={'span'}
              >
                {currency}
              </Typography>
            </Typography>
            <Divider orientation={'vertical'} sx={{ height: '30px' }} />
            <IconButton
              sx={{ marginInlineStart: '-12px' }}
              size={'large'}
              onClick={() => handleRemove(id)}
            >
              <RedRemoveIcon />
            </IconButton>
          </AmountWrap>
        </>
      ) : (
        <MobileFooterCardWrapper>
          <MobileFooterCardBlock>
            <Typography variant={'sm500'} color={'#96A0B5'}>
              {t('app.cart.price_per_unit')}
            </Typography>
            <Typography variant={'big200'}>
              {pricePerUnit.toFixed(2)}{' '}
              <Typography
                variant={'big200'}
                sx={{ fontWeight: 300 }}
                component={'span'}
              >
                {currency}
              </Typography>
            </Typography>
          </MobileFooterCardBlock>
          <MobileFooterCardBlock textAlign={'end'}>
            <Typography variant={'sm500'} color={'#96A0B5'}>
              {t('app.cart.amount')}
            </Typography>
            <Typography variant={'big200'}>
              {getAmount(pricePerUnit, isAuth ? quantity : count)}{' '}
              <Typography
                variant={'big200'}
                sx={{ fontWeight: 300 }}
                component={'span'}
              >
                {currency}
              </Typography>
            </Typography>
          </MobileFooterCardBlock>
          <IconButton
            onClick={() => handleRemove(id)}
            sx={{ position: 'absolute', insetInlineEnd: '8px', top: '8px' }}
            size={'small'}
          >
            <RedRemoveIcon />
          </IconButton>
        </MobileFooterCardWrapper>
      )}
    </ProductRow>
  );
};
