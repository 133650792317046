import { Box, BoxProps, styled } from '@mui/material';
import { AliasBoolTypes } from 'types/baseTypes';

interface WrapperStyledProps extends BoxProps {
  haserror?: AliasBoolTypes;
}

export const WrapperStyled = styled(Box)<WrapperStyledProps>(
  ({ theme, haserror }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: haserror === 'true' ? '10px' : '24px',
      '.MuiInputBase-root': {
        borderColor: haserror === 'true' && '#F03758',
      },
      [theme.breakpoints.down('laptop')]: {
        marginBottom: haserror === 'true' ? '10px' : '16px',
      },
    };
  },
);

export const ImageBlock = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '6px',
  overflow: 'hidden',
  marginInlineEnd: '10px',
  maxWidth: 'fit-content',
}));
