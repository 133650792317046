import React from 'react';

export const VisaIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={35} height={24} fill='none'>
      <rect
        width={34}
        height={23}
        x={0.5}
        y={0.5}
        fill='#fff'
        stroke='#D9D9D9'
        rx={3.5}
      />
      <path
        fill='#172B85'
        fillRule='evenodd'
        d='M10.625 16.258h-2.12l-1.59-6.066c-.076-.279-.236-.525-.471-.642A6.902 6.902 0 0 0 4.5 8.908v-.233h3.416a.89.89 0 0 1 .884.758l.825 4.376 2.119-5.134h2.061l-3.18 7.583Zm4.359 0H12.98l1.65-7.583h2.002l-1.65 7.583Zm4.24-5.482c.058-.409.412-.642.824-.642a3.732 3.732 0 0 1 1.944.35l.353-1.633A5.068 5.068 0 0 0 20.52 8.5c-1.944 0-3.358 1.05-3.358 2.508 0 1.11 1.002 1.692 1.709 2.042.765.35 1.06.584 1 .933 0 .525-.589.759-1.177.759a5.049 5.049 0 0 1-2.061-.467l-.354 1.633a5.779 5.779 0 0 0 2.18.409c2.179.058 3.533-.992 3.533-2.567 0-1.983-2.769-2.1-2.769-2.974ZM29 16.258l-1.59-7.583h-1.708a.886.886 0 0 0-.825.583l-2.944 7h2.061l.412-1.108h2.533l.235 1.108H29Zm-3.003-5.54.588 2.857h-1.649l1.06-2.858Z'
        clipRule='evenodd'
      />
    </svg>
  );
};
