import React from 'react';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { LogoIcon } from '../icons';
import { VisaIcon } from '../icons/VisaIcon';
import { MastercardIcon } from '../icons/MastercardIcon';
import {
  BottomPart,
  FooterWrap,
  LeftColumn,
  RightColumn,
  TopPart,
  LinksRowCSS,
} from 'components/Footer/Footer.styled';
import { useTranslation } from 'next-i18next';
import { useLaptop } from 'hooks';
import { PATHS } from 'utils';
import Link from 'next/link';

export const Footer = () => {
  const { isOverLaptop } = useLaptop();
  const { t } = useTranslation();

  return (
    <FooterWrap>
      <TopPart>
        <LeftColumn>
          <LogoIcon />
          <Typography
            component={'p'}
            mb={isOverLaptop ? '20px' : '16px'}
            variant={isOverLaptop ? 'title' : 'titleFooterMobile'}
            textAlign={isOverLaptop ? 'start' : 'center'}
          >
            {t('app.footer.webmarket_today')}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <IconButton
              sx={{
                padding: 0,
                height: 'fit-content',
                marginInlineEnd: '10px',
              }}
            >
              <VisaIcon />
            </IconButton>
            <IconButton sx={{ padding: 0, height: 'fit-content' }}>
              <MastercardIcon />
            </IconButton>
          </Box>
        </LeftColumn>

        <RightColumn>
          <Stack spacing={1}>
            <Typography
              variant={'upperGrey'}
              mb={isOverLaptop ? '12px' : '0'}
              textAlign={isOverLaptop ? 'start' : 'center'}
            >
              {t('app.footer.contact_us')}
            </Typography>

            <Typography
              variant={'mid550'}
              textAlign={isOverLaptop ? 'start' : 'center'}
            >
              {t('app.footer.address')}:{' '}
              <Typography
                variant={isOverLaptop ? 'title' : 'titleFooterMobile'}
              >
                Al-Areej Business Center Sharjah, Office 307
              </Typography>
            </Typography>

            <Typography
              variant={'mid550'}
              textAlign={isOverLaptop ? 'start' : 'center'}
            >
              {t('app.footer.phone')}:{' '}
              <Typography
                variant={isOverLaptop ? 'title' : 'titleFooterMobile'}
              >
                +971 509 315696
              </Typography>
            </Typography>

            <Typography
              variant={'mid550'}
              textAlign={isOverLaptop ? 'start' : 'center'}
            >
              {t('app.footer.email')}:{' '}
              <Typography
                variant={isOverLaptop ? 'title' : 'titleFooterMobile'}
              >
                help@shifa.one
              </Typography>
            </Typography>
          </Stack>
        </RightColumn>
      </TopPart>
      <Divider />
      <BottomPart>
        <Typography
          variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
        >
          ©{new Date().getFullYear()} {t('app.footer.all_rights_reserved')}
        </Typography>
        <LinksRowCSS>
          <Link href={PATHS.termsOfUse}>
            <Typography
              color={!isOverLaptop ? '#373748' : ''}
              variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
            >{`Terms of Use`}</Typography>
          </Link>
          <Link href={PATHS.privacyPolicy}>
            <Typography
              color={!isOverLaptop ? '#373748' : ''}
              variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
            >{`Privacy Policy`}</Typography>
          </Link>
          <Link href={PATHS.cookies}>
            <Typography
              color={!isOverLaptop ? '#373748' : ''}
              variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
            >{`Cookies`}</Typography>
          </Link>
          <Link href={PATHS.shippingPolicy}>
            <Typography
              color={!isOverLaptop ? '#373748' : ''}
              variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
            >{`Shipping Policy`}</Typography>
          </Link>
        </LinksRowCSS>
      </BottomPart>
    </FooterWrap>
  );
};
