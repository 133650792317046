import * as yup from 'yup';
import { messages as M } from '../utils/constants/message';
import { getEnding } from 'utils/getEnding';

export const signUpSchema = (isENLang: boolean) =>
  yup.object().shape({
    email: yup
      .string()
      .email(M.INVALID_MAIL[getEnding(isENLang)])
      .required(M.REQUIRED[getEnding(isENLang)]),
    password: yup.string().required(M.REQUIRED[getEnding(isENLang)]),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], M.PASSWORD_MATCH[getEnding(isENLang)])
      .required(M.REQUIRED[getEnding(isENLang)]),
    countrySelect: yup.string().required(M.REQUIRED[getEnding(isENLang)]),
    confirm: yup
      .boolean()
      .isTrue(M.REQUIRED[getEnding(isENLang)])
      .required(M.REQUIRED[getEnding(isENLang)]),
  });
