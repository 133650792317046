import { Box, styled } from '@mui/material';
import { AlertType } from 'types/baseTypes';

type Wrapper = {
  type: AlertType;
};

export const Wrapper = styled(Box)<Wrapper>(({ theme, type }) => {
  const getColor = (type: string) => {
    switch (type) {
      case 'error':
        return '#F03758';
      case 'information':
        return '#4663F4';
      case 'success':
        return '#009045';
      default:
        return '#96A0B5';
    }
  };
  const getBackground = (type: string) => {
    switch (type) {
      case 'error':
        return 'rgba(240, 55, 88, 0.05)';
      case 'information':
        return 'rgba(70, 99, 244, 0.05)';
      case 'success':
        return 'rgba(176, 239, 143, 0.2)';
      default:
        return '#F7F9FC';
    }
  };
  const getBorderColor = (type: string) => {
    switch (type) {
      case 'error':
        return 'rgba(240, 55, 88, 0.1)';
      case 'information':
        return 'rgba(70, 99, 244, 0.1)';
      case 'success':
        return 'rgba(176, 239, 143, 0.4)';
      default:
        return '#ECEFF4';
    }
  };

  return {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    background: getBackground(type),
    border: '1px solid',
    borderColor: getBorderColor(type),
    color: getColor(type),
    fontWeight: 300,
    fontSize: '15px',
    lineHeight: '22px',
    padding: '12px 20px',
    [theme?.breakpoints.down('laptop')]: {
      padding: '8.5px 12px',
      fontSize: '14px',
      lineHeight: '21px',
    },
  };
});
