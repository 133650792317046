import { Box, styled } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';

export const CustomLink = styled(Link)(({ href, theme }) => {
  const { pathname } = useRouter();
  return {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    color: pathname !== href ? '#373748' : '#4663F4',
    textDecoration: 'none',
    [theme.breakpoints.down('laptop')]: {
      lineHeight: '36px',
    },
  };
});

export const NavigationBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '23px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    width: '100%',
    minWidth: '260px',
    padding: '16px 20px 0 20px',
    gap: '0',
  },
}));
