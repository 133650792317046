import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

interface WrapperProps extends BoxProps {
  verify?: string;
}

export const Wrapper = styled(Box)<WrapperProps>(({ theme, verify }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2.2px 8px 1.8px 9px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: verify === 'true' ? '#009045' : '#F03758',
  border: '1px solid ',
  borderColor: verify === 'true' ? 'rgba(0,144,69,0.4)' : 'rgba(240,55,88,0.4)',
  borderRadius: '4px',
  width: 'fit-content',
  [theme.breakpoints.down('laptop')]: {
    padding: '2.2px 6px 1.8px 7px',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '15px',
  },
}));
