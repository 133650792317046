import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCartContext, useModalContext } from 'context';
import { MODAL_NAME, AUTH_PATHS } from 'utils';
import { ButtonFW, FormWrapper } from 'components/Form/SignInForm.styled';

import { ArrowIcon } from '../../icons';
import { FormConfirmHeader } from '..';
import { useLaptop } from 'hooks';
import { useAuthVariablesStore } from 'Store';

export const SuccessPasswordForm = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isOverLaptop } = useLaptop();
  const { isProductInTheCart } = useCartContext();
  const { onOpenModal } = useModalContext();

  const [clearEmail, clearCode] = useAuthVariablesStore((state) => [
    state.clearEmail,
    state.clearCode,
  ]);

  const handleClick = () => {
    clearEmail();
    clearCode();
    // * if mobile and cart is empty
    if (!isOverLaptop || !isProductInTheCart) {
      return router.push(AUTH_PATHS.signIn);
    } else {
      onOpenModal(MODAL_NAME.signIn);
    }
  };

  return (
    <FormWrapper>
      <FormConfirmHeader
        title={t('app.auth.success')}
        subTitle={t('app.auth.new_password_successfully')}
      />
      <ButtonFW
        onClick={handleClick}
        endIcon={<ArrowIcon />}
        sx={{ maxWidth: '278px', alignSelf: 'center' }}
      >
        {t('app.sign_in')}
      </ButtonFW>
    </FormWrapper>
  );
};
