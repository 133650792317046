import create from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type useInnerHeaderShowStoreProps = {
  hidden: boolean;
  setHidden: () => void;
};

export const useInnerHeaderShowStore = create<useInnerHeaderShowStoreProps>()(
  immer(
    persist(
      (setState) => ({
        hidden: false,
        setHidden: () => {
          setState((state) => void (state.hidden = !state.hidden));
        },
      }),
      {
        name: 'hidden',
      },
    ),
  ),
);
