import { AllProductsIcon, Rotate24Icon, ShieldIcon } from 'components/icons';
import React from 'react';
import { useTranslation } from 'next-i18next';

export const useCardItemData = () => {
  const { t } = useTranslation();
  return [
    {
      id: 1,
      icon: <AllProductsIcon style={{ marginInlineEnd: '24px' }} />,
      title: t`app.large_selection_of_services`,
      subTitle: t`app.over_different_services`,
    },
    {
      id: 2,
      icon: <ShieldIcon style={{ marginInlineEnd: '24px' }} />,
      title: t`app.num_secure_payment`,
      subTitle: t`app.guarantee_secure_payments`,
    },
    {
      id: 3,
      icon: <Rotate24Icon style={{ marginInlineEnd: '24px' }} />,
      title: t`app.num_dedicated_support`,
      subTitle: t`app.anywhere_anytime`,
    },
  ];
};
