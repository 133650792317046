import { Box, BoxProps, styled } from '@mui/material';

interface WrapperProps extends BoxProps {
  haserror?: string;
}

export const WrapperStyled = styled(Box)<WrapperProps>(
  ({ theme, haserror }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: haserror === 'true' ? '10px' : '24px',
      [theme.breakpoints.down('laptop')]: {
        marginBottom: haserror === 'true' ? '10px' : '16px',
      },
    };
  },
);

export const ErrorMessageS = styled(Box)(({ theme }) => {
  return {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    color: '#F03758',
    marginTop: '4px',
    [theme.breakpoints.down('mobile')]: {
      fontSize: '11px',
      lineHeight: '16px',
      marginTop: '5px',
    },
  };
});
