import { Box, styled } from '@mui/material';
import bgLeftBlock from '../icons/background/cartImage.png';
import bgLeftMobileBlock from '../icons/background/cartMobileImage.png';
import bgRightBlock from '../icons/background/handImage.png';
import bgRightMobileBlock from '../icons/background/handMobileImage.png';

export const InnerHeader = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.grey[500],
    padding: '40px 0',
    [theme.breakpoints.down('laptop')]: {
      padding: '12px 20px 20px 20px',
    },
  };
});

export const HeadFlex = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    gap: '30px',
    marginBottom: '30px',
    maxWidth: '1240px',
    alignSelf: 'center',
    width: '100%',
    padding: '0 20px',
    [theme.breakpoints.down('laptop')]: {
      flexDirection: 'column',
      gap: '16px',
      marginBottom: '16px',
      padding: 0,
    },
  };
});

export const LeftBlock = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '500px',
    background: '#D0C1EC',
    width: '66%',
    padding: '34px 41px',
    backgroundImage: `url(${bgLeftBlock.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '85px',
    backgroundPositionX: 'right',
    borderRadius: '8px',
    [theme.breakpoints.down('laptop')]: {
      width: '100%',
      height: '319px',
      alignItems: 'center',
      justifyContent: 'end',
      padding: '20px 15px',
      backgroundImage: `url(${bgLeftMobileBlock.src})`,
      backgroundPositionX: 'center',
      backgroundPositionY: 'top',
    },
  };
});

export const Text = styled(Box)(() => {
  return {
    display: 'flex',
    maxWidth: '527px',
    marginBottom: '24px',
  };
});

export const RightBlock = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    height: '500px',
    width: '32%',
    background: 'linear-gradient(150.25deg, #867EFF 0%, #4663F4 100%), #867EFF',
    backgroundImage: `url(${bgRightBlock.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    borderRadius: '8px',
    padding: '38px 40px',
    [theme.breakpoints.down('laptop')]: {
      width: '100%',
      height: '125px',
      padding: '20px 15px 17px 15px',
      backgroundImage: `url(${bgRightMobileBlock.src})`,
      backgroundPositionY: 'center',
      backgroundPositionX: '15px',
    },
  };
});

export const CardsFlex = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '30px',
    maxWidth: '1240px',
    alignSelf: 'center',
    width: '100%',
    padding: '0 20px',
    [theme.breakpoints.down('laptop')]: {
      flexDirection: 'column',
      gap: '16px',
      padding: 0,
    },
  };
});

export const CardItem = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    background: '#fff',
    height: '112px',
    borderRadius: '8px',
    padding: '31px 32px',
    flex: '1 1 33.333%',
    alignItems: 'center',
    [theme.breakpoints.down('laptop')]: {
      width: '100%',
      padding: '13px 16px',
    },
  };
});

export const CardItemText = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2px',
    [theme.breakpoints.down('laptop')]: {
      gap: '0',
    },
  };
});

export const ImageBlock = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    [theme.breakpoints.down('laptop')]: {
      svg: { width: '32px', height: '32px' },
    },
  };
});

export const HideBtn = styled(Box)(({ theme }) => {
  return {
    display: 'none',
    [theme.breakpoints.down('laptop')]: {
      padding: '3px 11px',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: '#96A0B5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#FFFFFF',
      width: 'fit-content',
      borderRadius: '4px',
      alignSelf: 'center',
    },
  };
});
