import { Box, styled } from '@mui/material';

export const CardsBlock = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(282px, 1fr))',
  gap: '20px',
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flexWrap: 'nowrap',
  },
}));

export const Center = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
});
