import { styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import placeholder from 'components/icons/background/placeholder.png';

export const ProductRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid rgba(150, 160, 181, 0.4)',
  borderRadius: '8px',
  paddingTop: '12px',
  paddingBottom: '12px',
  paddingInlineStart: '12px',
  paddingInlineEnd: '30px',
  minWidth: '858px',
  justifyContent: 'space-between',
  '& > :nth-of-type(1)': {
    flex: '1 1 398px',
  },
  '& > :nth-of-type(2)': {
    flex: '1 1 100px',
  },
  '& > :nth-of-type(3)': {
    flex: '1 1 333px',
  },
  '& > :nth-of-type(4)': {
    marginInlineStart: '24px',
  },
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    height: 'auto',
    minHeight: '80px',
    position: 'relative',
    minWidth: 'auto',
    '& > :nth-of-type(1), & > :nth-of-type(2), & > :nth-of-type(3), & > :nth-of-type(4)':
      {
        flex: 'auto',
        width: '100%',
      },
  },
}));

export const ProductCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  [theme.breakpoints.down('laptop')]: {
    padding: '8px',
    alignItems: 'center',
  },
}));

export const DateCell = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 8px 8px 8px',
    order: '100',
  },
}));

export const RefundCell = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

export const ProductImageBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '116px',
  minWidth: '116px',
  height: '66px',
  borderRadius: '4px',
  backgroundImage: `url(${placeholder.src})`,
  backgroundSize: 'contain',
  overflow: 'hidden',
  img: {
    width: '116px',
    height: '66px',
    objectFit: 'cover',
  },
  [theme.breakpoints.down('laptop')]: {
    height: '61px',
    width: '76px',
    minWidth: '76px',
    img: {
      height: '61px',
      width: '76px',
    },
  },
}));

export const DescriptionBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // paddingInlineEnd: '20px',
  [theme.breakpoints.down('laptop')]: {
    alignSelf: 'center',
  },
}));

export const VoucherCodeField = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid rgba(150, 160, 181, 0.4)',
  height: '40px',
  paddingInlineStart: '16px',
  paddingInlineEnd: '10px',
  borderRadius: '6px',
  maxWidth: '280px',
  width: '100%',
  p: {
    fontWeight: 300,
  },
  [theme.breakpoints.down('laptop')]: {
    height: '38px',
    paddingInlineStart: '14px',
    paddingInlineEnd: '7px',
    maxWidth: 'initial',
    svg: {
      width: '20px',
      height: '20px',
    },
    p: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
}));
export const VoucherCodeWrapper = styled(Box)(({ theme }) => ({
  display: 'contents',
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    paddingInlineEnd: '8px',
    paddingInlineStart: '8px',
  },
}));

export const TypographyDate = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const RefundLink = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: '13px',
  lineHeight: '20px',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#4663F4',
  [theme.breakpoints.down('laptop')]: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
  },
}));
export const TypographyCSS = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  maxWidth: '200px',
}));
