import {
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from '@mui/material';

export const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    padding: '0 15px',
    gap: '10px',
    '& .MuiAccordionSummary-content': {
      margin: '12px',
    },
  },
}));

export const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    padding: '15px 15px 17px 15px',
  },
}));

export const TitleTypographyCSS = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const DescriptionTypographyCSS = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 300,
  fontSize: '15px',
  lineHeight: '22px',
  margin: '0',
  whiteSpace: 'break-spaces',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));
