import { Typography } from '@mui/material';
import {
  GeneralProfileMobileIcon,
  LockIcon,
  LogoutIcon,
  MyOrdersMobileNavIcon,
} from '../../icons';
import {
  Aside,
  Content,
  CustomLink,
  CustomLinkTypography,
  ProfileLayoutWrapper,
} from 'components/Layout/ProfileLayout/ProfileLayout.styled';
import { useTranslation } from 'next-i18next';
import { useAuthContext } from 'context';
import { PATHS } from 'utils';
import { KycLevelBlock } from 'components';
import React from 'react';
import { useLaptop } from 'hooks';

interface ProfileLayoutProps {
  children: React.ReactNode;
}

export const ProfileLayout = ({ children }: ProfileLayoutProps) => {
  const { isOverLaptop } = useLaptop();
  const { onLogout } = useAuthContext();

  const handleLogOut = () => {
    onLogout();
  };

  const { t } = useTranslation();

  return (
    <ProfileLayoutWrapper>
      <Aside component={'aside'}>
        {isOverLaptop ? (
          <Typography variant={'big500'} mb={'30px'}>
            {t('app.profile')}
          </Typography>
        ) : null}
        <CustomLink href={PATHS.profile}>
          <GeneralProfileMobileIcon /> {t('app.general')}
        </CustomLink>
        <CustomLink href={PATHS.profileMyOrders}>
          <MyOrdersMobileNavIcon /> {t('app.my_orders')}
        </CustomLink>
        <CustomLink href={PATHS.profilePassword}>
          <LockIcon /> {t('app.password')}
        </CustomLink>
        {isOverLaptop ? (
          <CustomLinkTypography onClick={handleLogOut}>
            <LogoutIcon size={'24'} /> {t('app.logout')}
          </CustomLinkTypography>
        ) : null}
        {isOverLaptop ? <KycLevelBlock sx={{ marginTop: 'auto' }} /> : null}
      </Aside>
      <Content>{children}</Content>
    </ProfileLayoutWrapper>
  );
};
