import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const RetailIcon = ({ color = '#96A0B5' }: IconsTypes) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_452_3351)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.92142 1.97034C4.14482 1.97034 2.66726 3.37229 2.56787 5.17761L2.38176 8.55786C2.2885 10.2519 3.62339 11.6646 5.28717 11.6646C6.84624 11.6646 8.1368 10.4186 8.19508 8.83067L8.44688 1.97034H5.92142ZM9.94789 1.97034L9.68145 9.22942C9.63239 10.5662 10.6916 11.6646 12 11.6646C13.3085 11.6646 14.3677 10.5662 14.3186 9.22942L14.3061 8.8883C14.306 8.88743 14.306 8.88656 14.306 8.88568L14.0522 1.97034H9.94789ZM15.5532 1.97034L15.805 8.83067C15.805 8.83137 15.805 8.83207 15.8051 8.83278C15.8644 10.4197 17.1545 11.6646 18.7129 11.6646C20.3767 11.6646 21.7116 10.2519 21.6183 8.55786L21.4322 5.17761C21.3328 3.37229 19.8552 1.97034 18.0786 1.97034H15.5532ZM15.2039 11.4128C14.5248 12.464 13.3485 13.1646 12 13.1646C10.6516 13.1646 9.47526 12.464 8.79618 11.4128C7.99021 12.4783 6.71772 13.1646 5.28717 13.1646C4.44259 13.1646 3.6571 12.9266 2.99004 12.5155V16.9752C2.99004 19.7731 5.23599 22.0297 7.99229 22.0297H8.47491V18.8409C8.47491 17.7462 9.35619 16.8471 10.4575 16.8471H13.3337C14.435 16.8471 15.3163 17.7462 15.3163 18.8409V22.0297H15.7989C18.5552 22.0297 20.8012 19.7731 20.8012 16.9752V12.6365C20.1813 12.9729 19.4709 13.1646 18.7129 13.1646C17.2823 13.1646 16.0098 12.4783 15.2039 11.4128ZM22.3012 11.3045C22.8642 10.5116 23.1739 9.52697 23.116 8.4754L22.9299 5.09515C22.7874 2.50638 20.6633 0.470337 18.0786 0.470337H5.92142C3.33672 0.470337 1.21266 2.50638 1.07013 5.09515L0.884031 8.4754C0.833755 9.38859 1.06071 10.2513 1.49004 10.9818V16.9752C1.49004 20.5887 4.39482 23.5297 7.99229 23.5297H15.7989C19.3964 23.5297 22.3012 20.5887 22.3012 16.9752V11.3045ZM13.8163 22.0297V18.8409C13.8163 18.5618 13.5938 18.3471 13.3337 18.3471H10.4575C10.1974 18.3471 9.97491 18.5618 9.97491 18.8409V22.0297H13.8163Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_452_3351'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
