import React from 'react';
import { CircularProgress } from '@mui/material';
import { Center } from './LoaderWrapper.styles';

type LoaderWrapperProps = {
  loading?: boolean;
  isDisableCirculate?: boolean;
  children: React.ReactNode;
};

export const LoaderWrapper = ({
  loading = false,
  children,
  isDisableCirculate,
}: LoaderWrapperProps) => {
  return loading ? (
    <Center>
      {!isDisableCirculate ? <CircularProgress size={'48px'} /> : ''}
    </Center>
  ) : (
    <>{children}</>
  );
};
