import { useRouter } from 'next/router';
import { AuthLayout } from 'components/Layout/AuthLayout';
import { MainLayout } from 'components/Layout/MainLayout';
import { authIncludeLayout } from 'utils';
import React from 'react';

interface LayoutProviderProps {
  children: React.ReactNode;
}

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const { pathname } = useRouter();

  if (authIncludeLayout.includes(pathname)) {
    return <AuthLayout>{children}</AuthLayout>;
  } else {
    return <MainLayout>{children}</MainLayout>;
  }
};
