import React from 'react';
import { useTranslation } from 'next-i18next';
import {
  AllProdIcon,
  ClothIcon,
  EcommerceIcon,
  ElectronicsIcon,
  EntertainmentIcon,
  FoodIcon,
  GameIcon,
  HealthIcon,
  HomeIcon,
  MobileIcon,
  OtherProductIcon,
  RetailIcon,
  StreamingIcon,
  TravelIcon,
} from 'components/icons';
import { filterSelectDataTypes } from 'types/optionsTypes';

export const useFilterSelectData = () => {
  const { t } = useTranslation();
  return [
    {
      value: '1',
      label: t('app.category_filter.all_products'),
      icon: <AllProdIcon />,
      new: false,
    },
    {
      value: '2',
      label: t('app.category_filter.ecommerce'),
      icon: <EcommerceIcon />,
      new: false,
    },
    {
      value: '3',
      label: t('app.category_filter.games'),
      icon: <GameIcon />,
      new: false,
    },
    {
      value: '4',
      label: t('app.category_filter.mobile_topup_data'),
      icon: <MobileIcon />,
      new: false,
    },
    {
      value: '5',
      label: t('app.category_filter.retail'),
      icon: <RetailIcon />,
      new: false,
    },
    {
      value: '6',
      label: t('app.category_filter.entertainment'),
      icon: <EntertainmentIcon />,
      new: true,
    },
    {
      value: '7',
      label: t('app.category_filter.streaming'),
      icon: <StreamingIcon />,
      new: false,
    },
    {
      value: '8',
      label: t('app.category_filter.food'),
      icon: <FoodIcon />,
      new: false,
    },
    {
      value: '9',
      label: t('app.category_filter.home'),
      icon: <HomeIcon />,
      new: false,
    },
    {
      value: '10',
      label: t('app.category_filter.electronics'),
      icon: <ElectronicsIcon />,
      new: false,
    },
    {
      value: '11',
      label: t('app.category_filter.travel_flights'),
      icon: <TravelIcon />,
      new: false,
    },
    {
      value: '12',
      label: t('app.category_filter.Apparel_clothing'),
      icon: <ClothIcon />,
      new: false,
    },
    {
      value: '13',
      label: t('app.category_filter.Health_beauty'),
      icon: <HealthIcon />,
      new: false,
    },
    {
      value: '14',
      label: t('app.category_filter.other_products'),
      icon: <OtherProductIcon />,
      new: false,
    },
  ] as filterSelectDataTypes[];
};
