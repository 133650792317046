import { styled } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import Box from '@mui/material/Box';

export const PhoneInputStyled = styled(PhoneInput)(
  ({ theme, localization, disabled }) => {
    const isEn = Object.keys(localization || {}).length === 0;
    return {
      input: {
        width: '100% !important',
        paddingTop: '12px !important',
        paddingBottom: '12px !important',
        paddingInlineStart: isEn ? '80px !important' : '14px !important',
        paddingInlineEnd: isEn ? '14px !important' : '80px !important',
        borderColor: disabled
          ? '#BDBDBD !important'
          : 'rgba(150, 160, 181, 0.4) !important',
        fontWeight: '300 !important',
        fontSize: '15px !important',
        lineHeight: '22px !important',
        color: '#373748',
        direction: 'ltr',
        textAlign: isEn ? 'left' : 'end',
        background: disabled ? '#F7F9FC !important' : 'initial',
        cursor: 'auto !important',
      },
      'input:focus, input:hover': {
        borderColor: '#96A0B5 !important',
        boxShadow: 'none !important',
      },
      '.flag-dropdown': {
        width: '65px',
        borderInlineEnd: '1px solid',
        borderColor: 'rgba(150,160,181,0.4)',
        boxShadow: '0 0 0 1 rgba(150,160,181,0.4) !important',
      },
      '.selected-flag': {
        padding: '0 !important',
        paddingInlineStart: '20px !important',
      },
      '.arrow': {
        insetInlineStart: '29px !important',
      },
      [theme.breakpoints.down('laptop')]: {
        input: {
          paddingTop: '9.5px !important',
          paddingBottom: '9.5px !important',
          paddingInlineStart: isEn ? '68px !important' : '14px !important',
          paddingInlineEnd: isEn ? '14px !important' : '68px !important',
          fontWeight: '300 !important',
          fontSize: '14px !important',
          lineHeight: '21px !important',
        },
        '.selected-flag': {
          padding: '0 !important',
          paddingInlineStart: '14px !important',
        },
        '.flag-dropdown': {
          width: '58px',
        },
      },
    };
  },
);

export const BadgeWrapper = styled(Box)(() => ({
  position: 'absolute',
  insetInlineEnd: 0,
  top: 0,
}));
