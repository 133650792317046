import React from 'react';
import {
  AmountBtn,
  AmountBtnGroupCSS,
  AmountCountField,
} from './AmountBtnGroup.styled';
import { MinusIcon, PlusIcon } from 'components/icons';
import { useAuthContext } from 'context';

interface AmountBtnGroupProps {
  setCount: (x: 'add' | 'subtract') => void;
  count: string | number;
  quantity?: string;
}

export const AmountBtnGroup = ({
  setCount,
  count,
  quantity,
}: AmountBtnGroupProps) => {
  const { isAuth } = useAuthContext();
  const handleCount = (str: 'add' | 'subtract') => {
    setCount(str);
  };

  return (
    <AmountBtnGroupCSS>
      <AmountBtn onClick={() => handleCount('subtract')}>
        <MinusIcon />
      </AmountBtn>
      <AmountCountField>{isAuth ? quantity : count}</AmountCountField>
      <AmountBtn onClick={() => handleCount('add')}>
        <PlusIcon />
      </AmountBtn>
    </AmountBtnGroupCSS>
  );
};
