import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const LogoutIcon = ({ size = '20', color = '#373748' }: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.16667 3.25C3.92355 3.25 3.69039 3.34658 3.51849 3.51849C3.34658 3.69039 3.25 3.92355 3.25 4.16667V15.8333C3.25 16.0764 3.34658 16.3096 3.51849 16.4815C3.69039 16.6534 3.92355 16.75 4.16667 16.75H7.5C7.91421 16.75 8.25 17.0858 8.25 17.5C8.25 17.9142 7.91421 18.25 7.5 18.25H4.16667C3.52573 18.25 2.91104 17.9954 2.45783 17.5422C2.00461 17.089 1.75 16.4743 1.75 15.8333V4.16667C1.75 3.52573 2.00461 2.91104 2.45783 2.45783C2.91104 2.00461 3.52573 1.75 4.16667 1.75H7.5C7.91421 1.75 8.25 2.08579 8.25 2.5C8.25 2.91421 7.91421 3.25 7.5 3.25H4.16667Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.803 5.30317C13.0959 5.01027 13.5707 5.01027 13.8636 5.30317L18.0303 9.46983C18.3232 9.76273 18.3232 10.2376 18.0303 10.5305L13.8636 14.6972C13.5707 14.9901 13.0959 14.9901 12.803 14.6972C12.5101 14.4043 12.5101 13.9294 12.803 13.6365L16.4393 10.0002L12.803 6.36383C12.5101 6.07093 12.5101 5.59606 12.803 5.30317Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.75 10C6.75 9.58579 7.08579 9.25 7.5 9.25H17.5C17.9142 9.25 18.25 9.58579 18.25 10C18.25 10.4142 17.9142 10.75 17.5 10.75H7.5C7.08579 10.75 6.75 10.4142 6.75 10Z'
        fill={color}
      />
    </svg>
  );
};
