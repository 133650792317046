import { Box, BoxProps, styled } from '@mui/material';
import { AliasBoolTypes } from 'types/baseTypes';

interface Props extends BoxProps {
  haserror: AliasBoolTypes;
}

export const WrapperStyled = styled(Box)<Props>(({ theme, haserror }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    svg: {
      border: haserror === 'true' && '1px solid red',
      borderRadius: '6px',
    },
    p: {
      fontWeight: 300,
      fontSize: '15px',
      lineHeight: '19px',
    },
    [theme.breakpoints.up('laptop')]: {
      p: {
        fontSize: '14px',
      },
    },
  };
});
