import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const TitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& :nth-of-type(1)': {
    whiteSpace: 'nowrap',
    flex: '1 0 365px',
  },
  '& :nth-of-type(2)': {
    whiteSpace: 'nowrap',
    flex: '1 0 70px',
  },
  '& :nth-of-type(3)': {
    whiteSpace: 'nowrap',
    flex: '1 0 360px',
  },
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));
