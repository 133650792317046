import React from 'react';

export const NotFoundIcon = () => {
  return (
    <svg
      width='421'
      height='391'
      viewBox='0 0 421 391'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: '100%' }}
    >
      <path
        d='M73.4605 113.3L75.0805 116.57L78.6905 117.1L76.0805 119.65L76.6905 123.25L73.4605 121.55L70.2305 123.25L70.8405 119.65L68.2305 117.1L71.8405 116.57L73.4605 113.3Z'
        fill='#EBEBEB'
      />
      <path
        d='M397.111 267.53L398.731 270.81L402.341 271.33L399.731 273.88L400.341 277.48L397.111 275.78L393.881 277.48L394.491 273.88L391.881 271.33L395.491 270.81L397.111 267.53Z'
        fill='#F5F5F5'
      />
      <path
        d='M333.001 362.61L334.621 365.89L338.241 366.42L335.621 368.96L336.241 372.57L333.001 370.87L329.771 372.57L330.392 368.96L327.771 366.42L331.392 365.89L333.001 362.61Z'
        fill='#F5F5F5'
      />
      <path
        d='M186.352 55.2L186.952 56.42L188.292 56.61C188.368 56.6205 188.439 56.6521 188.498 56.7013C188.557 56.7505 188.601 56.8152 188.625 56.8882C188.649 56.9612 188.652 57.0394 188.634 57.1139C188.616 57.1885 188.577 57.2565 188.522 57.31L187.522 58.26L187.752 59.59C187.765 59.6659 187.756 59.744 187.727 59.8153C187.698 59.8866 187.649 59.9483 187.586 59.9932C187.524 60.038 187.45 60.0644 187.373 60.0691C187.296 60.0739 187.219 60.0569 187.152 60.02L185.952 59.39L184.752 60.02C184.685 60.0583 184.608 60.0765 184.531 60.0722C184.454 60.068 184.38 60.0415 184.317 59.9961C184.255 59.9506 184.207 59.8881 184.179 59.8161C184.152 59.744 184.146 59.6655 184.162 59.59L184.392 58.26L183.392 57.31C183.337 57.2565 183.298 57.1885 183.279 57.1139C183.261 57.0394 183.264 56.9612 183.288 56.8882C183.312 56.8152 183.356 56.7505 183.415 56.7013C183.474 56.6521 183.546 56.6205 183.622 56.61L184.962 56.42L185.562 55.2C185.586 55.1137 185.637 55.0376 185.709 54.9834C185.78 54.9292 185.867 54.8999 185.957 54.8999C186.046 54.8999 186.133 54.9292 186.205 54.9834C186.276 55.0376 186.328 55.1137 186.352 55.2Z'
        fill='#EBEBEB'
      />
      <path
        d='M21.1807 347.05L21.7807 348.27L23.1207 348.47C23.1969 348.478 23.2691 348.508 23.3287 348.556C23.3883 348.604 23.4329 348.669 23.4572 348.741C23.4814 348.814 23.4842 348.892 23.4654 348.966C23.4465 349.04 23.4067 349.108 23.3507 349.16L22.3507 350.11L22.5807 351.45C22.5925 351.525 22.5835 351.601 22.5547 351.671C22.5258 351.741 22.4783 351.801 22.4172 351.845C22.3562 351.89 22.2841 351.917 22.2088 351.923C22.1336 351.929 22.0581 351.914 21.9907 351.88L20.7907 351.25L19.5907 351.88C19.5227 351.913 19.4471 351.927 19.3717 351.921C19.2964 351.914 19.2243 351.888 19.1628 351.843C19.1014 351.799 19.053 351.74 19.0227 351.67C18.9924 351.601 18.9813 351.525 18.9907 351.45L19.2207 350.11L18.2207 349.16C18.1647 349.108 18.1249 349.04 18.1061 348.966C18.0872 348.892 18.0901 348.814 18.1143 348.741C18.1385 348.669 18.1831 348.604 18.2427 348.556C18.3024 348.508 18.3746 348.478 18.4507 348.47L19.7907 348.27L20.3907 347.05C20.4147 346.964 20.4663 346.888 20.5377 346.833C20.609 346.779 20.6961 346.75 20.7857 346.75C20.8753 346.75 20.9624 346.779 21.0338 346.833C21.1051 346.888 21.1567 346.964 21.1807 347.05Z'
        fill='#F5F5F5'
      />
      <path
        d='M48.1602 42.35L48.7602 43.56L50.1002 43.76C50.1763 43.7705 50.2479 43.8021 50.3069 43.8513C50.3659 43.9005 50.4098 43.9652 50.4338 44.0382C50.4578 44.1112 50.4608 44.1894 50.4425 44.264C50.4242 44.3385 50.3853 44.4065 50.3302 44.46L49.3302 45.4L49.5602 46.74C49.5734 46.8159 49.5648 46.894 49.5356 46.9653C49.5064 47.0366 49.4577 47.0983 49.3951 47.1432C49.3324 47.188 49.2584 47.2144 49.1815 47.2191C49.1046 47.2239 49.0279 47.2068 48.9602 47.17L47.7602 46.54L46.5602 47.17C46.4929 47.2041 46.4174 47.2189 46.3421 47.2128C46.2668 47.2067 46.1947 47.18 46.1337 47.1355C46.0727 47.091 46.0251 47.0306 45.9963 46.9608C45.9674 46.891 45.9584 46.8146 45.9702 46.74L46.2002 45.4L45.2002 44.46C45.1452 44.4065 45.1063 44.3385 45.088 44.264C45.0697 44.1894 45.0727 44.1112 45.0967 44.0382C45.1206 43.9652 45.1646 43.9005 45.2236 43.8513C45.2826 43.8021 45.3542 43.7705 45.4302 43.76L46.7702 43.56L47.3702 42.35C47.3852 42.256 47.4332 42.1705 47.5056 42.1087C47.578 42.047 47.6701 42.0131 47.7652 42.0131C47.8604 42.0131 47.9524 42.047 48.0248 42.1087C48.0972 42.1705 48.1452 42.256 48.1602 42.35Z'
        fill='#EBEBEB'
      />
      <path
        d='M46.5403 283.37C46.5324 283.631 46.4478 283.884 46.2969 284.098C46.1461 284.311 45.9357 284.475 45.6921 284.57C45.4485 284.664 45.1824 284.685 44.9271 284.629C44.6718 284.574 44.4386 284.444 44.2566 284.256C44.0746 284.069 43.9518 283.832 43.9036 283.575C43.8555 283.318 43.8841 283.053 43.9858 282.812C44.0875 282.572 44.2579 282.366 44.4757 282.222C44.6934 282.077 44.9489 282 45.2103 282C45.3883 282 45.5646 282.036 45.7286 282.105C45.8927 282.174 46.0411 282.276 46.1651 282.404C46.2892 282.531 46.3863 282.683 46.4507 282.849C46.5152 283.015 46.5456 283.192 46.5403 283.37Z'
        fill='#EBEBEB'
      />
      <path
        d='M236.08 116.94C236.08 117.203 236.002 117.46 235.856 117.679C235.71 117.898 235.502 118.068 235.259 118.169C235.016 118.269 234.748 118.296 234.49 118.244C234.232 118.193 233.995 118.066 233.809 117.88C233.623 117.694 233.497 117.457 233.445 117.199C233.394 116.941 233.42 116.674 233.521 116.431C233.622 116.188 233.792 115.98 234.011 115.834C234.23 115.688 234.487 115.61 234.75 115.61C235.102 115.613 235.439 115.754 235.687 116.002C235.936 116.251 236.077 116.588 236.08 116.94Z'
        fill='#EBEBEB'
      />
      <path
        d='M167.111 43.45C167.111 43.7135 167.033 43.971 166.886 44.1899C166.74 44.4089 166.531 44.5793 166.288 44.6797C166.044 44.7801 165.776 44.8058 165.518 44.7537C165.26 44.7015 165.023 44.5739 164.837 44.3869C164.652 44.1999 164.526 43.9619 164.476 43.7033C164.425 43.4446 164.453 43.1769 164.555 42.9341C164.658 42.6912 164.83 42.4842 165.05 42.3393C165.27 42.1943 165.528 42.118 165.791 42.12C166.142 42.1226 166.478 42.2639 166.725 42.5131C166.972 42.7622 167.111 43.099 167.111 43.45Z'
        fill='#F5F5F5'
      />
      <path
        d='M169.222 367.588C173.172 366.655 175.619 362.697 174.687 358.746C173.754 354.795 169.796 352.348 165.845 353.281C161.894 354.213 159.447 358.172 160.38 362.123C161.312 366.073 165.271 368.52 169.222 367.588Z'
        fill='#F0F0F0'
      />
      <path
        d='M165.001 356.14C163.495 356.137 162.028 356.613 160.811 357.5C160.189 358.926 160.037 360.512 160.375 362.03C160.713 363.548 161.525 364.919 162.693 365.946C163.861 366.973 165.325 367.602 166.874 367.743C168.423 367.884 169.976 367.529 171.311 366.73C171.904 365.637 172.202 364.409 172.177 363.166C172.153 361.923 171.805 360.708 171.168 359.64C170.532 358.572 169.629 357.688 168.548 357.074C167.466 356.46 166.244 356.139 165.001 356.14Z'
        fill='#E6E6E6'
      />
      <path
        d='M108.09 232.69H47.2305V205.22L108.09 132.88H137.2V206.78H152.29V232.69H137.2V255.16H108.09V232.69ZM108.09 206.78V168.94L75.9305 206.78H108.09Z'
        fill='#407BFF'
      />
      <path
        d='M162.71 194.56C162.71 171.7 166.824 155.7 175.05 146.56C183.277 137.42 195.81 132.853 212.65 132.86C220.75 132.86 227.394 133.86 232.58 135.86C237.307 137.567 241.636 140.221 245.3 143.66C248.386 146.602 250.999 150.002 253.05 153.74C255.075 157.651 256.604 161.8 257.6 166.09C259.881 175.268 261.023 184.693 261 194.15C261 215.963 257.31 231.927 249.93 242.04C242.55 252.153 229.837 257.21 211.79 257.21C201.677 257.21 193.504 255.6 187.27 252.38C180.984 249.102 175.688 244.203 171.93 238.19C169.03 233.757 166.777 227.7 165.17 220.02C163.472 211.641 162.648 203.109 162.71 194.56ZM195.84 194.64C195.84 209.973 197.194 220.43 199.9 226.01C202.607 231.59 206.53 234.39 211.67 234.41C213.303 234.43 214.923 234.126 216.437 233.515C217.951 232.904 219.329 231.998 220.49 230.85C222.97 228.47 224.8 224.71 225.98 219.57C227.16 214.43 227.747 206.43 227.74 195.57C227.74 179.57 226.39 168.84 223.69 163.38C220.99 157.92 216.93 155.173 211.51 155.14C205.977 155.14 201.977 157.94 199.51 163.54C197.044 169.14 195.82 179.507 195.84 194.64Z'
        fill='#407BFF'
      />
      <path
        d='M332.151 232.69H271.291V205.22L332.151 132.88H361.261V206.78H376.361V232.69H361.261V255.16H332.151V232.69ZM332.151 206.78V168.94L300.001 206.78H332.151Z'
        fill='#407BFF'
      />
      <g opacity='0.3'>
        <path
          d='M161.361 90.67C161.361 91.0398 161.251 91.4014 161.046 91.7089C160.84 92.0164 160.548 92.2561 160.207 92.3976C159.865 92.5392 159.489 92.5762 159.126 92.5041C158.764 92.4319 158.43 92.2538 158.169 91.9923C157.907 91.7307 157.729 91.3975 157.657 91.0348C157.585 90.6721 157.622 90.2961 157.763 89.9544C157.905 89.6127 158.145 89.3206 158.452 89.1151C158.76 88.9097 159.121 88.8 159.491 88.8C159.987 88.8 160.463 88.997 160.813 89.3477C161.164 89.6984 161.361 90.174 161.361 90.67Z'
          fill='#407BFF'
        />
        <path
          d='M33.3708 162.5C34.0998 162.5 34.6908 161.909 34.6908 161.18C34.6908 160.451 34.0998 159.86 33.3708 159.86C32.6418 159.86 32.0508 160.451 32.0508 161.18C32.0508 161.909 32.6418 162.5 33.3708 162.5Z'
          fill='#407BFF'
        />
        <path
          d='M251.46 355.27C252.504 355.27 253.35 354.424 253.35 353.38C253.35 352.336 252.504 351.49 251.46 351.49C250.416 351.49 249.57 352.336 249.57 353.38C249.57 354.424 250.416 355.27 251.46 355.27Z'
          fill='#407BFF'
        />
        <path
          d='M296.9 278.37C297.629 278.37 298.22 277.779 298.22 277.05C298.22 276.321 297.629 275.73 296.9 275.73C296.171 275.73 295.58 276.321 295.58 277.05C295.58 277.779 296.171 278.37 296.9 278.37Z'
          fill='#407BFF'
        />
        <path
          d='M384.581 40.67C384.552 41.0016 384.4 41.3102 384.155 41.5351C383.909 41.76 383.589 41.8848 383.256 41.8848C382.923 41.8848 382.602 41.76 382.357 41.5351C382.112 41.3102 381.96 41.0016 381.931 40.67C381.915 40.4863 381.937 40.3013 381.997 40.1268C382.056 39.9522 382.151 39.7919 382.275 39.656C382.4 39.5201 382.552 39.4116 382.72 39.3374C382.889 39.2631 383.071 39.2248 383.256 39.2248C383.44 39.2248 383.623 39.2631 383.791 39.3374C383.96 39.4116 384.112 39.5201 384.236 39.656C384.361 39.7919 384.456 39.9522 384.515 40.1268C384.574 40.3013 384.597 40.4863 384.581 40.67Z'
          fill='#407BFF'
        />
        <path
          d='M133.16 14C133.162 14.2639 133.085 14.5224 132.94 14.7426C132.795 14.9629 132.587 15.1349 132.344 15.2368C132.1 15.3387 131.832 15.3659 131.573 15.3149C131.314 15.2639 131.076 15.137 130.89 14.9504C130.703 14.7638 130.576 14.5259 130.525 14.267C130.474 14.0081 130.501 13.7398 130.603 13.4964C130.705 13.253 130.877 13.0454 131.097 12.9C131.318 12.7546 131.576 12.678 131.84 12.68C132.19 12.68 132.526 12.8191 132.773 13.0666C133.021 13.3142 133.16 13.6499 133.16 14Z'
          fill='#407BFF'
        />
        <path
          d='M238.101 83.31C238.83 83.31 239.421 82.719 239.421 81.99C239.421 81.261 238.83 80.67 238.101 80.67C237.372 80.67 236.781 81.261 236.781 81.99C236.781 82.719 237.372 83.31 238.101 83.31Z'
          fill='#407BFF'
        />
      </g>
      <path
        d='M101.64 83.32C113.741 83.32 123.55 73.5106 123.55 61.41C123.55 49.3094 113.741 39.5 101.64 39.5C89.5399 39.5 79.7305 49.3094 79.7305 61.41C79.7305 73.5106 89.5399 83.32 101.64 83.32Z'
        fill='#407BFF'
      />
      <path
        opacity='0.7'
        d='M101.64 83.32C113.741 83.32 123.55 73.5106 123.55 61.41C123.55 49.3094 113.741 39.5 101.64 39.5C89.5399 39.5 79.7305 49.3094 79.7305 61.41C79.7305 73.5106 89.5399 83.32 101.64 83.32Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M94.0916 44.88C91.1211 44.8755 88.181 45.4778 85.4516 46.65C82.0875 50.3549 80.0924 55.0993 79.7978 60.0949C79.5032 65.0905 80.9268 70.0365 83.832 74.1111C86.7373 78.1857 90.9493 81.1436 95.7681 82.4932C100.587 83.8428 105.723 83.5029 110.322 81.53C113.174 78.3866 115.052 74.4827 115.728 70.2924C116.405 66.102 115.85 61.8054 114.131 57.9243C112.412 54.0433 109.604 50.7447 106.047 48.4291C102.489 46.1135 98.3361 44.8806 94.0916 44.88Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M91.9102 50.67C91.9102 51.0655 91.7929 51.4522 91.5731 51.7811C91.3533 52.11 91.041 52.3664 90.6755 52.5177C90.3101 52.6691 89.9079 52.7087 89.52 52.6316C89.132 52.5544 88.7756 52.3639 88.4959 52.0842C88.2162 51.8045 88.0258 51.4481 87.9486 51.0602C87.8714 50.6722 87.911 50.2701 88.0624 49.9046C88.2138 49.5392 88.4701 49.2268 88.799 49.007C89.1279 48.7873 89.5146 48.67 89.9102 48.67C90.4406 48.67 90.9493 48.8807 91.3244 49.2558C91.6994 49.6308 91.9102 50.1395 91.9102 50.67Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M115.471 48.67C115.471 49.0655 115.353 49.4522 115.134 49.7811C114.914 50.11 114.602 50.3664 114.236 50.5177C113.871 50.6691 113.468 50.7087 113.081 50.6316C112.693 50.5544 112.336 50.3639 112.056 50.0842C111.777 49.8045 111.586 49.4481 111.509 49.0602C111.432 48.6722 111.472 48.2701 111.623 47.9046C111.774 47.5392 112.031 47.2268 112.36 47.007C112.688 46.7873 113.075 46.67 113.471 46.67C114.001 46.67 114.51 46.8807 114.885 47.2558C115.26 47.6308 115.471 48.1395 115.471 48.67Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M111.46 63C111.46 63.6491 111.268 64.2837 110.907 64.8233C110.546 65.3629 110.033 65.7833 109.433 66.0313C108.833 66.2792 108.173 66.3436 107.537 66.2162C106.9 66.0888 106.316 65.7755 105.858 65.3157C105.399 64.856 105.088 64.2706 104.962 63.6337C104.837 62.9968 104.903 62.337 105.153 61.7378C105.403 61.1387 105.825 60.6271 106.365 60.2679C106.906 59.9087 107.541 59.718 108.19 59.72C108.621 59.72 109.047 59.8049 109.444 59.9699C109.841 60.1348 110.202 60.3766 110.506 60.6813C110.81 60.986 111.051 61.3477 111.214 61.7455C111.378 62.1434 111.462 62.5697 111.46 63Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M101.051 72.29C101.051 73.1563 100.794 74.0031 100.313 74.7234C99.8316 75.4437 99.1475 76.0051 98.3472 76.3366C97.5468 76.6681 96.6662 76.7548 95.8165 76.5858C94.9669 76.4168 94.1864 75.9997 93.5739 75.3871C92.9613 74.7746 92.5442 73.9941 92.3752 73.1445C92.2062 72.2949 92.2929 71.4142 92.6244 70.6138C92.9559 69.8135 93.5173 69.1294 94.2376 68.6482C94.9579 68.1669 95.8047 67.91 96.671 67.91C97.8327 67.91 98.9467 68.3715 99.7681 69.1929C100.59 70.0143 101.051 71.1284 101.051 72.29Z'
        fill='#407BFF'
      />
      <path
        d='M361.314 325.705C363.753 315.368 357.352 305.011 347.015 302.572C336.679 300.132 326.322 306.534 323.882 316.87C321.443 327.206 327.844 337.563 338.181 340.003C348.517 342.443 358.874 336.041 361.314 325.705Z'
        fill='#407BFF'
      />
      <path
        opacity='0.3'
        d='M361.314 325.705C363.753 315.368 357.352 305.011 347.015 302.572C336.679 300.132 326.322 306.534 323.882 316.87C321.443 327.206 327.844 337.563 338.181 340.003C348.517 342.443 358.874 336.041 361.314 325.705Z'
        fill='white'
      />
      <path
        opacity='0.4'
        d='M354.74 306.39C350.479 305.112 345.907 305.351 341.802 307.066C337.698 308.781 334.315 311.866 332.23 315.796C330.144 319.726 329.486 324.257 330.367 328.617C331.248 332.978 333.613 336.898 337.06 339.71C341.321 340.988 345.894 340.749 349.998 339.034C354.103 337.319 357.486 334.234 359.571 330.304C361.656 326.374 362.314 321.843 361.434 317.483C360.553 313.122 358.187 309.202 354.74 306.39Z'
        fill='#407BFF'
      />
      <path
        d='M324.24 327C303.7 336.66 319.02 344.1 347.95 333.71C374.73 324.08 384.95 311.94 361 315.71C362.23 320.81 328.69 333.88 324.24 327Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M354.501 132.88C327.611 151.05 292.801 175.01 283.191 232.69H281.141C290.491 175.69 324.031 151.12 350.931 132.88H354.501Z'
        fill='black'
      />
      <path
        d='M215.42 313.31C198.42 313.31 181.62 305.65 173.42 293.13C168.37 285.39 162.5 269.18 179.97 247.55L181.53 248.81C169.17 264.11 166.88 279.46 175.09 292.03C185.09 307.34 208.69 315.03 228.82 309.56C249.47 304 262 286 263.2 260.33C267.34 172.53 313.99 145.48 348.05 125.73C369.21 113.46 384.5 104.59 381.14 85.89C381.031 84.8073 380.695 83.7598 380.154 82.8156C379.613 81.8714 378.879 81.0518 378 80.41C373.65 77.54 365.15 79.52 355.3 81.82C336 86.31 309.56 92.48 293.81 65.68L295.53 64.68C310.53 90.2 335.11 84.48 354.85 79.88C365.14 77.48 374.03 75.41 379.1 78.75C381.24 80.16 382.56 82.38 383.1 85.55C386.71 105.63 370.1 115.27 349.04 127.47C315.38 146.99 269.28 173.72 265.19 260.47C264 287 250.89 305.64 229.37 311.49C224.822 312.714 220.13 313.326 215.42 313.31Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M215.42 313.31C198.42 313.31 181.62 305.65 173.42 293.13C168.37 285.39 162.5 269.18 179.97 247.55L181.53 248.81C169.17 264.11 166.88 279.46 175.09 292.03C185.09 307.34 208.69 315.03 228.82 309.56C249.47 304 262 286 263.2 260.33C267.34 172.53 313.99 145.48 348.05 125.73C369.21 113.46 384.5 104.59 381.14 85.89C381.031 84.8073 380.695 83.7598 380.154 82.8156C379.613 81.8714 378.879 81.0518 378 80.41C373.65 77.54 365.15 79.52 355.3 81.82C336 86.31 309.56 92.48 293.81 65.68L295.53 64.68C310.53 90.2 335.11 84.48 354.85 79.88C365.14 77.48 374.03 75.41 379.1 78.75C381.24 80.16 382.56 82.38 383.1 85.55C386.71 105.63 370.1 115.27 349.04 127.47C315.38 146.99 269.28 173.72 265.19 260.47C264 287 250.89 305.64 229.37 311.49C224.822 312.714 220.13 313.326 215.42 313.31Z'
        fill='white'
      />
      <path
        d='M273.16 42C277.771 42.026 282.352 42.744 286.75 44.13C286.75 44.13 297.75 62.9 299.05 67.2C298.59 71.44 291.43 78.39 291.43 78.39L273.16 42Z'
        fill='#407BFF'
      />
      <path
        opacity='0.3'
        d='M273.16 42C277.771 42.026 282.352 42.744 286.75 44.13C286.75 44.13 297.75 62.9 299.05 67.2C298.59 71.44 291.43 78.39 291.43 78.39L273.16 42Z'
        fill='white'
      />
      <path
        d='M305.751 133.18C301.512 127.999 297.649 122.521 294.191 116.79C293.351 115.35 292.551 113.87 291.801 112.38C291.371 111.53 290.951 110.67 290.551 109.81C290.027 108.977 289.623 108.075 289.351 107.13C288.151 94.46 292.491 85.13 288.351 74.96L271.881 81.39C271.881 81.39 273.281 99.52 276.481 110.39C278.481 117.11 282.951 122.94 287.281 128.32C288.641 130.01 289.941 131.73 291.281 133.42C292.621 135.11 293.991 136.49 295.281 138.08C297.221 140.49 297.871 142.79 296.391 145.64C296.311 145.79 296.231 145.94 296.141 146.09C295.721 146.83 297.691 147.67 298.931 146.09C300.931 143.51 300.651 143.67 302.391 141.47C303.441 140.13 304.661 138.69 305.711 137.47C306.211 136.869 306.489 136.114 306.496 135.332C306.503 134.55 306.24 133.79 305.751 133.18Z'
        fill='#407BFF'
      />
      <path
        opacity='0.7'
        d='M305.751 133.18C301.512 127.999 297.649 122.521 294.191 116.79C293.351 115.35 292.551 113.87 291.801 112.38C291.371 111.53 290.951 110.67 290.551 109.81C290.027 108.977 289.623 108.075 289.351 107.13C288.151 94.46 292.491 85.13 288.351 74.96L271.881 81.39C271.881 81.39 273.281 99.52 276.481 110.39C278.481 117.11 282.951 122.94 287.281 128.32C288.641 130.01 289.941 131.73 291.281 133.42C292.621 135.11 293.991 136.49 295.281 138.08C297.221 140.49 297.871 142.79 296.391 145.64C296.311 145.79 296.231 145.94 296.141 146.09C295.721 146.83 297.691 147.67 298.931 146.09C300.931 143.51 300.651 143.67 302.391 141.47C303.441 140.13 304.661 138.69 305.711 137.47C306.211 136.869 306.489 136.114 306.496 135.332C306.503 134.55 306.24 133.79 305.751 133.18Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M301.721 128C299.102 130.804 296.196 133.324 293.051 135.52L294.341 136.98C297.485 134.852 300.341 132.328 302.841 129.47C302.461 129 302.091 128.46 301.721 128Z'
        fill='#407BFF'
      />
      <path
        opacity='0.3'
        d='M305.739 133.18C305.699 133.136 305.663 133.089 305.629 133.04C304.847 133.915 304.397 135.037 304.359 136.21C304.283 137.013 304.414 137.822 304.739 138.56L305.689 137.43C306.184 136.835 306.459 136.088 306.468 135.314C306.478 134.54 306.22 133.786 305.739 133.18Z'
        fill='#407BFF'
      />
      <path
        d='M269.25 54C267.451 56.6689 265.306 59.0868 262.87 61.19C261.52 62.3563 260.04 63.363 258.46 64.19C257.624 64.6164 256.758 64.9807 255.87 65.28L255.2 65.49L254.98 65.56L254.5 65.69C254.213 65.7586 253.923 65.8054 253.63 65.83C252.785 65.8924 251.937 65.8113 251.12 65.59C250.087 65.3046 249.098 64.8807 248.18 64.33C247.429 63.8912 246.701 63.4139 246 62.9C244.752 61.97 243.56 60.9683 242.43 59.9C240.242 57.8275 238.235 55.5727 236.43 53.16C236.082 52.69 235.909 52.1134 235.941 51.5296C235.973 50.9459 236.208 50.3917 236.605 49.9626C237.002 49.5335 237.537 49.2565 238.116 49.1794C238.696 49.1022 239.284 49.2298 239.78 49.54H239.85C242.24 51 244.63 52.61 247 54C248.17 54.69 249.32 55.39 250.45 55.93C250.962 56.1969 251.49 56.434 252.03 56.64C252.37 56.7953 252.736 56.8868 253.11 56.91C253.16 56.91 253.11 56.84 252.73 56.85C252.616 56.8431 252.503 56.8431 252.39 56.85H252.17C252.1 56.85 252.17 56.85 252.17 56.85L252.51 56.68C252.95 56.4394 253.377 56.1757 253.79 55.89C254.685 55.2563 255.525 54.547 256.3 53.77C258.041 51.9345 259.677 50.0013 261.2 47.98C261.998 46.9125 263.188 46.2059 264.507 46.0155C265.826 45.8252 267.167 46.1667 268.235 46.965C269.302 47.7633 270.009 48.953 270.199 50.2723C270.389 51.5916 270.048 52.9325 269.25 54Z'
        fill='#407BFF'
      />
      <path
        opacity='0.7'
        d='M269.25 54C267.451 56.6689 265.306 59.0868 262.87 61.19C261.52 62.3563 260.04 63.363 258.46 64.19C257.624 64.6164 256.758 64.9807 255.87 65.28L255.2 65.49L254.98 65.56L254.5 65.69C254.213 65.7586 253.923 65.8054 253.63 65.83C252.785 65.8924 251.937 65.8113 251.12 65.59C250.087 65.3046 249.098 64.8807 248.18 64.33C247.429 63.8912 246.701 63.4139 246 62.9C244.752 61.97 243.56 60.9683 242.43 59.9C240.242 57.8275 238.235 55.5727 236.43 53.16C236.082 52.69 235.909 52.1134 235.941 51.5296C235.973 50.9459 236.208 50.3917 236.605 49.9626C237.002 49.5335 237.537 49.2565 238.116 49.1794C238.696 49.1022 239.284 49.2298 239.78 49.54H239.85C242.24 51 244.63 52.61 247 54C248.17 54.69 249.32 55.39 250.45 55.93C250.962 56.1969 251.49 56.434 252.03 56.64C252.37 56.7953 252.736 56.8868 253.11 56.91C253.16 56.91 253.11 56.84 252.73 56.85C252.616 56.8431 252.503 56.8431 252.39 56.85H252.17C252.1 56.85 252.17 56.85 252.17 56.85L252.51 56.68C252.95 56.4394 253.377 56.1757 253.79 55.89C254.685 55.2563 255.525 54.547 256.3 53.77C258.041 51.9345 259.677 50.0013 261.2 47.98C261.998 46.9125 263.188 46.2059 264.507 46.0155C265.826 45.8252 267.167 46.1667 268.235 46.965C269.302 47.7633 270.009 48.953 270.199 50.2723C270.389 51.5916 270.048 52.9325 269.25 54Z'
        fill='white'
      />
      <path
        d='M232.689 47.46L233.859 49.46C233.859 49.46 234.759 52.08 236.549 52.56L241.399 51L241.159 50.59C240.529 49.64 240.599 47.82 240.819 46.3C241.039 44.78 240.249 44.72 239.669 45.1C239.251 45.5732 238.959 46.1441 238.819 46.76C238.584 46.4299 238.323 46.1189 238.039 45.83L236.559 44.35C236.251 44.0429 235.838 43.8648 235.403 43.8518C234.968 43.8388 234.545 43.9919 234.219 44.28L232.999 45.31C232.706 45.577 232.515 45.9373 232.459 46.3294C232.402 46.7216 232.484 47.1212 232.689 47.46Z'
        fill='#407BFF'
      />
      <path
        opacity='0.7'
        d='M232.689 47.46L233.859 49.46C233.859 49.46 234.759 52.08 236.549 52.56L241.399 51L241.159 50.59C240.529 49.64 240.599 47.82 240.819 46.3C241.039 44.78 240.249 44.72 239.669 45.1C239.251 45.5732 238.959 46.1441 238.819 46.76C238.584 46.4299 238.323 46.1189 238.039 45.83L236.559 44.35C236.251 44.0429 235.838 43.8648 235.403 43.8518C234.968 43.8388 234.545 43.9919 234.219 44.28L232.999 45.31C232.706 45.577 232.515 45.9373 232.459 46.3294C232.402 46.7216 232.484 47.1212 232.689 47.46Z'
        fill='white'
      />
      <path
        d='M278.081 40.27C272.685 41.6925 267.517 43.8708 262.731 46.74C261.986 47.2059 261.401 47.889 261.056 48.6971C260.711 49.5052 260.622 50.3996 260.801 51.26C262.721 60.7 267.111 73.35 271.851 81.39L294.001 72.25C294.161 68.35 288.791 55.73 283.321 43.53C282.301 41.34 280.421 39.7 278.081 40.27Z'
        fill='#407BFF'
      />
      <path
        opacity='0.8'
        d='M278.081 40.27C272.685 41.6925 267.517 43.8708 262.731 46.74C261.986 47.2059 261.401 47.889 261.056 48.6971C260.711 49.5052 260.622 50.3996 260.801 51.26C262.721 60.7 267.111 73.35 271.851 81.39L294.001 72.25C294.161 68.35 288.791 55.73 283.321 43.53C282.301 41.34 280.421 39.7 278.081 40.27Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M286.701 51.26L282.311 49.79C283.311 52.36 286.851 55.61 289.311 57.52C288.521 55.52 287.631 53.42 286.701 51.26Z'
        fill='#407BFF'
      />
      <path
        d='M276.619 30.37C274.799 26.89 270.849 25.13 266.109 25.53C262.109 25.86 258.559 29.95 258.979 32.15C259.399 34.35 262.769 35.29 263.379 36.06L260.609 38.06C260.281 38.2969 260.004 38.5976 259.795 38.9443C259.586 39.2909 259.45 39.6762 259.394 40.077C259.338 40.4778 259.363 40.8858 259.469 41.2764C259.575 41.667 259.759 42.0322 260.009 42.35C261.179 43.83 262.709 45.35 263.609 46.47C271.269 46.27 276.939 43.35 278.979 40.53C278.249 37 278.439 33.83 276.619 30.37Z'
        fill='#407BFF'
      />
      <path
        opacity='0.8'
        d='M276.619 30.37C274.799 26.89 270.849 25.13 266.109 25.53C262.109 25.86 258.559 29.95 258.979 32.15C259.399 34.35 262.769 35.29 263.379 36.06L260.609 38.06C260.281 38.2969 260.004 38.5976 259.795 38.9443C259.586 39.2909 259.45 39.6762 259.394 40.077C259.338 40.4778 259.363 40.8858 259.469 41.2764C259.575 41.667 259.759 42.0322 260.009 42.35C261.179 43.83 262.709 45.35 263.609 46.47C271.269 46.27 276.939 43.35 278.979 40.53C278.249 37 278.439 33.83 276.619 30.37Z'
        fill='white'
      />
      <path
        d='M272.869 32.52C273.418 33.9161 273.54 35.4435 273.22 36.9089C272.9 38.3743 272.152 39.7118 271.072 40.7522C269.991 41.7925 268.626 42.4889 267.15 42.7531C265.673 43.0174 264.152 42.8376 262.777 42.2366C261.403 41.6356 260.238 40.6404 259.429 39.377C258.621 38.1135 258.206 36.6386 258.236 35.139C258.266 33.6393 258.741 32.1824 259.599 30.9525C260.458 29.7226 261.662 28.7752 263.059 28.23C263.985 27.8674 264.973 27.6908 265.968 27.7101C266.962 27.7295 267.942 27.9445 268.853 28.3429C269.764 28.7413 270.588 29.3152 271.277 30.0319C271.966 30.7487 272.507 31.5941 272.869 32.52Z'
        fill='#263238'
      />
      <path
        d='M337.791 122.64C337.681 119.36 337.541 119.64 337.451 116.88C337.391 115.18 337.381 113.29 337.371 111.66C337.366 110.886 337.094 110.138 336.603 109.541C336.111 108.944 335.429 108.534 334.671 108.38C333.341 108.11 332.011 107.86 330.671 107.57C328.951 107.21 327.241 106.81 325.541 106.32C324.231 105.94 322.931 105.51 321.641 105.05C320.351 104.59 318.901 104.05 317.551 103.43C315.981 102.75 314.421 102.03 312.871 101.29C311.151 100.457 309.437 99.6067 307.731 98.74C301.171 87.85 301.001 80.67 294.001 72.25L278.801 79.1C278.801 79.1 289.941 98.87 297.531 107.25C301.901 112.07 308.751 114.25 314.861 115.82C319.261 116.96 323.731 117.82 328.211 118.65C329.951 118.97 331.841 119.1 333.341 120.14C334.453 120.987 335.212 122.216 335.471 123.59C335.548 123.867 335.612 124.147 335.661 124.43C335.821 125.26 337.861 124.63 337.791 122.64Z'
        fill='#407BFF'
      />
      <path
        opacity='0.8'
        d='M337.791 122.64C337.681 119.36 337.541 119.64 337.451 116.88C337.391 115.18 337.381 113.29 337.371 111.66C337.366 110.886 337.094 110.138 336.603 109.541C336.111 108.944 335.429 108.534 334.671 108.38C333.341 108.11 332.011 107.86 330.671 107.57C328.951 107.21 327.241 106.81 325.541 106.32C324.231 105.94 322.931 105.51 321.641 105.05C320.351 104.59 318.901 104.05 317.551 103.43C315.981 102.75 314.421 102.03 312.871 101.29C311.151 100.457 309.437 99.6067 307.731 98.74C301.171 87.85 301.001 80.67 294.001 72.25L278.801 79.1C278.801 79.1 289.941 98.87 297.531 107.25C301.901 112.07 308.751 114.25 314.861 115.82C319.261 116.96 323.731 117.82 328.211 118.65C329.951 118.97 331.841 119.1 333.341 120.14C334.453 120.987 335.212 122.216 335.471 123.59C335.548 123.867 335.612 124.147 335.661 124.43C335.821 125.26 337.861 124.63 337.791 122.64Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M330.111 107.45L328.301 107C328.351 110.45 326.731 116.42 326.131 118.22L328.031 118.59C329.279 115.002 329.98 111.247 330.111 107.45Z'
        fill='#407BFF'
      />
      <path
        opacity='0.3'
        d='M337.371 111.66C337.365 110.886 337.094 110.138 336.603 109.541C336.111 108.944 335.429 108.534 334.671 108.38C334.361 108.31 334.031 108.24 333.721 108.19C333.878 109.328 334.46 110.364 335.351 111.09C335.92 111.62 336.604 112.011 337.351 112.23C337.371 112 337.371 111.84 337.371 111.66Z'
        fill='#407BFF'
      />
      <path
        d='M271.451 32.59C271.861 34.1 270.161 35.7 268.801 34.39C267.543 33.0337 266.164 31.7953 264.681 30.69C263.291 29.82 265.151 28.31 267.331 28.89C268.268 29.1234 269.133 29.5826 269.851 30.2276C270.569 30.8726 271.119 31.6837 271.451 32.59Z'
        fill='white'
      />
      <path
        d='M271.571 80.91C270.861 81.17 272.151 82.36 272.151 82.36C272.151 82.36 286.151 77.58 294.641 72.65C294.665 72.3521 294.617 72.0528 294.5 71.7776C294.383 71.5025 294.202 71.2597 293.971 71.07C286.693 74.7635 279.214 78.0486 271.571 80.91Z'
        fill='#407BFF'
      />
      <path
        opacity='0.5'
        d='M271.571 80.91C270.861 81.17 272.151 82.36 272.151 82.36C272.151 82.36 286.151 77.58 294.641 72.65C294.665 72.3521 294.617 72.0528 294.5 71.7776C294.383 71.5025 294.202 71.2597 293.971 71.07C286.693 74.7635 279.214 78.0486 271.571 80.91Z'
        fill='white'
      />
      <path
        d='M281.87 39.61C284.62 41 287.22 42.48 289.81 44.11C291.1 44.92 292.391 45.76 293.661 46.66C294.931 47.56 296.191 48.47 297.451 49.52L297.93 49.91L298.52 50.44C298.873 50.7707 299.207 51.1213 299.52 51.49C299.84 51.84 300.11 52.18 300.37 52.49C300.63 52.8 300.901 53.17 301.131 53.49C302.065 54.7756 302.93 56.1109 303.721 57.49C305.272 60.1634 306.61 62.9554 307.721 65.84C307.922 66.3841 307.929 66.981 307.741 67.5298C307.553 68.0786 307.182 68.5458 306.689 68.8525C306.197 69.1592 305.614 69.2867 305.038 69.2134C304.463 69.14 303.93 68.8704 303.531 68.45L303.481 68.4C301.481 66.27 299.541 64.03 297.601 61.94C295.991 59.9907 294.132 58.2624 292.071 56.8C289.801 55.39 287.271 53.98 284.761 52.59L277.191 48.4C276.018 47.7794 275.141 46.7185 274.75 45.4508C274.36 44.183 274.49 42.8123 275.11 41.64C275.731 40.4677 276.792 39.59 278.06 39.2C279.327 38.8099 280.698 38.9394 281.87 39.56V39.61Z'
        fill='#407BFF'
      />
      <path
        opacity='0.8'
        d='M281.87 39.61C284.62 41 287.22 42.48 289.81 44.11C291.1 44.92 292.391 45.76 293.661 46.66C294.931 47.56 296.191 48.47 297.451 49.52L297.93 49.91L298.52 50.44C298.873 50.7707 299.207 51.1213 299.52 51.49C299.84 51.84 300.11 52.18 300.37 52.49C300.63 52.8 300.901 53.17 301.131 53.49C302.065 54.7756 302.93 56.1109 303.721 57.49C305.272 60.1634 306.61 62.9554 307.721 65.84C307.922 66.3841 307.929 66.981 307.741 67.5298C307.553 68.0786 307.182 68.5458 306.689 68.8525C306.197 69.1592 305.614 69.2867 305.038 69.2134C304.463 69.14 303.93 68.8704 303.531 68.45L303.481 68.4C301.481 66.27 299.541 64.03 297.601 61.94C295.991 59.9907 294.132 58.2624 292.071 56.8C289.801 55.39 287.271 53.98 284.761 52.59L277.191 48.4C276.018 47.7794 275.141 46.7185 274.75 45.4508C274.36 44.183 274.49 42.8123 275.11 41.64C275.731 40.4677 276.792 39.59 278.06 39.2C279.327 38.8099 280.698 38.9394 281.87 39.56V39.61Z'
        fill='white'
      />
      <path
        d='M310.141 70.79L309.291 68.66C309.291 68.66 308.821 65.94 307.121 65.18L302.121 65.97L302.301 66.41C302.771 67.41 302.421 69.23 301.961 70.7C301.501 72.17 302.281 72.34 302.911 72.06C303.281 71.9 303.621 71.25 304.011 70.56C304.194 70.9096 304.405 71.244 304.641 71.56L305.871 73.25C306.126 73.6025 306.507 73.8436 306.935 73.9236C307.363 74.0036 307.805 73.9164 308.171 73.68L309.521 72.82C309.838 72.6009 310.071 72.2813 310.184 71.9128C310.296 71.5444 310.281 71.1487 310.141 70.79Z'
        fill='#407BFF'
      />
      <path
        opacity='0.8'
        d='M310.141 70.79L309.291 68.66C309.291 68.66 308.821 65.94 307.121 65.18L302.121 65.97L302.301 66.41C302.771 67.41 302.421 69.23 301.961 70.7C301.501 72.17 302.281 72.34 302.911 72.06C303.281 71.9 303.621 71.25 304.011 70.56C304.194 70.9096 304.405 71.244 304.641 71.56L305.871 73.25C306.126 73.6025 306.507 73.8436 306.935 73.9236C307.363 74.0036 307.805 73.9164 308.171 73.68L309.521 72.82C309.838 72.6009 310.071 72.2813 310.184 71.9128C310.296 71.5444 310.281 71.1487 310.141 70.79Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M277.639 51.1L276.419 51.2L268.939 69.29C269.349 69.3195 269.76 69.2857 270.159 69.19C270.159 69.19 279.919 65.55 282.869 64.19C280.879 60.1 277.639 51.1 277.639 51.1Z'
        fill='#407BFF'
      />
      <path
        d='M264 55.85C264.911 60.576 266.6 65.1179 269 69.29C272.65 68.03 278.76 65.65 281.71 64.29C279.742 60.0095 277.997 55.6303 276.48 51.17C273.27 51.41 266.41 54 264 55.85Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M271.41 59.76C271.265 60.2489 270.977 60.6837 270.585 61.0094C270.192 61.3351 269.712 61.5371 269.204 61.5899C268.697 61.6427 268.185 61.5439 267.734 61.306C267.283 61.0681 266.912 60.7017 266.669 60.2532C266.426 59.8048 266.321 59.2943 266.368 58.7864C266.415 58.2784 266.612 57.7958 266.933 57.3994C267.254 57.0031 267.686 56.7109 268.173 56.5597C268.66 56.4085 269.181 56.4051 269.67 56.55C269.995 56.6463 270.298 56.8058 270.562 57.0193C270.825 57.2328 271.044 57.4961 271.206 57.7943C271.367 58.0924 271.468 58.4195 271.504 58.7568C271.539 59.0941 271.507 59.435 271.41 59.76Z'
        fill='#407BFF'
      />
      <path
        opacity='0.6'
        d='M273.32 56.32C273.271 56.4808 273.176 56.6237 273.047 56.7305C272.917 56.8373 272.758 56.9033 272.591 56.9201C272.424 56.9368 272.256 56.9037 272.107 56.8248C271.959 56.7459 271.837 56.6249 271.758 56.4769C271.678 56.3289 271.644 56.1606 271.66 55.9934C271.676 55.8261 271.741 55.6673 271.847 55.5371C271.954 55.4069 272.096 55.3111 272.257 55.2618C272.417 55.2124 272.589 55.2118 272.75 55.26C272.965 55.327 273.145 55.4755 273.251 55.6737C273.358 55.8718 273.382 56.1039 273.32 56.32Z'
        fill='#407BFF'
      />
      <path
        opacity='0.6'
        d='M275.551 55.45C275.471 55.6459 275.321 55.8049 275.13 55.8959C274.939 55.9869 274.721 56.0034 274.518 55.9422C274.315 55.8809 274.143 55.7463 274.034 55.5646C273.926 55.383 273.889 55.1674 273.931 54.96C273.954 54.844 274.002 54.7342 274.07 54.6375C274.139 54.5409 274.226 54.4597 274.328 54.3989C274.43 54.3382 274.543 54.2994 274.66 54.2849C274.778 54.2705 274.897 54.2807 275.01 54.315C275.123 54.3492 275.228 54.4068 275.318 54.4839C275.408 54.5611 275.481 54.6561 275.531 54.7629C275.582 54.8698 275.61 54.9861 275.614 55.1044C275.617 55.2227 275.596 55.3404 275.551 55.45Z'
        fill='#407BFF'
      />
      <path
        opacity='0.5'
        d='M278.701 63.23L269.451 66.89L268.891 65.07L278.141 61.41L278.701 63.23Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M227.659 202.22C227.644 208.042 227.088 213.851 225.999 219.57C224.819 224.71 222.985 228.47 220.499 230.85C219.34 232 217.965 232.908 216.452 233.52C214.94 234.133 213.32 234.439 211.689 234.42C206.549 234.42 202.625 231.617 199.919 226.01C199.443 225.003 199.039 223.964 198.709 222.9C192.992 227.81 187.669 233.159 182.789 238.9L177.479 245.16C180.336 248.056 183.633 250.483 187.249 252.35C193.482 255.57 201.655 257.183 211.769 257.19C213.549 257.19 215.269 257.14 216.939 257.04C223.061 247.981 228.127 238.251 232.039 228.04L246.889 189.32L227.659 202.22Z'
        fill='black'
      />
      <path
        d='M93.7891 255L111.299 272.5L160.299 226.33C143.439 219.93 110.559 238.24 93.7891 255Z'
        fill='#263238'
      />
      <path
        d='M154.83 316L137.33 298.5L183.49 249.5C189.9 266.39 171.6 299.27 154.83 316Z'
        fill='#263238'
      />
      <path
        d='M221.798 205.75L240.898 168.94L204.088 188C192.26 194.129 181.34 201.87 171.638 211L108.898 270.11L139.728 300.94L198.798 238.19C207.927 228.491 215.667 217.574 221.798 205.75Z'
        fill='#407BFF'
      />
      <path
        opacity='0.6'
        d='M221.798 205.75L240.898 168.94L204.088 188C192.26 194.129 181.34 201.87 171.638 211L108.898 270.11L139.728 300.94L198.798 238.19C207.927 228.491 215.667 217.574 221.798 205.75Z'
        fill='white'
      />
      <path
        d='M182.599 240.13C189.724 240.13 195.499 234.354 195.499 227.23C195.499 220.106 189.724 214.33 182.599 214.33C175.475 214.33 169.699 220.106 169.699 227.23C169.699 234.354 175.475 240.13 182.599 240.13Z'
        fill='white'
      />
      <path
        d='M182.599 235.52C187.177 235.52 190.889 231.808 190.889 227.23C190.889 222.652 187.177 218.94 182.599 218.94C178.02 218.94 174.309 222.652 174.309 227.23C174.309 231.808 178.02 235.52 182.599 235.52Z'
        fill='#407BFF'
      />
      <path
        opacity='0.3'
        d='M150.149 289.86L119.969 259.68L144.679 236.42L173.409 265.15L150.149 289.86Z'
        fill='#407BFF'
      />
      <path
        d='M100.629 282.66C77.9994 284.5 70.5294 299 67.9994 318.2C66.6994 328.01 66.1194 337.94 57.8794 343.68C57.3835 344.017 57.0102 344.506 56.8158 345.073C56.6213 345.64 56.6161 346.255 56.801 346.825C56.9858 347.396 57.3508 347.891 57.8409 348.236C58.3311 348.581 58.9201 348.758 59.5194 348.74C89.8594 347.79 104.009 332.93 105.779 326.74C105.318 329.986 104.483 333.167 103.289 336.22C103.071 336.759 103.028 337.352 103.167 337.916C103.306 338.481 103.62 338.987 104.063 339.362C104.506 339.738 105.057 339.964 105.636 340.009C106.215 340.053 106.794 339.914 107.289 339.61C115.809 334.27 126.489 324.45 127.199 308.53C120.909 299.65 100.629 282.66 100.629 282.66Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M100.629 282.66C77.9994 284.5 70.5294 299 67.9994 318.2C66.6994 328.01 66.1194 337.94 57.8794 343.68C57.3835 344.017 57.0102 344.506 56.8158 345.073C56.6213 345.64 56.6161 346.255 56.801 346.825C56.9858 347.396 57.3508 347.891 57.8409 348.236C58.3311 348.581 58.9201 348.758 59.5194 348.74C89.8594 347.79 104.009 332.93 105.779 326.74C105.318 329.986 104.483 333.167 103.289 336.22C103.071 336.759 103.028 337.352 103.167 337.916C103.306 338.481 103.62 338.987 104.063 339.362C104.506 339.738 105.057 339.964 105.636 340.009C106.215 340.053 106.794 339.914 107.289 339.61C115.809 334.27 126.489 324.45 127.199 308.53C120.909 299.65 100.629 282.66 100.629 282.66Z'
        fill='white'
      />
      <path
        d='M130.68 315.35L94.4902 279.15L113.77 274.98L134.85 296.06L130.68 315.35Z'
        fill='#407BFF'
      />
    </svg>
  );
};
