import {
  Typography,
  TextField,
  InputAdornment,
  FormLabel,
  IconButton,
  SxProps,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { CSSProperties, useState } from 'react';

import {
  ErrorMessageS,
  WrapperStyled,
} from 'components/uiKit/TextFieldFormik/TextFieldFormik.styled';
import { EyeIcon, EyeOffIcon } from '../../icons';
import { VerifyBadge } from 'components';
import { DefaultTFuncReturn } from 'i18next';

interface TextFieldFormikProps {
  name: string | DefaultTFuncReturn;
  label?: string | DefaultTFuncReturn;
  nativeLabel?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  maxLength?: number;
  type?: 'text' | 'number' | 'password';
  sx?: SxProps | CSSProperties;
  variant?: 'filled' | 'outlined' | 'standard';
  size?: 'medium' | 'small';
  verified?: boolean;
  verifiedOption?: boolean;
  disabled?: boolean;
}

export const TextFieldFormik = ({
  name,
  label,
  nativeLabel,
  placeholder = '',
  maxLength = Infinity,
  type = 'text',
  sx,
  variant,
  size = 'medium',
  verified = false,
  verifiedOption = true,
  disabled,
}: TextFieldFormikProps) => {
  const [field, meta] = useField(String(name));
  const { handleChange } = useFormikContext();
  const hasError = Boolean(meta.error && meta.touched);

  const [viewPassword, setViewPassword] = useState(false);

  const isSizeMedium = size !== 'small';

  return (
    <WrapperStyled
      style={sx as CSSProperties}
      haserror={hasError ? 'true' : 'false'}
    >
      {!nativeLabel && label ? (
        <FormLabel
          htmlFor={String(name)}
          sx={{ marginBottom: isSizeMedium ? '6px' : '4px' }}
        >
          <Typography variant={isSizeMedium ? 'tf' : 'tfs'}>{label}</Typography>
        </FormLabel>
      ) : null}
      <TextField
        sx={{ svg: { margin: '0 !important' } }}
        size={size}
        variant={variant}
        id={String(name)}
        name={String(name)}
        value={field.value}
        onChange={handleChange}
        label={nativeLabel || ''}
        error={hasError}
        placeholder={String(placeholder)}
        fullWidth
        type={viewPassword ? 'text' : type}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position='start'
              sx={{ cursor: !verified ? 'pointer' : 'default' }}
            >
              {verified ? <VerifyBadge verify={verifiedOption} /> : null}
              {type === 'password' ? (
                <IconButton
                  onClick={() => {
                    setViewPassword(!viewPassword);
                  }}
                >
                  {!viewPassword ? <EyeIcon /> : <EyeOffIcon />}
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
        inputProps={{ maxLength: maxLength }}
      />
      {hasError && <ErrorMessageS>{meta.error || ''}</ErrorMessageS>}
    </WrapperStyled>
  );
};
