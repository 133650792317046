import React, { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ArrowIcon } from '../../icons';
import { useRouter } from 'next/router';
import {
  AuthCodeWrapper,
  ButtonFW,
  FormWrapper,
} from 'components/Form/SignInForm.styled';
import { useCartContext, useModalContext } from 'context';
import { useLaptop } from 'hooks';
import { AlertCustom, CircularProgressCustom } from 'components';
import { AUTH_PATHS, getErrorName, MODAL_NAME } from 'utils';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { FormConfirmHeader } from '..';
import { useConfirmRegistrationMutation } from 'graphql/generated';
import { useAuthVariablesStore } from 'Store';

export const ConfirmEmailForm = () => {
  const { isOverLaptop } = useLaptop();
  const [error, setError] = useState(false);
  const [code, setCode] = useState('');
  const ref = useRef<AuthCodeRef>(null);

  const [email, clearEmail] = useAuthVariablesStore((state) => [
    state.email,
    state.clearEmail,
  ]);

  const { onOpenModal } = useModalContext();
  const { isProductInTheCart } = useCartContext();

  const router = useRouter();
  const { t } = useTranslation();
  const isDisabledBtn = code.length < 4;

  const [confirmRegistrationMutation, { data, loading }] =
    useConfirmRegistrationMutation();

  const handleOnChange = (res: any) => {
    setCode(res);
  };

  const handleSubmit = async () => {
    if (loading || data?.confirmRegistration) return;

    await confirmRegistrationMutation({
      variables: {
        input: {
          email: email,
          code: code,
        },
      },
      onCompleted: (data) => {
        if (!data.confirmRegistration) {
          setError(true);
          (ref.current as AuthCodeRef | null)?.clear();
        } else {
          clearEmail();
          // * if mobile and cart is empty
          if (!isOverLaptop || !isProductInTheCart) {
            router.push(AUTH_PATHS.success);
          } else {
            onOpenModal(MODAL_NAME.success);
          }
        }
      },
      onError: () => (ref.current as AuthCodeRef | null)?.clear(),
    });
  };

  return (
    <FormWrapper>
      <FormConfirmHeader
        title={t('app.auth.confirmation_email')}
        subTitle={t('app.auth.please_enter_email_address')}
      />
      <AuthCodeWrapper>
        <AuthCode
          onChange={handleOnChange}
          length={4}
          containerClassName={'containerClass'}
          inputClassName={'inputClass'}
          ref={ref}
        />
        <ButtonFW
          disabled={isDisabledBtn}
          onClick={handleSubmit}
          endIcon={
            loading ? (
              <CircularProgressCustom loading={true} color={'#fff'} />
            ) : (
              <ArrowIcon />
            )
          }
          size={!isOverLaptop ? 'medium' : 'large'}
        >
          {t('app.profilePage.confirm')}
        </ButtonFW>
      </AuthCodeWrapper>
      {error ? (
        <AlertCustom type={'error'} mt={isOverLaptop ? '24px' : '16px'}>
          {getErrorName('notConfirmEmail')}
        </AlertCustom>
      ) : null}
    </FormWrapper>
  );
};
