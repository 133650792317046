import React from 'react';
import { Box, IconButton } from '@mui/material';
import { CloseDialogIcon } from '../../icons';
import {
  DialogStyled,
  FormWrapper,
  IconButtonWrapper,
} from 'components/Modal/ModalContent/ModalContent.styled';
import { useModalContext } from 'context';
import { MODAL_NAME } from 'utils';
import {
  ConfirmEmailForm,
  ConfirmPasswordForm,
  ForgotPasswordForm,
  NewPasswordForm,
  SignInForm,
  SuccessForm,
  PhoneVerificationForm,
  DonePhoneForm,
  SuccessPasswordForm,
  SignUpForm,
} from 'components/Form';

export const ModalContent = () => {
  const { openModal, onCloseModal } = useModalContext();
  if (!openModal.name) return null;

  return (
    <DialogStyled open={openModal.open} onClose={() => onCloseModal()}>
      <FormWrapper>
        <TypeModal />
      </FormWrapper>
      <IconButtonWrapper>
        <IconButton onClick={onCloseModal}>
          <CloseDialogIcon />
        </IconButton>
      </IconButtonWrapper>
    </DialogStyled>
  );
};

const TypeModal = () => {
  const { openModal } = useModalContext();

  switch (openModal.name) {
    case MODAL_NAME.signIn:
      return <SignInForm />;
    case MODAL_NAME.signUp:
      return <SignUpForm />;
    case MODAL_NAME.confirmEmail:
      return <ConfirmEmailForm />;
    case MODAL_NAME.success:
      return <SuccessForm />;
    case MODAL_NAME.forgotPassword:
      return <ForgotPasswordForm />;
    case MODAL_NAME.confirmPasswordForm:
      return <ConfirmPasswordForm />;
    case MODAL_NAME.newPassword:
      return <NewPasswordForm />;
    case MODAL_NAME.successPassword:
      return <SuccessPasswordForm />;
    case MODAL_NAME.phoneVerification:
      return <PhoneVerificationForm />;
    case MODAL_NAME.donePhone:
      return <DonePhoneForm />;
    default:
      return <Box />;
  }
};
