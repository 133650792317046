import { gql } from '@apollo/client';

export const GET_SKUS_BY_ID = gql`
  query GetSkusByIds($ids: [ID!]!) {
    getSkusByIds(ids: $ids) {
      id
      productId
      nameEN
      nameAR
      value
      providerId
      upayId
      createdAt
      updatedAt
      product {
        id
        nameEN
        nameAR
        siteIds
        shortDescriptionEN
        shortDescriptionAR
        fullDescriptionEN
        fullDescriptionAR
        instructionEN
        instructionAR
        termsEN
        termsAR
        sorting
        image
        countries
        baseCurrencyId
        categoryId
        createdAt
        updatedAt
        baseCurrency {
          id
          name
        }
        category {
          id
          nameEN
          nameAR
          icon
          sorting
          parentId
          createdAt
          updatedAt
          parent {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
          }
          children {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
          }
        }
        skus {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          upayId
          createdAt
          updatedAt
        }
        preset {
          isPreset
          presetName
        }
      }
      carts {
        id
        userId
        skuId
        quantity
        sku {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          upayId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
