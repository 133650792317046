import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { Flex, Wrapper } from 'components/KycLevelBlock/KycLevelBlock.styled';
import {
  useConvertCurrencyMutation,
  useGetAllCurrencyQuery,
  useGetCurrentUserQuery,
  useGetKycLimitsQuery,
} from 'graphql/generated';
import { useLaptop } from 'hooks';
import { useSiteIdContext } from 'context/SiteIdContext';
import { getAllValue, getIdCurrency, getPercent } from 'utils';
import { useIntlContext } from 'context/IntlContext';
import { CurrencyType } from 'types/baseTypes';

interface KycLevelBlockProps {
  sx?: SxProps;
}

export const KycLevelBlock = ({ sx }: KycLevelBlockProps) => {
  const { isOverLaptop } = useLaptop();
  const { t } = useTranslation();
  const { siteId } = useSiteIdContext();
  const { currentCurrency } = useIntlContext();
  const { data: fiatAllCurrency } = useGetAllCurrencyQuery();

  const currencyId = getIdCurrency(
    fiatAllCurrency?.getAllCurrency as any,
    currentCurrency as CurrencyType,
  );

  const { data } = useGetKycLimitsQuery({
    variables: {
      getKycLimitsId: siteId.toString(),
    },
  });

  const { data: userData, loading } = useGetCurrentUserQuery();
  const kycLevel = userData?.getCurrentUser.kycLevel;
  const valueSpent = userData?.getCurrentUser.total;

  const valueAll = getAllValue(kycLevel, data?.getKycLimits);
  const percentage = getPercent(valueSpent || 0, +valueAll);

  const [convertCurrency] = useConvertCurrencyMutation();

  const [value, setValue] = useState<
    Record<string, string | number | undefined>
  >({
    value1: 0,
    value2: 0,
  });

  useEffect(() => {
    if (!valueSpent || !valueAll) return;
    Promise.all([
      convertCurrency({
        variables: {
          input: {
            currencyFromId: 1, // USD
            currencyToId: currencyId,
            value: Number(valueSpent),
          },
        },
      }),
      convertCurrency({
        variables: {
          input: {
            currencyFromId: 1, // USD
            currencyToId: currencyId,
            value: +valueAll,
          },
        },
      }),
    ]).then((res) =>
      setValue({
        value1: res[0].data?.convertCurrency,
        value2: res[1].data?.convertCurrency,
      }),
    );
  }, [currencyId, valueAll, valueSpent]);

  if (loading) return <Box />;
  return (
    <Wrapper sx={sx}>
      <Typography
        component={'span'}
        variant={isOverLaptop ? 'average600' : 'mid500'}
      >
        {t('app.profilePage.kyc_Level')}:{' '}
        <Typography
          component={'span'}
          variant={isOverLaptop ? 'big1000' : 'mid600'}
        >
          {kycLevel}
        </Typography>
      </Typography>
      <LinearProgress
        variant='determinate'
        value={percentage}
        sx={{ margin: isOverLaptop ? '12px 0' : '10px 0' }}
      />
      <Flex>
        <Typography variant={isOverLaptop ? 'averageMob450' : 'averageMob450'}>
          {t('app.profilePage.your_limit')}:
        </Typography>
        <Typography
          component={'span'}
          variant={'averageMob500'}
          sx={{
            maxWidth: isOverLaptop ? '140px' : 'initial',
            overflow: 'hidden',
          }}
        >
          {Number(value.value1 || valueSpent).toFixed()} /
          <Typography component={'span'} variant={'averageMob450'}>
            {' '}
            {Number(value.value2 || valueAll).toFixed()} {currentCurrency}
          </Typography>
        </Typography>
      </Flex>
    </Wrapper>
  );
};
