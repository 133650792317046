import { i18n } from 'next-i18next';

export const getErrorName = (code: string) => {
  switch (code) {
    case 'Invalid email or password':
      return i18n?.t('app.error.invalidEmailOrPassword');
    case 'notConfirmEmail':
      return i18n?.t('app.error.notConfirmEmail');
    case 'Mailgun access denied':
      return i18n?.t('app.error.mailgun_access_denied');
    case 'notUserOrNotVerify':
      return i18n?.t('app.error.user_not_found_or_not_verified');
    case '4001':
      return i18n?.t('app.error.incorrect_code');
    case '4002':
      return i18n?.t('app.error.invalid_phone_number');
    case '4003':
      return i18n?.t('app.error.invalidEmailOrPassword');
    case '4004':
      return i18n?.t('app.error.invalid_old_password');
    case '5002':
      return i18n?.t('app.error.product_already_added_to_cart');
    case '5003':
      return i18n?.t('app.error.userAlreadyExists');
    case '5005':
      return i18n?.t('app.error.this_phone_number_is_already_in_use');
    default:
      return i18n?.t('app.error.occurredError');
  }
};
