import { Box, BoxProps, styled, Typography } from '@mui/material';
import placeholder from '../icons/background/placeholder.png';

interface ProductRowProps extends BoxProps {
  disabled?: boolean;
}

export const ProductRow = styled(Box)<ProductRowProps>(
  ({ theme, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(150, 160, 181, 0.4)',
    borderRadius: '8px',
    padding: '12px',
    background: disabled ? '#F7F9FC' : '#fff',
    height: '100px',
    '& > :nth-of-type(1)': {
      flex: '1 1 559px',
    },
    '& > :nth-of-type(2)': {
      flex: '1 1 191px',
    },
    '& > :nth-of-type(3)': {
      flex: '1 1 155px',
    },
    '& > :nth-of-type(4)': {
      flex: '1 1 149px',
      justifyContent: 'end',
    },
    '> div:not(:last-of-type)': {
      opacity: disabled ? '0.2' : '1',
    },
    '> div:last-of-type': {
      button: {
        opacity: '1',
      },
      '> span, > hr': {
        opacity: disabled ? '0.2' : '1',
      },
    },
    [theme.breakpoints.down('laptop')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      height: 'auto',
      minHeight: '80px',
      position: 'relative',
      '& > :nth-of-type(1), & > :nth-of-type(2), & > :nth-of-type(3), & > :nth-of-type(4)':
        {
          flex: 'auto',
          width: '100%',
        },
      '> div:last-of-type': {
        div: {
          opacity: disabled ? '0.2' : '1',
        },
      },
    },
  }),
);

export const ProductCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  [theme.breakpoints.down('laptop')]: {
    padding: '8px',
    alignItems: 'start',
  },
}));

export const ProductImageBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '134px',
  minWidth: '134px',
  height: '76px',
  borderRadius: '4px',
  overflow: 'hidden',
  backgroundImage: `url(${placeholder.src})`,
  backgroundSize: 'contain',
  img: {
    width: '134px',
    height: '76px',
    objectFit: 'cover',
  },
  [theme.breakpoints.down('laptop')]: {
    backgroundSize: '100% 100%',
    backgroundRepeat: 'repeat',
    height: '79px',
    width: '76px',
    minWidth: '76px',
    img: {
      height: '79px',
      width: '76px',
    },
  },
}));

export const DescriptionBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('laptop')]: {
    alignSelf: 'flex-start',
    paddingInlineEnd: '25px',
  },
}));

export const AmountWrap = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  whiteSpace: 'nowrap',
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  paddingInlineEnd: '20px',
  [theme.breakpoints.down('laptop')]: {
    WebkitLineClamp: 1,
    maxWidth: '100%',
  },
}));

// ! mobile only
export const MobileFooterCardWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    padding: '8px',
    justifyContent: 'space-between',
  },
}));

export const MobileFooterCardBlock = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
