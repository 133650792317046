import React from 'react';

export const MyOrdersMobileNavIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9537 18.2871C14.5239 18.7169 13.941 18.9583 13.3333 18.9583L3.33325 18.9583C2.72546 18.9583 2.14257 18.7169 1.7128 18.2871C1.28303 17.8574 1.04159 17.2745 1.04159 16.6667L1.04159 3.33334C1.04159 2.72555 1.28303 2.14266 1.7128 1.71289C2.14257 1.28312 2.72547 1.04168 3.33325 1.04168L16.6666 1.04168C17.2744 1.04168 17.8573 1.28312 18.287 1.71289C18.7168 2.14266 18.9583 2.72556 18.9583 3.33334L18.9583 10.8333C18.9583 12.0952 17.9284 13.125 16.6666 13.125L15.6249 13.125L15.6249 16.6667C15.6249 17.2745 15.3835 17.8574 14.9537 18.2871ZM15.6249 11.875L16.6666 11.875C17.2381 11.875 17.7083 11.4048 17.7083 10.8333L17.7083 3.33334C17.7083 3.05708 17.5985 2.79213 17.4032 2.59677C17.2078 2.40142 16.9429 2.29168 16.6666 2.29168C16.3903 2.29168 16.1254 2.40142 15.93 2.59677C15.7347 2.79213 15.6249 3.05708 15.6249 3.33334L15.6249 11.875ZM14.6253 2.29168C14.4622 2.6113 14.3749 2.96782 14.3749 3.33334L14.3749 16.6667C14.3749 16.9429 14.2652 17.2079 14.0698 17.4032C13.8745 17.5986 13.6095 17.7083 13.3333 17.7083L3.33325 17.7083C3.05699 17.7083 2.79203 17.5986 2.59668 17.4032C2.40133 17.2079 2.29159 16.9429 2.29159 16.6667L2.29159 3.33334C2.29159 3.05708 2.40133 2.79212 2.59668 2.59677C2.79204 2.40142 3.05699 2.29168 3.33325 2.29168L14.6253 2.29168Z'
        fill='#96A0B5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6667 6.45834L10.0001 6.45834C9.6549 6.45834 9.37508 6.17852 9.37508 5.83334C9.37508 5.48817 9.6549 5.20834 10.0001 5.20834L11.6667 5.20834C12.0119 5.20834 12.2917 5.48816 12.2917 5.83334C12.2917 6.17852 12.0119 6.45834 11.6667 6.45834Z'
        fill='#96A0B5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6667 10.625L10.0001 10.625C9.6549 10.625 9.37508 10.3452 9.37508 10C9.37508 9.65482 9.6549 9.375 10.0001 9.375L11.6667 9.375C12.0119 9.375 12.2917 9.65482 12.2917 10C12.2917 10.3452 12.0119 10.625 11.6667 10.625Z'
        fill='#96A0B5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6667 14.7917L10.0001 14.7917C9.6549 14.7917 9.37508 14.5118 9.37508 14.1667C9.37508 13.8215 9.6549 13.5417 10.0001 13.5417L11.6667 13.5417C12.0119 13.5417 12.2917 13.8215 12.2917 14.1667C12.2917 14.5118 12.0119 14.7917 11.6667 14.7917Z'
        fill='#96A0B5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.94194 4.55806C8.18602 4.80214 8.18602 5.19786 7.94194 5.44194L6.27527 7.10861C6.0312 7.35269 5.63547 7.35269 5.39139 7.10861L4.55806 6.27527C4.31398 6.0312 4.31398 5.63547 4.55806 5.39139C4.80214 5.14731 5.19786 5.14731 5.44194 5.39139L5.83333 5.78278L7.05806 4.55806C7.30214 4.31398 7.69786 4.31398 7.94194 4.55806Z'
        fill='#96A0B5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.94194 8.72471C8.18602 8.96879 8.18602 9.36452 7.94194 9.6086L6.27527 11.2753C6.0312 11.5193 5.63547 11.5193 5.39139 11.2753L4.55806 10.4419C4.31398 10.1979 4.31398 9.80213 4.55806 9.55805C4.80214 9.31397 5.19786 9.31397 5.44194 9.55805L5.83333 9.94944L7.05806 8.72471C7.30214 8.48064 7.69786 8.48064 7.94194 8.72471Z'
        fill='#96A0B5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.94194 12.8914C8.18602 13.1355 8.18602 13.5312 7.94194 13.7753L6.27527 15.442C6.0312 15.686 5.63547 15.686 5.39139 15.442L4.55806 14.6086C4.31398 14.3645 4.31398 13.9688 4.55806 13.7247C4.80214 13.4807 5.19786 13.4807 5.44194 13.7247L5.83333 14.1161L7.05806 12.8914C7.30214 12.6473 7.69786 12.6473 7.94194 12.8914Z'
        fill='#96A0B5'
      />
    </svg>
  );
};
