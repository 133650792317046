import * as React from 'react';
import Box from '@mui/material/Box';
import { ChevronDownIcon } from '../../icons';
import { MenuItem, Select, SxProps, Typography } from '@mui/material';
import { ImageBlock } from 'components/uiKit/CountrySelect/CountrySelect.styled';
import { countriesDataTypes } from 'types/optionsTypes';
import Image from 'next/image';

interface CountrySelectProps {
  fullWidth?: boolean;
  value?: number | string;
  setValue: (str: string) => void;
  options?: countriesDataTypes[];
  border?: boolean;
  setFocus: (bool: boolean) => void;
  sx?: SxProps;
}

export const CountrySelect = ({
  fullWidth = true,
  value,
  setValue,
  options = [],
  border = false,
  setFocus,
  sx,
}: CountrySelectProps) => {
  return (
    <Select
      onOpen={() => setFocus(true)}
      onClose={() => setFocus(false)}
      displayEmpty
      renderValue={
        value !== ''
          ? undefined
          : () => (
              <Typography variant={'average400'} color={'#96A0B5'}>
                Search...
              </Typography>
            )
      }
      IconComponent={({ ...rest }) => <ChevronDownIcon {...rest} />}
      value={value}
      fullWidth={fullWidth}
      onChange={(e) => {
        setValue(e.target.value.toString());
      }}
      sx={{
        border: !border ? 'none' : '',
        ...sx,
        '.MuiSelect-select .MuiBox-root >.MuiBox-root': {
          '&:last-of-type': {
            maxWidth: '165px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline',
          },
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 50,
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },

        PaperProps: {
          sx: {
            background: '#ffffff',
            boxShadow: '0px 20px 64px -10px rgba(55, 55, 72, 0.3)',
            maxHeight: '350px',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              borderRadius: '2px',
              width: '15px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#96A0B5',
              borderRadius: '10px',
              border: '5px solid #fff',
            },
          },
        },
      }}
    >
      {options?.map((option) => {
        const src = `https://flagcdn.com/w20/${option.code.toLowerCase()}.png`;

        return (
          <MenuItem key={option.id} value={option.code}>
            <Box display={'flex'}>
              <ImageBlock>
                <Image
                  loading='lazy'
                  width='24'
                  height='15'
                  loader={() => src}
                  src={src}
                  // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=''
                  unoptimized
                />
              </ImageBlock>

              <Box display={'flex'} fontWeight={300}>
                {option.label}
              </Box>
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};
