import React from 'react';
import { Button, Typography } from '@mui/material';
import { ArrowIcon } from '../icons';
import { useTranslation } from 'next-i18next';
import {
  CardItem,
  CardItemText,
  CardsFlex,
  HeadFlex,
  HideBtn,
  ImageBlock,
  InnerHeader,
  LeftBlock,
  RightBlock,
  Text,
} from 'components/InnerHeadHome/InnerHeadHome.styled';
import { useLaptop } from 'hooks';
import { useInnerHeaderShowStore } from 'Store';
import { useCardItemData } from 'utils';

export const InnerHeadHome = () => {
  const { isOverLaptop } = useLaptop();
  const { t } = useTranslation();
  const cardItemData = useCardItemData();
  const [hidden, setHidden] = useInnerHeaderShowStore((state) => [
    state.hidden,
    state.setHidden,
  ]);

  const handleShow = () => {
    setHidden();
  };

  return (
    <InnerHeader>
      <HideBtn
        mb={hidden ? '-8px' : '12px'}
        role={'presentation'}
        onClick={handleShow}
      >
        {hidden
          ? t('app.inner_head_home.show_section')
          : t('app.inner_head_home.hide_section')}
      </HideBtn>
      {!hidden || isOverLaptop ? (
        <>
          <HeadFlex>
            <LeftBlock>
              <Text>
                <Typography
                  color={'#ffffff'}
                  variant={isOverLaptop ? 'big600' : 'bigMob500'}
                  textAlign={isOverLaptop ? 'start' : 'center'}
                >
                  {t('app.inner_head_home.quis_sit_velit')}
                </Typography>
              </Text>
              <Button endIcon={<ArrowIcon />}>
                {t('app.inner_head_home.see_details')}
              </Button>
            </LeftBlock>
            <RightBlock>
              <Typography
                color={'#fff'}
                variant={isOverLaptop ? 'big600' : 'tf'}
                sx={{ marginLeft: !isOverLaptop ? '106px' : '0px' }}
              >
                {t('app.inner_head_home.pay_for_services_with_any')}{' '}
                <Typography
                  color={'#fff'}
                  variant={isOverLaptop ? 'big600' : 'bigMob500'}
                  fontWeight={700}
                  component={'span'}
                >
                  {t('app.inner_head_home.cryptocurrency')}
                </Typography>{' '}
                {t('app.inner_head_home.quickly_and_safely')}
              </Typography>
            </RightBlock>
          </HeadFlex>
          <CardsFlex>
            {cardItemData.map((item) => (
              <CardItem key={item.id}>
                <ImageBlock>{item.icon}</ImageBlock>
                <CardItemText>
                  <Typography
                    variant={isOverLaptop ? 'average500' : 'averageMob500'}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant={isOverLaptop ? 'average400' : 'averageMob400'}
                    color={'#96A0B5'}
                  >
                    {item.subTitle}
                  </Typography>
                </CardItemText>
              </CardItem>
            ))}
          </CardsFlex>
        </>
      ) : null}
    </InnerHeader>
  );
};
