import { TypographyOptions } from '@mui/material/styles/createTypography';
import React from 'react';

export const typography: TypographyOptions = {
  fontFamily: 'Poppins, sans-serif',
  // display: 'inline-block',
  // width: '100%',
  allVariants: {
    color: '#373748',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  },
  body1: {
    fontSize: '15px',
    fontFamily: 'Poppins, sans-serif',
  },
  upperGrey: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.06em',
    textTransform: 'uppercase',
    color: '#96A0B5',
  },
  title: {
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '20px',
  },
  titleFooterMobile: {
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
  },
  sm500: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },
  titleFooterLeft: {
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '20px',
    color: '#373748',
  },
  titleFooter: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '26px',
    color: '#373748',
  },
  bottomFooter: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
    color: '#96A0B5',
  },
  bottomFooterLaptop: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#96A0B5',
  },
  big200: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
  },
  big300: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  big350: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
  },
  big400: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
  },
  big425: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '26px',
  },
  big450: {
    fontWeight: 400,
    fontSize: '28px',
    lineHeight: '38px',
  },
  big500: {
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '42px',
  },
  bigMob500: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  big600: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.01em',
  },
  big700: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '34px',
  },
  big800: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
  },
  big900: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
  },
  big1000: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '20px',
  },
  average600: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '-0.01em',
  },
  average500: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
  },
  average400: {
    fontWeight: 300,
    fontSize: '15px',
    lineHeight: '22px',
  },
  average100: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '19px',
  },
  average200: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
  },
  averageMob500: {
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
  },
  averageMob450: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
  },
  averageMob400: {
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '20px',
  },
  titleForm: {
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '20px',
  },
  titleMobForm: {
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '20px',
  },
  tf: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
  },
  tfs: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  mid500: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  min500: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
  },
  mid550: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
  },
  mid600: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  mid700: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
  },
  mid750: {
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '30px',
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    upperGrey: React.CSSProperties;
    title: React.CSSProperties;
    titleFooterMobile: React.CSSProperties;
    sm500: React.CSSProperties;
    titleFooterLeft: React.CSSProperties;
    titleFooter: React.CSSProperties;
    bottomFooter: React.CSSProperties;
    bottomFooterLaptop: React.CSSProperties;
    big200: React.CSSProperties;
    big300: React.CSSProperties;
    big350: React.CSSProperties;
    big400: React.CSSProperties;
    big425: React.CSSProperties;
    big450: React.CSSProperties;
    big500: React.CSSProperties;
    bigMob500: React.CSSProperties;
    big600: React.CSSProperties;
    big700: React.CSSProperties;
    big800: React.CSSProperties;
    big900: React.CSSProperties;
    big1000: React.CSSProperties;
    average600: React.CSSProperties;
    average500: React.CSSProperties;
    average400: React.CSSProperties;
    average100: React.CSSProperties;
    average200: React.CSSProperties;
    averageMob500: React.CSSProperties;
    averageMob450: React.CSSProperties;
    averageMob400: React.CSSProperties;
    titleForm: React.CSSProperties;
    titleMobForm: React.CSSProperties;
    tf: React.CSSProperties;
    tfs: React.CSSProperties;
    mid500: React.CSSProperties;
    mid550: React.CSSProperties;
    min500: React.CSSProperties;
    mid600: React.CSSProperties;
    mid700: React.CSSProperties;
    mid750: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    upperGrey?: React.CSSProperties;
    title?: React.CSSProperties;
    titleFooterMobile?: React.CSSProperties;
    sm500?: React.CSSProperties;
    titleFooterLeft?: React.CSSProperties;
    titleFooter?: React.CSSProperties;
    bottomFooter?: React.CSSProperties;
    bottomFooterLaptop?: React.CSSProperties;
    big200?: React.CSSProperties;
    big300?: React.CSSProperties;
    big350?: React.CSSProperties;
    big400?: React.CSSProperties;
    big425?: React.CSSProperties;
    big450?: React.CSSProperties;
    big500?: React.CSSProperties;
    bigMob500?: React.CSSProperties;
    big600?: React.CSSProperties;
    big700?: React.CSSProperties;
    big800?: React.CSSProperties;
    big900?: React.CSSProperties;
    big1000?: React.CSSProperties;
    average600?: React.CSSProperties;
    average500?: React.CSSProperties;
    average400?: React.CSSProperties;
    average100?: React.CSSProperties;
    average200?: React.CSSProperties;
    averageMob500?: React.CSSProperties;
    averageMob450?: React.CSSProperties;
    averageMob400?: React.CSSProperties;
    titleForm?: React.CSSProperties;
    titleMobForm?: React.CSSProperties;
    tf: React.CSSProperties;
    tfs: React.CSSProperties;
    mid500: React.CSSProperties;
    mid550: React.CSSProperties;
    min500: React.CSSProperties;
    mid600: React.CSSProperties;
    mid700: React.CSSProperties;
    mid750: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    upperGrey: true;
    title: true;
    titleFooterMobile: true;
    sm500: true;
    titleFooterLeft: true;
    titleFooter: true;
    bottomFooter: true;
    bottomFooterLaptop: true;
    big200: true;
    big300: true;
    big350: true;
    big400: true;
    big425: true;
    big450: true;
    big500: true;
    bigMob500: true;
    big600: true;
    big700: true;
    big800: true;
    big900: true;
    big1000: true;
    average600: true;
    average500: true;
    average400: true;
    average100: true;
    average200: true;
    averageMob500: true;
    averageMob450: true;
    averageMob400: true;
    titleForm: true;
    titleMobForm: true;
    tf: true;
    tfs: true;
    mid500: true;
    mid550: true;
    min500: true;
    mid600: true;
    mid700: true;
    mid750: true;
  }
}
