import React from 'react';
import { SxProps, Typography } from '@mui/material';
import { useLaptop } from 'hooks';

interface FormConfirmHeaderProps {
  title: string;
  subTitle: string | any;
  sxTitle?: SxProps;
  sxSubTitle?: SxProps;
}

export const FormConfirmHeader = ({
  title = '',
  subTitle = '',
  sxTitle,
  sxSubTitle,
}: FormConfirmHeaderProps) => {
  const { isOverLaptop } = useLaptop();
  return (
    <>
      <Typography
        variant={isOverLaptop ? 'big500' : 'big700'}
        textAlign={'center'}
        mb={isOverLaptop ? '10px' : '8px'}
        sx={sxTitle}
      >
        {title}
      </Typography>
      <Typography
        textAlign={'center'}
        variant={isOverLaptop ? 'average400' : 'average200'}
        color={'#96A0B5'}
        mb={isOverLaptop ? '30px' : '20px'}
        padding={'0 35px'}
        sx={sxSubTitle}
      >
        {subTitle}
      </Typography>
    </>
  );
};
