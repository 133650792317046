import { Typography, Checkbox, SxProps, Box } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { WrapperStyled } from 'components/uiKit/CheckBoxFormik/CheckBoxFormik.styled';
import * as React from 'react';
import { CheckedCheckBoxIcon, NoCheckedCheckBoxIcon } from 'components/icons';
import { ErrorMessageS } from 'components/uiKit/TextFieldFormik/TextFieldFormik.styled';

interface CheckboxFormikProps {
  label?: React.ReactNode;
  name: string;
  nativeLabel?: string;
  sx?: SxProps;
  link?: any;
}

export const CheckboxFormik = ({ label, name, sx }: CheckboxFormikProps) => {
  const [field, meta] = useField(name);
  const { handleChange, setFieldValue } = useFormikContext();
  const hasError = Boolean(meta.error && meta.touched);

  const handle = (e: any) => {
    if (e.target.tagName === 'SPAN') {
      return;
    }
    setFieldValue(name, !field.value);
  };

  return (
    <>
      <WrapperStyled sx={sx} haserror={hasError ? 'true' : 'false'}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Checkbox
            sx={{ padding: 0 }}
            id={name}
            name={name}
            checked={field.value}
            onChange={handleChange}
            checkedIcon={<CheckedCheckBoxIcon />}
            icon={<NoCheckedCheckBoxIcon />}
          />
          {label ? (
            <>
              <Typography sx={{ cursor: 'pointer' }} onClick={handle}>
                {label}
              </Typography>
            </>
          ) : null}
        </Box>
        {hasError && <ErrorMessageS>{meta.error || ''}</ErrorMessageS>}
      </WrapperStyled>
    </>
  );
};
