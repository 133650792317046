import { Box, styled } from '@mui/material';

export const DividerBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '14px',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '12px',
  },
}));
