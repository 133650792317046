import Box from '@mui/material/Box';
import { DoneSIcon } from 'components/icons';
import { Divider, Typography } from '@mui/material';
import React from 'react';
import { ConfirmBlock } from './ConfirmationsBlock.styled';

interface ConfirmationsBlockProps {
  isOverLaptop: boolean;
  children: React.ReactNode;
  length: number;
  index: number;
}

export const ConfirmationsBlock = ({
  isOverLaptop,
  children,
  length,
  index,
}: ConfirmationsBlockProps) => {
  return (
    <Box mb={'12px'}>
      <ConfirmBlock>
        <DoneSIcon size={!isOverLaptop ? '16px' : '20px'} />
        <Typography variant={isOverLaptop ? 'averageMob450' : 'sm500'}>
          {children}
        </Typography>
      </ConfirmBlock>
      {index !== length - 1 && <Divider />}
    </Box>
  );
};
