import { Typography } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { LogoutIcon } from '../icons';
import { Flex, TypographyLogo } from './ProfileContentHeader.styled';

interface ProfileContentHeaderProps {
  isOverLaptop: boolean;
  onClick: () => void;
  title: string | DefaultTFuncReturn;
  titleBtn: string | DefaultTFuncReturn;
}

export const ProfileContentHeader = ({
  isOverLaptop,
  onClick,
  title = '',
  titleBtn = '',
}: ProfileContentHeaderProps) => {
  return (
    <Flex mb={isOverLaptop ? '24px' : '12px'}>
      <Typography variant={isOverLaptop ? 'big500' : 'big700'}>
        {title}
      </Typography>
      {!isOverLaptop ? (
        <TypographyLogo onClick={onClick}>
          <LogoutIcon size={'20'} /> {titleBtn}
        </TypographyLogo>
      ) : null}
    </Flex>
  );
};
