import React from 'react';

export const BurgerIcon = () => {
  return (
    <svg
      width='22'
      height='18'
      viewBox='0 0 22 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 1H1'
        stroke='#96A0B5'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M21 9H5'
        stroke='#96A0B5'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M21 17H1'
        stroke='#96A0B5'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
};
