import { GET_ALL_PRODUCT_FROM_CART } from 'graphql/queries';
import { getErrorName } from 'utils';
import { ErrorMessage } from 'types/baseTypes';
import { SkuEntity, useAddProductToCartMutation } from 'graphql/generated';
import { useRouter } from 'next/router';
import { useAlertContext, useAuthContext } from 'context';
import { useTranslation } from 'next-i18next';
import { useCartStore } from 'Store';
import { useState } from 'react';

export const useAddToCard = (product: any) => {
  const router = useRouter();
  const [skuId, setSkuId] = useState('');
  const { onOpenAlert } = useAlertContext();
  const { t } = useTranslation();
  const { isAuth } = useAuthContext();
  const [addProductToCartMutation] = useAddProductToCartMutation();
  const [addToCart] = useCartStore((state) => [state?.addToCart]);

  const doAddToCard = async () => {
    const id = router.query.id;
    if (!skuId || !id) {
      return onOpenAlert({
        subTitle: t`app.please_select_a_product`,
        error: true,
      });
    }
    // * DO WITH AUTHENTICATION USER
    if (isAuth) {
      await addProductToCartMutation({
        variables: {
          input: {
            quantity: 1,
            skuId: +skuId,
          },
        },
        refetchQueries: [GET_ALL_PRODUCT_FROM_CART],
        onError: (error) => {
          onOpenAlert({
            subTitle: getErrorName(
              (error?.graphQLErrors[0]?.extensions?.exception as ErrorMessage)
                ?.name,
            ),
            error: true,
          });
        },
        onCompleted: () =>
          onOpenAlert({ subTitle: t`app.cart.product_added_to_cart` }),
      });
    }

    addToCart(
      id as string,
      skuId,
      product?.skus as SkuEntity[],
      product?.baseCurrency.name as string,
    );
    if (!isAuth) {
      onOpenAlert({ subTitle: t`app.cart.product_added_to_cart` });
    }
  };
  return { doAddToCard, setSkuId, skuId };
};
