import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const DoneSIcon = ({ size = '20' }: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_200_19622)'>
        <path
          opacity='0.5'
          d='M18.3332 9.23333V10C18.3321 11.797 17.7503 13.5456 16.6743 14.9849C15.5983 16.4241 14.0859 17.4771 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8122C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0234 1.56633 11.2401 1.68457 9.44693C1.80281 7.6538 2.49751 5.94694 3.66507 4.58089C4.83263 3.21485 6.41049 2.26282 8.16333 1.86679C9.91617 1.47076 11.7501 1.65195 13.3915 2.38333'
          stroke='#30D05A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.3333 3.33333L10 11.675L7.5 9.175'
          stroke='#30D05A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_200_19622'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
