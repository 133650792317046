import React from 'react';
import { DoneIcon } from '../../icons';
import {
  AuthCodeWrapper,
  ButtonFW,
  FormWrapper,
} from 'components/Form/SignInForm.styled';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';
import { useModalContext } from 'context';
import { FormConfirmHeader } from '..';

export const DonePhoneForm = () => {
  const theme = useTheme();
  const isOverLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

  const { onCloseModal } = useModalContext();

  const { t } = useTranslation();

  const handleOk = () => {
    onCloseModal();
  };

  return (
    <FormWrapper>
      <Box textAlign={'center'} mb={'24px'}>
        <DoneIcon />
      </Box>
      <FormConfirmHeader
        sxSubTitle={{
          padding: 0,
          marginBottom: isOverLaptop ? '30px' : '24px',
        }}
        title={t('app.profilePage.thanks')}
        subTitle={t('app.profilePage.number_success')}
      />
      <AuthCodeWrapper>
        <ButtonFW
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            minWidth: '141px',
          }}
          onClick={handleOk}
          size={!isOverLaptop ? 'medium' : 'large'}
        >
          {t('app.ok')}
        </ButtonFW>
      </AuthCodeWrapper>
    </FormWrapper>
  );
};
