import Head from 'next/head';
import React from 'react';
import Script from 'next/script';
import { DefaultTFuncReturn } from 'i18next';

interface MetaLayoutProps {
  children: React.ReactNode;
  title?: string | DefaultTFuncReturn;
  description?: string;
  keywords?: string;
}

export const MetaLayout = ({
  children,
  title = '',
  description = '',
  keywords = '',
}: MetaLayoutProps) => {
  return (
    <>
      <>
        <Head>
          <title>{title}</title>
          <meta name='description' content={description} />
          <meta name='keywords' content={keywords} />
          <meta charSet='utf-8' />
        </Head>
      </>
      <>{children}</>
      <Script
        id='my-script'
        dangerouslySetInnerHTML={{
          __html: `history.scrollRestoration = "manual"`,
        }}
      />
    </>
  );
};
