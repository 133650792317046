import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { ValueBlock, Wrapper } from './TotalValueToCart.styled';
import { useCartStore } from 'Store';
import { getResultTotalCart, transformDataToTotal } from 'utils';
import { useIntlContext } from 'context/IntlContext';
import { CartProductSchema } from 'graphql/generated';
import { CurrencyType } from 'types/baseTypes';

interface TotalValueToCartProps {
  currency: string;
  exchangeRatesData: any;
  isAuth: boolean;
  productData?: CartProductSchema[];
  cb?: any;
}

export const TotalValueToCart = ({
  currency,
  exchangeRatesData,
  isAuth,
  productData,
  cb,
}: TotalValueToCartProps) => {
  const { t } = useTranslation();
  const [productDataStore] = useCartStore((state) => [state.productData]);
  const { currentCurrency } = useIntlContext();

  const total = getResultTotalCart(
    isAuth
      ? transformDataToTotal(productData as CartProductSchema[])
      : productDataStore,
    currentCurrency as CurrencyType,
    exchangeRatesData,
    isAuth,
  );

  useEffect(() => {
    cb(total);
  }, [total]);

  return (
    <Wrapper>
      <Typography variant={'averageMob450'} color={'#96A0B5'}>
        {t('app.cart.total')}
      </Typography>
      <ValueBlock>
        {total}{' '}
        <Typography component={'span'} variant={'mid750'}>
          {currency}
        </Typography>
      </ValueBlock>
    </Wrapper>
  );
};
