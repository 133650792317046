import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const MyOrdersIcon = ({
  size = '20',
  color = '#373748',
}: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_132_18226)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.0422 18.3757C14.5889 18.8289 13.9743 19.0835 13.3333 19.0835L3.33331 19.0835C2.69237 19.0835 2.07768 18.8289 1.62447 18.3757C1.17126 17.9225 0.916647 17.3078 0.916647 16.6668L0.916648 3.33349C0.916648 2.69255 1.17126 2.07787 1.62447 1.62465C2.07769 1.17144 2.69237 0.916829 3.33331 0.916829L16.6666 0.91683C17.3076 0.91683 17.9223 1.17144 18.3755 1.62465C18.8287 2.07787 19.0833 2.69256 19.0833 3.3335L19.0833 10.8335C19.0833 12.1644 17.9975 13.2502 16.6666 13.2502L15.75 13.2502L15.75 16.6668C15.75 17.3078 15.4954 17.9225 15.0422 18.3757ZM15.75 11.7502L16.6666 11.7502C17.1691 11.7502 17.5833 11.3359 17.5833 10.8335L17.5833 3.3335C17.5833 3.09038 17.4867 2.85722 17.3148 2.68531C17.1429 2.5134 16.9098 2.41683 16.6666 2.41683C16.4235 2.41683 16.1904 2.5134 16.0185 2.68531C15.8466 2.85722 15.75 3.09038 15.75 3.3335L15.75 11.7502ZM14.4306 2.41683C14.3124 2.70499 14.25 3.01602 14.25 3.3335L14.25 16.6668C14.25 16.9099 14.1534 17.1431 13.9815 17.315C13.8096 17.4869 13.5764 17.5835 13.3333 17.5835L3.33331 17.5835C3.0902 17.5835 2.85704 17.4869 2.68513 17.315C2.51322 17.1431 2.41665 16.9099 2.41665 16.6668L2.41665 3.33349C2.41665 3.09038 2.51322 2.85722 2.68513 2.68531C2.85704 2.5134 3.0902 2.41683 3.33331 2.41683L14.4306 2.41683Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.6667 6.58349L10 6.5835C9.58581 6.5835 9.25002 6.24771 9.25002 5.8335C9.25002 5.41928 9.58581 5.0835 10 5.0835L11.6667 5.08349C12.0809 5.08349 12.4167 5.41928 12.4167 5.83349C12.4167 6.24771 12.0809 6.58349 11.6667 6.58349Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.6667 10.75L10 10.75C9.58581 10.75 9.25002 10.4142 9.25002 10C9.25002 9.58579 9.58581 9.25 10 9.25L11.6667 9.25C12.0809 9.25 12.4167 9.58578 12.4167 10C12.4167 10.4142 12.0809 10.75 11.6667 10.75Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.6667 14.9165L10 14.9165C9.58581 14.9165 9.25002 14.5807 9.25002 14.1665C9.25002 13.7523 9.58581 13.4165 10 13.4165L11.6667 13.4165C12.0809 13.4165 12.4167 13.7523 12.4167 14.1665C12.4167 14.5807 12.0809 14.9165 11.6667 14.9165Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.03033 4.46967C8.32322 4.76256 8.32322 5.23744 8.03033 5.53033L6.36366 7.197C6.07077 7.48989 5.5959 7.48989 5.303 7.197L4.46967 6.36366C4.17678 6.07077 4.17678 5.5959 4.46967 5.303C4.76256 5.01011 5.23744 5.01011 5.53033 5.303L5.83333 5.60601L6.96967 4.46967C7.26256 4.17678 7.73744 4.17678 8.03033 4.46967Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.03033 8.63617C8.32322 8.92907 8.32322 9.40394 8.03033 9.69683L6.36366 11.3635C6.07077 11.6564 5.5959 11.6564 5.303 11.3635L4.46967 10.5302C4.17678 10.2373 4.17678 9.7624 4.46967 9.46951C4.76256 9.17661 5.23744 9.17661 5.53033 9.46951L5.83333 9.77251L6.96967 8.63617C7.26256 8.34328 7.73744 8.34328 8.03033 8.63617Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.03033 12.8032C8.32322 13.0961 8.32322 13.5709 8.03033 13.8638L6.36366 15.5305C6.07077 15.8234 5.5959 15.8234 5.303 15.5305L4.46967 14.6972C4.17678 14.4043 4.17678 13.9294 4.46967 13.6365C4.76256 13.3436 5.23744 13.3436 5.53033 13.6365L5.83333 13.9395L6.96967 12.8032C7.26256 12.5103 7.73744 12.5103 8.03033 12.8032Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_132_18226'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(20 20) rotate(-180)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
