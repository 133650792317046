import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const GoogleIcon = ({ size = '24' }: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_154_37538)'>
        <path
          d='M4.43242 12.0862L3.73625 14.6852L1.19176 14.739C0.431328 13.3286 0 11.7148 0 10C0 8.34176 0.403281 6.77801 1.11812 5.40109H1.11867L3.38398 5.8164L4.37633 8.06812C4.16863 8.67363 4.05543 9.32363 4.05543 10C4.05551 10.7341 4.18848 11.4374 4.43242 12.0862Z'
          fill='#FBBB00'
        />
        <path
          d='M19.8253 8.13187C19.9401 8.7368 20 9.36152 20 10C20 10.7159 19.9247 11.4143 19.7813 12.0879C19.2945 14.3802 18.0225 16.3819 16.2605 17.7984L16.2599 17.7978L13.4066 17.6522L13.0028 15.1313C14.172 14.4456 15.0858 13.3725 15.5671 12.0879H10.2198V8.13187H15.6451H19.8253Z'
          fill='#518EF8'
        />
        <path
          d='M16.2599 17.7978L16.2604 17.7984C14.5467 19.1758 12.3698 20 10 20C6.19177 20 2.8808 17.8714 1.19177 14.739L4.43244 12.0863C5.27693 14.3401 7.45111 15.9445 10 15.9445C11.0956 15.9445 12.122 15.6484 13.0027 15.1313L16.2599 17.7978Z'
          fill='#28B446'
        />
        <path
          d='M16.383 2.30219L13.1434 4.95437C12.2319 4.38461 11.1544 4.05547 10 4.05547C7.39341 4.05547 5.17856 5.73348 4.37638 8.06812L1.11868 5.40109H1.11813C2.78243 2.1923 6.13517 0 10 0C12.4264 0 14.6511 0.864297 16.383 2.30219Z'
          fill='#F14336'
        />
      </g>
      <defs>
        <clipPath id='clip0_154_37538'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
