import React from 'react';
import {
  AuthFormBlock,
  AuthImageBlock,
  AuthLayoutWrapper,
} from 'components/Layout/Layout.styled';
import { useLaptop } from 'hooks';
import { Header } from 'components';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const { isOverLaptop } = useLaptop();
  return (
    <AuthLayoutWrapper>
      {!isOverLaptop ? <Header /> : null}
      <AuthFormBlock>{children}</AuthFormBlock>
      <AuthImageBlock />
    </AuthLayoutWrapper>
  );
};
