import Head from 'next/head';
import React from 'react';
import Script from 'next/script';
import { DefaultTFuncReturn } from 'i18next';
import { useRouter } from 'next/router';
import { getCurrentUrl, getMetaLinks } from 'utils';

interface MetaLayoutProps {
  children: React.ReactNode;
  title?: string | DefaultTFuncReturn;
  description?: string;
  keywords?: string;
  [x: string]: any;
}

export const Meta = ({
  children,
  title = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  description = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  keywords = '',
  ...props
}: MetaLayoutProps) => {
  const { locales, asPath, locale } = useRouter();

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet='utf-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1'
        />
        <meta name='theme-color' content='#4663f4' />
        <meta property='og:title' content='Web Market' key='Web Market' />
        {getMetaLinks(locales, props.domain, asPath)}
        <link
          rel='canonical'
          href={getCurrentUrl(locale || '', props.domain, asPath)}
        />
      </Head>

      <>{children}</>

      <Script
        id='my-script'
        dangerouslySetInnerHTML={{
          __html: `history.scrollRestoration = "manual"`,
        }}
      />
    </>
  );
};
