import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

export const useCurrentLanguage = () => {
  const { locale } = useRouter();
  const { i18n } = useTranslation();

  const isENLang = locale === 'en';

  return { currentLanguage: i18n.language, isENLang };
};
