import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Link from 'next/link';

export const Wrapper = styled(Box)(({ theme }) => ({
  paddingBottom: '150px',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '87vh',
  [theme.breakpoints.down('laptop')]: {
    paddingBottom: '80px',
  },
}));

export const FlexRow = styled(Box)(({ theme, gap }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '30px',
  marginBottom: '24px',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    gap: gap || '12px',
    marginTop: '24px',
    marginBottom: '20px',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  [theme.breakpoints.down('laptop')]: {
    gap: '8px',
  },
}));

export const AlertLink = styled(Link)<any>(() => ({
  color: '#F2405D',
  '&:active': {
    color: '#F2405D',
  },
  '&:visited': {
    color: '#F2405D !important',
  },
  fontWeight: 400,
}));
