import { KycLevelsSchema } from 'graphql/generated';

export const getAllowableLimitOnPurchase = (
  kycInfo?: KycLevelsSchema,
  kycLvl?: number,
) => {
  if (!kycInfo || (typeof kycLvl !== 'number' && !kycLvl)) return Infinity;
  switch (kycLvl) {
    case 0:
      return kycInfo.limitLevel1;
    case 1:
      return kycInfo.limitLevel2;
    case 2:
      return kycInfo.limitLevel3;
    default:
      return Infinity;
  }
};
