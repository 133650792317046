import React from 'react';
import { Box, Typography } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';

interface FormHeaderProps {
  isOverLaptop?: boolean;
  title?: string | DefaultTFuncReturn;
  subTitle?: string | DefaultTFuncReturn;
}

export const FormHeader = ({
  isOverLaptop = false,
  title = '',
  subTitle = '',
}: FormHeaderProps) => {
  return (
    <>
      <Box mb={isOverLaptop ? '10px' : '8px'}>
        <Typography variant={isOverLaptop ? 'big500' : 'big700'}>
          {title}
        </Typography>
      </Box>
      <Box mb={isOverLaptop ? '24px' : '20px'}>
        <Typography
          color={'#96A0B5'}
          variant={isOverLaptop ? 'average400' : 'average200'}
        >
          {subTitle}
        </Typography>
      </Box>
    </>
  );
};
