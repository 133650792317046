import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const DoneIcon = ({ size = '86' }: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 86 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_126_13085)'>
        <path
          opacity='0.5'
          d='M42.9997 84.6666C66.0122 84.6666 84.6663 66.0124 84.6663 42.9999C84.6663 19.9874 66.0122 1.33325 42.9997 1.33325C19.9872 1.33325 1.33301 19.9874 1.33301 42.9999C1.33301 66.0124 19.9872 84.6666 42.9997 84.6666Z'
          stroke='#30D05A'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.5 43.0001L38.8333 51.3334L55.5 34.6667'
          stroke='#30D05A'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_126_13085'>
          <rect width='86' height='86' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
