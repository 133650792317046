import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  border: '3px solid #ffffff',
  display: 'flex',
  position: 'absolute',
  left: '12px',
  top: '-12px',
  borderRadius: '4px',
}));

export const StyledBadge = styled(Box)(() => ({
  background: '#4663F4',
  color: '#ffffff',
  borderRadius: '4px',
  height: '18px',
  minWidth: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '13px',
}));
