import React from 'react';
import { Wrapper } from 'components/VerifyBadge/VerifyBadge.styled';
import { useTranslation } from 'next-i18next';

export const VerifyBadge = ({ verify = true }) => {
  const { t } = useTranslation();

  return (
    <Wrapper verify={verify ? 'true' : 'false'}>
      {verify ? t('app.verified') : t('app.not_verified')}
    </Wrapper>
  );
};
