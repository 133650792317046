import React from 'react';
import { useLaptop, usePhoneVerify } from 'hooks';

import { AlertCustom } from 'components/AlertCustom/AlertCustom';
import { AlertLink } from 'components/Cart/DefaultCart/DefaultCart.styled';
import { PATHS } from 'utils';
import { useTranslation } from 'next-i18next';

interface AlertDefaultCartProps {
  isNeedAlert?: boolean;
  phoneNumber?: string | null;
}

export const AlertDefaultCart = ({
  isNeedAlert,
  phoneNumber,
}: AlertDefaultCartProps) => {
  const { isOverLaptop } = useLaptop();
  const { t } = useTranslation();
  const { phoneVerify } = usePhoneVerify();

  return isNeedAlert ? (
    <AlertCustom type={'error'} mb={isOverLaptop ? '24px' : '16px'}>
      <span>
        {t`app.cart.limits_exceeded`}. {t`app.cart.please`}&nbsp;
        {t`verify`}
      </span>
    </AlertCustom>
  ) : null;
};
