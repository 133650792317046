import {
  Box,
  styled,
  SwipeableDrawer as SwipeableDrawerMUI,
} from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // padding: '30px 16px',
  background: '#FFFFFF',
  height: '80px',
  // maxWidth: '1232px',
  // alignSelf: 'center',
  // width: '100%',
  borderBottom: '1px solid #ECEFF4',
  [theme.breakpoints.down('laptop')]: {
    // padding: '14px 20px',
    height: '53px',
  },
}));

export const InnerWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1240px',
  padding: '0 20px',
}));

export const VerticalDivider = styled(Box)(() => ({
  display: 'flex',
  width: '1px',
  height: '30px',
  background: '#ECEFF4',
  marginInlineEnd: '30px',
}));

export const CartBtnWrap = styled(Box)(({ theme }) => ({
  marginInlineEnd: '25px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    marginInlineEnd: '18px',
  },
}));

export const SwipeableDrawer = styled(SwipeableDrawerMUI)(({ theme }) => ({
  [theme.breakpoints.up('laptop')]: {
    // display: 'none',
  },
}));
