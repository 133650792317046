import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const EntertainmentIcon = ({ color = '#96A0B5' }: IconsTypes) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_452_3147)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.5 1.54785C6.00358 1.54785 1.54785 6.00358 1.54785 11.5C1.54785 16.9964 6.00358 21.4521 11.5 21.4521C12.5911 21.4521 13.6402 21.2768 14.6213 20.9531C13.9382 20.0682 13.531 18.9579 13.531 17.7529C13.531 14.8584 15.8775 12.512 18.7719 12.512C19.6936 12.512 20.5603 12.7503 21.313 13.1683C21.4045 12.6262 21.4521 12.0689 21.4521 11.5C21.4521 6.00358 16.9964 1.54785 11.5 1.54785ZM22.6304 14.2061C22.8408 13.3378 22.9521 12.4314 22.9521 11.5C22.9521 5.17515 17.8248 0.0478516 11.5 0.0478516C5.17515 0.0478516 0.0478516 5.17515 0.0478516 11.5C0.0478516 17.8248 5.17515 22.9521 11.5 22.9521C13.036 22.9521 14.503 22.6494 15.8432 22.0997C16.6794 22.664 17.6879 22.9939 18.7719 22.9939C21.6664 22.9939 24.0129 20.6474 24.0129 17.7529C24.0129 16.385 23.4881 15.1388 22.6304 14.2061ZM18.7719 14.012C16.7059 14.012 15.031 15.6868 15.031 17.7529C15.031 18.9249 15.5691 19.9707 16.4146 20.6578C17.0582 21.1809 17.8775 21.4939 18.7719 21.4939C20.838 21.4939 22.5129 19.819 22.5129 17.7529C22.5129 16.6569 22.0426 15.6719 21.2904 14.9866C20.6251 14.3805 19.7424 14.012 18.7719 14.012Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.8028 3.01898C12.038 2.88587 12.3266 2.88958 12.5583 3.0287L16.295 5.27244C17.7919 6.17127 18.4704 7.98429 17.9314 9.64505L17.789 10.0837C17.6612 10.4777 17.2381 10.6934 16.8441 10.5655C16.4502 10.4376 16.2344 10.0146 16.3623 9.62061L16.5047 9.18199C16.8281 8.18553 16.421 7.09772 15.5228 6.55842L12.9222 4.99685V6.8573L14.8836 8.1421C15.2301 8.36907 15.327 8.83396 15.1 9.18045C14.873 9.52694 14.4081 9.62384 14.0616 9.39687L12.9222 8.65047V15.1812C12.9222 17.2396 11.2535 18.9082 9.19513 18.9082C7.13674 18.9082 5.46808 17.2396 5.46808 15.1812C5.46808 13.1228 7.13674 11.4541 9.19513 11.4541C10.0301 11.4541 10.8009 11.7287 11.4222 12.1924V3.67169C11.4222 3.40144 11.5676 3.15209 11.8028 3.01898ZM11.4222 15.1812C11.4222 13.9512 10.4251 12.9541 9.19513 12.9541C7.96516 12.9541 6.96808 13.9512 6.96808 15.1812C6.96808 16.4111 7.96516 17.4082 9.19513 17.4082C10.4251 17.4082 11.4222 16.4111 11.4222 15.1812ZM16.9444 15.2513C17.1638 15.1143 17.4384 15.1 17.6708 15.2135L21.4868 17.0768C21.7439 17.2023 21.9072 17.4633 21.9077 17.7494C21.9082 18.0356 21.7458 18.2971 21.4891 18.4235L17.6732 20.3035C17.4407 20.418 17.1656 20.4044 16.9455 20.2675C16.7255 20.1306 16.5917 19.8899 16.5917 19.6307V15.8874C16.5917 15.6287 16.725 15.3883 16.9444 15.2513ZM18.0917 17.0883V18.4251L19.4545 17.7537L18.0917 17.0883Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_452_3147'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
