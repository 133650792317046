import { Box, Button, styled, Typography } from '@mui/material';

export const ForgotPasswordTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  insetInlineEnd: '0',
  top: '0',
  cursor: 'pointer',
  color: '#4663F4',
  fontWeight: '300',
  fontSize: '15px',
  lineHeight: '22px',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

export const ButtonFW = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('laptop')]: {},
}));

export const FormWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('laptop')]: {},
  };
});

export const GoogleStyled = styled(Button)(({ theme }) => {
  return {
    width: '100%',
    borderColor: 'rgba(150, 160, 181, 0.4)',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    color: '#373748',
    svg: {
      marginInlineEnd: '12px',
    },
    ':hover': {
      borderColor: 'rgba(150, 160, 181, 0.4)',
    },
    marginBottom: '20px',
    [theme.breakpoints.down('laptop')]: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      marginBottom: '16px',
      height: '42px',
      svg: {
        width: '20px',
        height: '20px',
      },
    },
  };
});

export const LinkTypography = styled(Typography)(() => ({
  color: '#4663F4',
  cursor: 'pointer',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  a: {
    color: '#4663F4',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    textDecoration: 'none',
  },
}));

const isBrowser = typeof window !== 'undefined';

export const AuthCodeWrapper = styled(Box)(({ theme }) => {
  const isMacLike =
    isBrowser && /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
  const isIOS = isBrowser && /(iPhone|iPod|iPad)/i.test(navigator.userAgent);

  return {
    width: '225px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    gap: '30px',
    '.containerClass': {
      display: 'flex',
      justifyContent: 'center',
      gap: '15px',
    },
    input: {
      width: '45px',
      height: '48px',
      borderRadius: '6px',
      border: '1px solid rgba(150, 160, 181, 0.4)',

      textAlign: 'center',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '21px',
      textTransform: 'uppercase',
      '&:focus-visible': {
        border: '1px solid #4663F4',
        outlineColor: '#4663F4',
        outlineOffset: isMacLike || isIOS ? '-3px' : '0px',
        outlineStyle: 'auto',
        outlineWidth: '5px',
      },
    },
    [theme.breakpoints.down('laptop')]: {
      gap: '24px',
      width: 'auto',
      input: {
        width: '46px',
        height: '42px',
      },
    },
  };
});

export const NavLink = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: '#4663F4',
  fontWeight: '300',
  fontSize: '15px',
  lineHeight: '22px',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));
