import { useRequestRefundMutation } from 'graphql/generated';
import { useAlertContext } from 'context';
import { useTranslation } from 'next-i18next';

export const useRefund = () => {
  const [requestRefund] = useRequestRefundMutation();
  const { onOpenAlert } = useAlertContext();
  const { t } = useTranslation();

  const doRefund = (id: string, status: string) => {
    if (status === 'REFUND_REQUESTED') {
      return onOpenAlert({
        subTitle: t`app.your_request_has_already_been_sent_please_wait`,
      });
    }
    requestRefund({
      variables: {
        requestRefundId: id,
      },
      onCompleted: () => {
        onOpenAlert({ subTitle: t`app.a_refund_request_has_been_sent` });
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.info(error.message);
      },
    });
  };
  return { doRefund };
};
