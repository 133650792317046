import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const MinusIcon = ({ size = '14' }: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.91699 7H11.0837'
        stroke='#96A0B5'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
