import React, { useEffect, useRef, useState } from 'react';
import { useCartContext, useModalContext } from 'context';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  MODAL_NAME,
  AUTH_PATHS,
  getErrorName,
  getConfirmPasswordInfoText,
} from 'utils';
import {
  AuthCodeWrapper,
  ButtonFW,
  FormWrapper,
  NavLink,
} from 'components/Form/SignInForm.styled';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { ArrowIcon } from '../../icons';
import { CircularProgressCustom, AlertCustom } from 'components';
import { useLaptop, useCount } from 'hooks';
import { FormConfirmHeader } from '..';
import {
  useConfirmResetPasswordMutation,
  useResetPasswordMutation,
} from 'graphql/generated';
import { useAuthVariablesStore } from 'Store';

export const ConfirmPasswordForm = () => {
  const [error, setError] = useState(false);
  const [code, setCode] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [showTimer, setShowTimer] = useState(0);
  const ref = useRef<AuthCodeRef>(null);

  const [email, setCodeLocalStorage] = useAuthVariablesStore((state) => [
    state.email,
    state.setCode,
  ]);

  const [resetPassword] = useResetPasswordMutation();

  const { isOverLaptop } = useLaptop();
  const { onOpenModal } = useModalContext();
  const isDisabledBtn = code.length < 4;

  const { isProductInTheCart } = useCartContext();
  const router = useRouter();
  const { t } = useTranslation();

  const { countDown, setStart, start, setCountDown } = useCount();

  const [confirmResetPasswordMutation, { loading }] =
    useConfirmResetPasswordMutation();

  const handleOnChange = (res: string) => {
    setCode(res);
  };

  useEffect(() => {
    if (!email) {
      router.push(AUTH_PATHS.forgotPassword);
    }
  }, [email, router]);

  const handleSubmit = async () => {
    if (loading) return;
    if (showInfo) {
      setError(false);
    }
    setShowInfo(false);
    await confirmResetPasswordMutation({
      variables: {
        input: {
          email: email,
          code: code,
        },
      },
      onCompleted: (data) => {
        setCodeLocalStorage(code);
        if (!data.confirmResetPassword) {
          setError(true);
          setShowTimer(0);
          ref.current?.clear();
        } else {
          // * if mobile and cart is empty
          if (!isOverLaptop || !isProductInTheCart) {
            router.push(AUTH_PATHS.newPassword);
          } else {
            onOpenModal(MODAL_NAME.newPassword);
          }
        }
      },
      onError: () => ref.current?.clear(),
    });
  };

  const handleResendPassword = async () => {
    setShowTimer((prevState) => prevState + 1);
    if (start && countDown <= 30 && countDown !== 0) return;
    setStart(true);
    setCountDown(30);
    resetPassword({
      variables: {
        input: {
          email: email,
        },
      },
      onCompleted: () => {
        setShowInfo(true);
        ref.current?.clear();
      },
    });
  };

  return (
    <FormWrapper>
      <FormConfirmHeader
        title={t('app.auth.forgot_password')}
        subTitle={t('app.auth.please_enter_email_address')}
      />
      <AuthCodeWrapper mb={isOverLaptop ? '16px' : '20px'}>
        <AuthCode
          onChange={handleOnChange}
          length={4}
          containerClassName={'containerClass'}
          inputClassName={'inputClass'}
          ref={ref}
        />
        <ButtonFW
          disabled={isDisabledBtn || loading}
          onClick={handleSubmit}
          endIcon={
            loading ? (
              <CircularProgressCustom loading={true} color={'#fff'} />
            ) : (
              <ArrowIcon />
            )
          }
          size={!isOverLaptop ? 'medium' : 'large'}
        >
          {t('app.profilePage.confirm')}
        </ButtonFW>
      </AuthCodeWrapper>
      <NavLink align={'center'} onClick={handleResendPassword}>
        {t`app.re_send_the_code`}
      </NavLink>
      {error || showInfo ? (
        <AlertCustom
          type={showInfo ? 'information' : 'error'}
          mt={isOverLaptop ? '24px' : '16px'}
        >
          {showInfo
            ? getConfirmPasswordInfoText(showInfo, countDown, showTimer)
            : getErrorName('notConfirmEmail')}
        </AlertCustom>
      ) : null}
    </FormWrapper>
  );
};
