import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import {
  AlertCustom,
  BottomCartNavigate,
  CartProductRow,
  EmptyCart,
  RowForCartTableHead,
  TotalValueToCart,
} from 'components';
import {
  useLaptop,
  useNeedAlertInDefaultCart,
  useCheckoutForDefaultCart,
} from 'hooks';
import { useAuthContext, useCartContext } from 'context';
import {
  CartProductSchema,
  GetAllCurrencyQuery,
  GetExchangeRatesQuery,
  useGetCurrentUserQuery,
  useGetSkusByIdsLazyQuery,
} from 'graphql/generated';
import { LoaderWrapper } from 'components/LoaderWrapper/LoaderWrapper';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useIntlContext } from 'context/IntlContext';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { CurrencyType } from 'types/baseTypes';
import { newPriceWithExchangeRates } from 'utils/newPriceWithExchangeRates';
import { getHasNotAvailable, getIdCurrency } from 'utils';
import { Content, FlexRow, Wrapper } from './DefaultCart.styled';

import { AlertDefaultCart } from 'components/Cart/AlertDefaulCart/AlertDefaultCart';

interface CartPageProps {
  exchangeRates: ApolloQueryResult<GetExchangeRatesQuery>;
  fiatAllCurrency: ApolloQueryResult<GetAllCurrencyQuery>;
}

export const DefaultCart = ({
  exchangeRates,
  fiatAllCurrency,
}: CartPageProps) => {
  const { isAuth } = useAuthContext();
  const { isOverLaptop } = useLaptop();
  const { isENLang } = useCurrentLanguage();
  const { t } = useTranslation();
  const { currentCurrency } = useIntlContext();
  const { productData, loadingAllProduct } = useCartContext();

  const currencyId = getIdCurrency(
    fiatAllCurrency.data.getAllCurrency,
    currentCurrency as CurrencyType,
  );

  const { data: currentUserData } = useGetCurrentUserQuery({
    skip: !isAuth,
  });

  const phoneNumber = currentUserData?.getCurrentUser.phoneNumber;
  const skusIds = !isAuth ? productData.map((el) => el.skuId) : [];

  // ! THIS DATA FOR NOT AUTH USER
  const [
    getSkusByIds,
    {
      data: dataCartForNotIsAuthUser,
      loading: loadingDataCartForNotIsAuthUser,
    },
  ] = useGetSkusByIdsLazyQuery({
    variables: {
      ids: skusIds,
    },
  });
  // ! THIS DATA FOR NOT AUTH USER

  useEffect(() => {
    if (!isAuth || !skusIds.length) {
      getSkusByIds();
    }
  }, [isAuth, skusIds.length]);

  const exchangeRatesData = exchangeRates.data.getExchangeRates;

  const cartData = isAuth
    ? productData
    : dataCartForNotIsAuthUser?.getSkusByIds;

  // ! is not available this product
  const hasNotAvailable = isAuth
    ? getHasNotAvailable(cartData as CartProductSchema[])
    : false;

  // ! =========== CHECKOUT ===========
  const {
    doCheckout,
    payOrderLQLoading,
    createFiatOrderLoading,
    dataIds,
    errorPreOrder,
  } = useCheckoutForDefaultCart({
    productData: productData,
    fiatAllCurrency: fiatAllCurrency,
    currentCurrency: currentCurrency as CurrencyType,
  });
  // ! =========== CHECKOUT ===========

  // ! =========== FOR PHONE VERIFY ===========
  const { setTotal, isNeedVerify } = useNeedAlertInDefaultCart({
    cartDataLength: cartData?.length,
    currentUserData: currentUserData?.getCurrentUser,
    currencyId,
  });
  // ! =========== FOR PHONE VERIFY ===========

  const showBottomCartNavigate = () => {
    if (!isOverLaptop) {
      return true;
    } else {
      return !!cartData?.length;
    }
  };

  return (
    <Wrapper>
      <FlexRow>
        <Typography variant={isOverLaptop ? 'big500' : 'big700'}>
          {t('app.cart.cart')}
        </Typography>
      </FlexRow>
      {/*! ALERT CODE */}
      <AlertDefaultCart
        isNeedAlert={isNeedVerify || errorPreOrder?.error}
        phoneNumber={phoneNumber}
      />

      {hasNotAvailable || dataIds.length ? (
        <AlertCustom type={'error'} mb={isOverLaptop ? '24px' : '16px'}>
          <span>{t`app.cart.unfortunately`}</span>
        </AlertCustom>
      ) : null}

      <LoaderWrapper
        loading={loadingAllProduct || loadingDataCartForNotIsAuthUser}
      >
        {cartData?.length ? (
          <Content>
            <RowForCartTableHead />
            {cartData.map((cartData, index) => {
              const disabled =
                isAuth &&
                cartData.skuId ===
                  dataIds.find((i) => i.id === cartData.skuId)?.id;
              return (
                <CartProductRow
                  key={index}
                  {...(isAuth
                    ? {
                        name: cartData?.sku?.[isENLang ? 'nameEN' : 'nameAR'],
                        description: cartData?.product?.shortDescriptionEN,
                        id: cartData.skuId,
                        isOverLaptop: isOverLaptop,
                        pricePerUnit: newPriceWithExchangeRates(
                          cartData?.sku?.value,
                          cartData?.product?.baseCurrency?.name as CurrencyType,
                          currentCurrency as CurrencyType,
                          exchangeRatesData,
                        ),
                        currency: currentCurrency,
                        imgSrc: cartData?.product?.image,
                        quantity: cartData?.quantity,
                        disabled: !cartData?.sku?.isAvailable || disabled,
                      }
                    : {
                        name: cartData?.[isENLang ? 'nameEN' : 'nameAR'],
                        description: cartData?.product?.shortDescriptionEN,
                        id: cartData.id,
                        isOverLaptop: isOverLaptop,
                        pricePerUnit: newPriceWithExchangeRates(
                          cartData?.value,
                          cartData?.product?.baseCurrency?.name as CurrencyType,
                          currentCurrency as CurrencyType,
                          exchangeRatesData,
                        ),
                        currency: currentCurrency,
                        imgSrc: cartData?.product?.image,
                      })}
                />
              );
            })}
            <TotalValueToCart
              isAuth={isAuth}
              currency={currentCurrency}
              exchangeRatesData={exchangeRatesData}
              productData={productData}
              cb={setTotal}
            />
          </Content>
        ) : (
          <EmptyCart />
        )}
      </LoaderWrapper>
      {showBottomCartNavigate() && (
        <BottomCartNavigate
          loading={createFiatOrderLoading || payOrderLQLoading}
          onClickCheckout={doCheckout}
          disabledCheckoutBtn={!cartData?.length || hasNotAvailable}
        />
      )}
    </Wrapper>
  );
};
