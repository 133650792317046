import { Box, BoxProps, styled } from '@mui/material';
import image from 'components/icons/background/authBackground.png';
import { useRouter } from 'next/router';

export const MainLayoutWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

export const AuthLayoutWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  height: '100%',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}));

export const AuthFormBlock = styled(Box)(({ theme }) => {
  const { pathname } = useRouter();

  function getMarginTop() {
    switch (pathname) {
      case '/forgot-password':
        return '107px';
      case '/forgot-password/confirm-password':
        return '96px';
      case '/forgot-password/success-password':
        return '141px';
      case '/sign-up/confirm-email':
        return '110px';
      case '/sign-up/success':
        return '141px';
      default:
        return '0';
    }
  }

  return {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    padding: '20px 130px',
    justifyContent: 'center',
    [theme.breakpoints.down('laptop')]: {
      padding: '24px 20px',
      width: '100%',
      marginTop: getMarginTop(),
    },
  };
});

export const AuthImageBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '50%',
  backgroundImage: `url(${image.src})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

interface IContainer extends BoxProps {
  isdisabledcontainer?: 'true' | 'false';
}

export const Container = styled(Box)<IContainer>(
  ({ isdisabledcontainer, theme }) => ({
    display: isdisabledcontainer === 'true' ? 'contents' : 'flex',
    padding: '0 20px',
    flexDirection: 'column',
    flex: '1 1 auto',
    maxWidth: '1240px',
    alignSelf: 'center',
    width: '100%',
    [theme.breakpoints.down('laptop')]: {
      padding: '0 20px',
    },
  }),
);
