import React, { useEffect, useRef, useState } from 'react';
import {
  AuthCodeWrapper,
  ButtonFW,
  FormWrapper,
  NavLink,
} from 'components/Form/SignInForm.styled';
import { ArrowIcon } from '../../icons';
import { useModalContext } from 'context';
import { useTranslation } from 'next-i18next';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { getConfirmPasswordInfoText, getErrorName, MODAL_NAME } from 'utils';
import { useCount, useLaptop } from 'hooks';
import { AlertCustom, CircularProgressCustom } from 'components';
import { FormConfirmHeader } from '..';
import {
  useConfirmPhoneNumberMutation,
  useSendPhoneConfirmationCodeMutation,
} from 'graphql/generated';
import { useErrorsStore } from 'graphql/client';
import { GET_CURRENT_USER } from 'graphql/queries';
import { parsePhoneNumber } from 'awesome-phonenumber';

export const PhoneVerificationForm = () => {
  const { isOverLaptop } = useLaptop();
  const [code, setCode] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [showTimer, setShowTimer] = useState(0);
  const { openModal, onOpenModal } = useModalContext();
  const authRef = useRef<AuthCodeRef>(null);
  const pn = parsePhoneNumber(`+${openModal?.content?.phoneNumber}`);

  const error = useErrorsStore();

  useEffect(() => {
    useErrorsStore.setState({ hasError: false });
  }, []);

  const [sendPhoneConfirmationCodeMutation] =
    useSendPhoneConfirmationCodeMutation();

  const [confirmPhoneNumberMutation, { loading }] =
    useConfirmPhoneNumberMutation();

  const { t } = useTranslation();
  const { countDown, setStart, start, setCountDown } = useCount();

  const handleSubmit = () => {
    confirmPhoneNumberMutation({
      variables: {
        input: {
          phone: openModal?.content?.phoneNumber,
          code: code.toUpperCase(),
        },
      },
      refetchQueries: [GET_CURRENT_USER],
      onCompleted: () => {
        onOpenModal(MODAL_NAME.donePhone);
      },
      onError: () => {
        authRef.current?.clear();
        setShowInfo(false);
        setStart(false);
        setCountDown(0);
      },
    });
  };

  const handleCodeChange = (value: string) => {
    setCode(value);
  };

  const handleReSend = () => {
    if (error) {
      useErrorsStore.setState({
        hasError: false,
      });
    }

    setShowTimer((prevState) => prevState + 1);
    if (start && countDown <= 30 && countDown !== 0) return;
    setStart(true);
    setCountDown(30);

    sendPhoneConfirmationCodeMutation({
      variables: {
        input: {
          phone: openModal?.content?.phoneNumber,
        },
      },
      onCompleted: () => {
        setShowInfo(true);
        authRef.current?.clear();
      },
    });
  };

  return (
    <FormWrapper>
      <FormConfirmHeader
        sxSubTitle={{ padding: 0 }}
        title={t('app.profilePage.phone_verification')}
        subTitle={
          <>
            {t('app.profilePage.pe_code')}
            {!isOverLaptop ? <br /> : <></>}
            <span>
              {pn.number?.international || openModal?.content?.phoneNumber}
            </span>
          </>
        }
      />
      <AuthCodeWrapper>
        <AuthCode
          onChange={handleCodeChange}
          length={4}
          containerClassName={'containerClass'}
          inputClassName={'inputClass'}
          ref={authRef}
        />
        <ButtonFW
          sx={{ marginBottom: isOverLaptop ? '24px' : '20px' }}
          onClick={handleSubmit}
          disabled={!(code.length === 4)}
          endIcon={
            loading ? (
              <CircularProgressCustom loading={true} color={'#fff'} />
            ) : (
              <ArrowIcon />
            )
          }
          size={!isOverLaptop ? 'medium' : 'large'}
        >
          {t('app.profilePage.confirm')}
        </ButtonFW>
      </AuthCodeWrapper>

      <NavLink align={'center'} onClick={handleReSend}>
        {t`app.re_send_the_code`}
      </NavLink>
      {error.hasError || showInfo ? (
        <AlertCustom
          type={showInfo ? 'information' : 'error'}
          mt={isOverLaptop ? '24px' : '16px'}
        >
          {showInfo
            ? getConfirmPasswordInfoText(showInfo, countDown, showTimer)
            : getErrorName(error.error?.extensions?.exception?.name)}
        </AlertCustom>
      ) : null}
    </FormWrapper>
  );
};
