import React, { useContext, useEffect, useState } from 'react';
import { useCartStore } from 'Store';
import {
  CartProductSchema,
  useGetAllProductFromCartQuery,
} from 'graphql/generated';
import { useAuthContext } from 'context/AuthContext';

type Props = {
  children: React.ReactNode;
};

type ContextProps = {
  cartLength: number;
  isProductInTheCart: boolean;
  productData: any[];
  loadingAllProduct?: boolean;
};

export const CartContext = React.createContext<ContextProps>({
  cartLength: 0,
  isProductInTheCart: false,
  productData: [],
  loadingAllProduct: false,
});

export const CartProvider = ({ children }: Props) => {
  const [cartLength, setCartLength] = useState(0);
  const [productData] = useCartStore((state) => [state.productData]);
  const isProductInTheCart = !!productData.length;
  const { isAuth } = useAuthContext();

  // ! FOR AUTHENTICATION USER ===========================

  const { data: dataAllProduct, loading: loadingAllProduct } =
    useGetAllProductFromCartQuery({
      skip: !isAuth,
      fetchPolicy: 'network-only',
    });
  const allProductsCollection = dataAllProduct?.getAllProductFromCart
    .products as CartProductSchema[];

  // ! FOR  AUTHENTICATION USER ===========================

  useEffect(() => {
    setCartLength(productData.length);
  }, [productData.length]);

  const value = {
    cartLength: isAuth ? allProductsCollection?.length : cartLength,
    isProductInTheCart,
    productData: isAuth ? allProductsCollection : productData,
    loadingAllProduct,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = () => {
  const modalContext = useContext(CartContext);

  if (!modalContext) {
    throw new Error(
      'useCartContext must be used within a ModalContextProvider',
    );
  }
  return modalContext;
};
