import { Box, BoxProps, styled } from '@mui/material';
import { AliasBoolTypes } from 'types/baseTypes';

export const TabsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  borderRadius: '6px',
  padding: '3px',
  background: '#ECEFF4',
  [theme.breakpoints.down('laptop')]: {
    height: '38px',
  },
}));

interface TabBtnProps extends BoxProps {
  target?: AliasBoolTypes;
}

export const TabBtn = styled(Box)<TabBtnProps>(({ theme, target }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '34px',
  width: '129px',
  fontWeight: 300,
  fontSize: '13px',
  lineHeight: '20px',
  color: '#373748',
  userSelect: 'none',
  background: target === 'true' ? '#FFFFFF' : '#ECEFF4',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    height: '32px',
  },
}));
