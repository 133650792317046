import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const ProfIcon = ({ size = '24', color = '#4663F4' }: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_146_15721777)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.04919 17.0804C7.81321 16.1962 9.20177 15.6433 12.0043 15.6433C14.8069 15.6433 16.142 16.2124 16.9922 17.0804M7.21247 22.2591H16.7876C19.8094 22.2591 22.2591 19.8094 22.2591 16.7875V7.21241C22.2591 4.19058 19.8094 1.74091 16.7876 1.74091H7.21247C4.19064 1.74091 1.74097 4.19058 1.74097 7.21241V16.7875C1.74097 19.8094 4.19064 22.2591 7.21247 22.2591ZM15.4197 9.26423C15.4197 11.1529 13.8887 12.6839 12 12.6839C10.1114 12.6839 8.58035 11.1529 8.58035 9.26423C8.58035 7.37558 10.1114 5.84453 12 5.84453C13.8887 5.84453 15.4197 7.37558 15.4197 9.26423Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_146_15721777'>
          <rect width='24' height='24' fill='red' />
        </clipPath>
      </defs>
    </svg>
  );
};
