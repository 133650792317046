import React from 'react';
import { FormLabel, IconButton, TextField, Typography } from '@mui/material';
import { ClipboardCopyIcon } from '../../icons';
import { DefaultTFuncReturn } from 'i18next';

interface TextFieldCustomProps {
  label?: string | DefaultTFuncReturn;
  value?: string | number;
  size?: 'small' | 'medium';
  clickCopy?: () => void;
  [x: string]: any;
}

export const TextFieldCustom = ({
  label,
  value,
  size = 'small',
  clickCopy,
  ...props
}: TextFieldCustomProps) => {
  const isSizeMedium = size !== 'small';

  function f(str: string) {
    if (!str) return { main: '', click: '' };
    const fp = str?.search(/[(]/);
    const lp = str?.search(/[)]/);

    return { main: str.slice(0, fp), click: str.slice(fp, lp + 1) };
  }

  type strObjT = {
    main: string;
    click: string;
  };
  const strObj: strObjT = f(String(label));

  return (
    <>
      {label ? (
        <FormLabel sx={{ marginBottom: isSizeMedium ? '6px' : '4px' }}>
          <Typography variant={isSizeMedium ? 'tf' : 'tfs'}>
            {strObj.main}
            {strObj.click && (
              <Typography
                component={'span'}
                variant={'bottomFooterLaptop'}
                sx={{ cursor: 'pointer' }}
                role={'presentation'}
                onClick={clickCopy}
              >
                {strObj.click}
              </Typography>
            )}
          </Typography>
        </FormLabel>
      ) : null}
      <TextField
        size={size}
        value={value}
        {...props}
        InputProps={{
          endAdornment: (
            <IconButton
              size={'medium'}
              onClick={clickCopy}
              sx={{ marginInlineEnd: isSizeMedium ? '0' : '-14px' }}
            >
              <ClipboardCopyIcon />
            </IconButton>
          ),
        }}
      />
    </>
  );
};
