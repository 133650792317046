import React from 'react';
import { Typography } from '@mui/material';
import { TitleRow } from './TableHeadRowMyOrder.styled';
import { useTranslation } from 'next-i18next';

export const TableHeadRowMyOrder = () => {
  const { t } = useTranslation();
  return (
    <TitleRow>
      <Typography variant={'averageMob450'} color={'#96A0B5'}>
        {t('app.cart.product')}
      </Typography>
      <Typography variant={'averageMob450'} color={'#96A0B5'}>
        {t('app.cart.date')}
      </Typography>
      <Typography variant={'averageMob450'} color={'#96A0B5'}>
        {t('app.cart.voucher_code')}
      </Typography>
    </TitleRow>
  );
};
