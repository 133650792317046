import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const MobileIcon = ({ color = '#96A0B5' }: IconsTypes) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 2.22699C3.30964 2.22699 2.75 2.78663 2.75 3.47699V20.5232C2.75 21.2136 3.30964 21.7732 4 21.7732H13C13.6904 21.7732 14.25 21.2136 14.25 20.5232V20.5C14.25 20.0858 14.5858 19.75 15 19.75C15.4142 19.75 15.75 20.0858 15.75 20.5V20.5232C15.75 22.042 14.5188 23.2732 13 23.2732H4C2.48122 23.2732 1.25 22.042 1.25 20.5232V3.47699C1.25 1.9582 2.48122 0.72699 4 0.72699H13C14.5188 0.72699 15.75 1.95821 15.75 3.47699V4.5C15.75 4.91421 15.4142 5.25 15 5.25C14.5858 5.25 14.25 4.91421 14.25 4.5V3.47699C14.25 2.78663 13.6904 2.22699 13 2.22699H11.2697L10.708 3.95609C10.6087 4.26197 10.3253 4.47046 10.0038 4.47434L7.04122 4.51012C6.71412 4.51408 6.42219 4.30556 6.31983 3.99487L5.73738 2.22699H4ZM7.31669 2.22699L7.57255 3.0036L9.44761 2.98095L9.69251 2.22699H7.31669ZM6.99097 19.4869C6.99097 19.0727 7.32676 18.7369 7.74097 18.7369H9.24634C9.66055 18.7369 9.99634 19.0727 9.99634 19.4869C9.99634 19.9011 9.66055 20.2369 9.24634 20.2369H7.74097C7.32676 20.2369 6.99097 19.9011 6.99097 19.4869Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.2013 7.75C10.2953 7.75 8.75 9.2953 8.75 11.2017C8.75 13.1081 10.2953 14.6534 12.2013 14.6534C14.1073 14.6534 15.6525 13.1081 15.6525 11.2017C15.6525 9.2953 14.1073 7.75 12.2013 7.75ZM7.25 11.2017C7.25 8.46701 9.46668 6.25 12.2013 6.25C14.9358 6.25 17.1525 8.46701 17.1525 11.2017C17.1525 13.9363 14.9358 16.1534 12.2013 16.1534C9.46668 16.1534 7.25 13.9363 7.25 11.2017ZM10.751 9.80112C10.751 9.38691 11.0868 9.05112 11.501 9.05112H12.2013C12.6155 9.05112 12.9513 9.38691 12.9513 9.80112V12.6022C12.9513 13.0165 12.6155 13.3522 12.2013 13.3522C11.787 13.3522 11.4513 13.0165 11.4513 12.6022V10.5495C11.0603 10.5239 10.751 10.1986 10.751 9.80112ZM18.5636 12.5993C18.7083 12.2112 19.1403 12.0139 19.5284 12.1586C20.3085 12.4495 21.0026 12.9323 21.5466 13.5626C22.0907 14.1928 22.467 14.9501 22.6408 15.7643C22.8147 16.5785 22.7804 17.4235 22.5413 18.221C22.3021 19.0184 21.8657 19.7427 21.2724 20.3269C20.6792 20.911 19.9482 21.3361 19.1471 21.5628C18.3461 21.7896 17.5007 21.8107 16.6894 21.6241C15.878 21.4376 15.1268 21.0494 14.5051 20.4955C13.8835 19.9417 13.4116 19.24 13.133 18.4555C12.9944 18.0651 13.1984 17.6363 13.5888 17.4977C13.9791 17.3591 14.4079 17.5632 14.5465 17.9535C14.7407 18.5004 15.0697 18.9895 15.503 19.3756C15.9363 19.7617 16.4599 20.0322 17.0255 20.1622C17.591 20.2923 18.1802 20.2776 18.7386 20.1195C19.297 19.9615 19.8065 19.6652 20.22 19.258C20.6335 18.8508 20.9377 18.346 21.1045 17.79C21.2712 17.2341 21.2951 16.6451 21.1739 16.0775C21.0527 15.5099 20.7904 14.9821 20.4112 14.5427C20.0319 14.1034 19.5481 13.7668 19.0043 13.5641C18.6162 13.4194 18.4189 12.9874 18.5636 12.5993ZM17.7735 14.7852C18.0685 14.4944 18.5434 14.4978 18.8342 14.7928L19.3243 15.29C19.6136 15.5835 19.612 16.0554 19.3206 16.3468L17.346 18.3216C17.0531 18.6145 16.5782 18.6146 16.2853 18.3217C15.9924 18.0288 15.9924 17.5539 16.2853 17.261L17.7344 15.8118C17.4758 15.5154 17.4897 15.0651 17.7735 14.7852Z'
        fill={color}
      />
    </svg>
  );
};
