/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { TabsCustom } from 'components/TabsCustom/TabsCustom';
import { AlertCustom } from 'components/AlertCustom/AlertCustom';
import { RowForCartTableHead } from 'components/RowForCartTableHead/RowForCartTableHead';
import { LoaderWrapper } from 'components/LoaderWrapper/LoaderWrapper';
import { CartProductRow } from 'components/CartProductRow/CartProductRow';
import { newPriceWithExchangeRates } from 'utils/newPriceWithExchangeRates';
import { CurrencyType } from 'types/baseTypes';
import { BottomCartNavigate } from 'components/BottomCartNavigate/BottomCartNavigate';
import { useLaptop } from 'hooks';
import { useAuthContext, useCartContext } from 'context';
import { useRouter } from 'next/router';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useTranslation } from 'next-i18next';
import { useIntlContext } from 'context/IntlContext';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { GetExchangeRatesQuery } from 'graphql/generated';
import {
  AlertLink,
  Content,
  FlexRow,
  Wrapper,
} from '../DefaultCart/DefaultCart.styled';

interface BinanceCartProps {
  exchangeRates: ApolloQueryResult<GetExchangeRatesQuery>;
}

export const BinanceCart = ({ exchangeRates }: BinanceCartProps) => {
  const { isOverLaptop } = useLaptop();
  const { productData, isProductInTheCart, loadingAllProduct } =
    useCartContext();
  const router = useRouter();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isAuth } = useAuthContext();
  const { isENLang } = useCurrentLanguage();
  const { t } = useTranslation();
  const { currentCurrency } = useIntlContext();
  // const arrIDs = !isAuth ? productData.map((it) => +it.productId) : '';
  const exchangeRatesData = exchangeRates.data.getExchangeRates;

  // const { data, loading } = useGetProductsByIdsQuery({
  //   variables: {
  //     ids: arrIDs || [],
  //   },
  //   skip: !arrIDs.length,
  // });

  const dataToFetchCreateCart = productData?.map((item) => {
    return {
      productId: item.productId,
      count: 1,
      skuId: item.skuId,
      value: item.value,
    };
  });

  // console.log(2, productData);
  // const [createCartMutation, { data }] = useCreateCartMutation();

  // useEffect(() => {
  //   createCartMutation({
  //     variables: {
  //       input: {
  //         state: {
  //           productData: dataToFetchCreateCart,
  //         },
  //       },
  //     },
  //   });
  // }, []);

  // const cartsData = data?.getProductsByIds;
  // console.log(productData);

  // function getValuesOnProductRow(cartData: ProductEntity) {
  //   if (!cartData) return [];
  //   let targetProduct: any;
  //
  //   if (!isAuth) {
  //     targetProduct = productData.find((el) => el.productId === cartData.id);
  //   }
  //   // if (isAuth) {
  //   //   targetProduct = productData.find((el) => el.product.id === cartData.id);
  //   // }
  //   const skuData = cartData.skus?.find((el) => el.id === targetProduct?.skuId);
  //
  //   return String(skuData?.value);
  // }

  // function getValuesOnProductRow(cartData: ProductEntity) {
  //   console.log(77, cartData);
  //   const t = cartData.skus?.find((sku) => {
  //     const s = productData.find((i) => i.productId === cartData.id);
  //     console.log('s', s);
  //     return sku;
  //   });
  // }

  // function getNewFullDataLocal(data: any) {
  //   return productData?.map((item: any) => {
  //     return data?.find((prod: any) => {
  //       return prod?.id === item?.productId;
  //     });
  //   });
  // }
  // const newFullDataLocal = getNewFullDataLocal(cartsData);
  return (
    <Wrapper sx={{ paddingBottom: '150px' }}>
      <FlexRow>
        <Typography variant={isOverLaptop ? 'big500' : 'big700'}>
          {t('app.cart.cart')}
        </Typography>
        <TabsCustom tabNum={'2'} />
      </FlexRow>

      <AlertCustom type={'information'} mb={isOverLaptop ? '24px' : '16px'}>
        <span>
          {t`app.cart.you_can_pay`}.&nbsp;
          <AlertLink href={'#'}>{t`app.cart.create_your_binance`}</AlertLink>
        </span>
      </AlertCustom>

      {/*! ALERT CODE */}
      {/*<AlertCustom type={'error'} mb={isOverLaptop ? '24px' : '16px'}>*/}
      {/*  <span>*/}
      {/*    {t`app.cart.limits_exceeded`}. {t`app.cart.please`}&nbsp;*/}
      {/*    <AlertLink href={'#'}>{t`verify`}</AlertLink>*/}
      {/*  </span>*/}
      {/*</AlertCustom>*/}
      {/*<AlertCustom type={'error'} mb={isOverLaptop ? '24px' : '16px'}>*/}
      {/*  <span>{t`app.cart.unfortunately`}</span>*/}
      {/*</AlertCustom>*/}
      {productData?.length ? (
        <Content>
          <RowForCartTableHead />
          <LoaderWrapper loading={loadingAllProduct}>
            {/*{cartsData?.map((cartData) => {*/}
            {/*  const productPrice = getValuesOnProductRow(*/}
            {/*    cartData as ProductEntity,*/}
            {/*  );*/}
            {/*  const baseCurrency = cartData.baseCurrency.name;*/}

            {/*  return (*/}
            {/*    <CartProductRow*/}
            {/*      isOverLaptop={isOverLaptop}*/}
            {/*      key={cartData.id}*/}
            {/*      id={cartData.id}*/}
            {/*      name={cartData?.[isENLang ? 'nameEN' : 'nameAR']}*/}
            {/*      description={'United Arab Emirates'}*/}
            {/*      imgSrc={cartData.image}*/}
            {/*      pricePerUnit={newPriceWithExchangeRates(*/}
            {/*        productPrice,*/}
            {/*        baseCurrency as CurrencyType,*/}
            {/*        currentCurrency as CurrencyType,*/}
            {/*        exchangeRatesData,*/}
            {/*      )}*/}
            {/*      currency={currentCurrency}*/}
            {/*    />*/}
            {/*  );*/}
            {/*})}*/}
            {/*//CartProductSchema*/}
            {productData.map((cartData: any, index: number) => {
              // console.log('cartData', cartData);
              // const productPrice = getValuesOnProductRow(
              //   isAuth
              //     ? (cartData as CartProductSchema).product
              //     : (cartData as ProductEntity),
              // );

              // const baseCurrency = isAuth
              //   ? (cartData as CartProductSchema).product.baseCurrency.name
              //   : (cartData as ProductEntity).baseCurrency.name;

              return (
                <CartProductRow
                  key={index}
                  description={'description'}
                  name={'name'}
                  id={'1'}
                  isOverLaptop={isOverLaptop}
                  pricePerUnit={newPriceWithExchangeRates(
                    '1234',
                    'USD',
                    currentCurrency as CurrencyType,
                    exchangeRatesData,
                  )}
                  currency={currentCurrency}
                />
              );
            })}
            {/*<TotalValueToCart*/}
            {/*  currency={currentCurrency}*/}
            {/*  exchangeRatesData={exchangeRatesData}*/}
            {/*/>*/}
          </LoaderWrapper>
        </Content>
      ) : (
        <Typography variant={'h6'}>{t`app.cart.cart_is_empty`}</Typography>
      )}
      <BottomCartNavigate loading={false} onClickCheckout={() => {}} />
    </Wrapper>
  );
};
