import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  paddingInlineEnd: '98px',
  marginTop: '8px',
  [theme.breakpoints.down('laptop')]: {
    paddingInlineEnd: '0',
    marginTop: '4px',
  },
}));

export const ValueBlock = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '30px',
  [theme.breakpoints.down('laptop')]: {},
}));
