import { productDataProps } from 'Store/cart';
import { newPriceWithExchangeRates } from 'utils/newPriceWithExchangeRates';
import { CurrencyType } from 'types/baseTypes';
import { ExchangeRateEntity } from 'graphql/generated';

export const getResultTotalCart = (
  products: productDataProps[],
  currentCurrency: CurrencyType,
  exchangeRatesData: ExchangeRateEntity[],
  isAuth: boolean,
): string | number => {
  return products
    .reduce((acc, curV) => {
      return (
        newPriceWithExchangeRates(
          isAuth ? curV.value / curV.count : curV.value,
          curV.baseCurrency as CurrencyType,
          currentCurrency,
          exchangeRatesData,
        ) *
          curV.count +
        acc
      );
    }, 0)
    .toFixed(2);
};
