import React from 'react';
import { Typography } from '@mui/material';
import { TitleRow } from './RowForCartTableHead.styled';
import { useTranslation } from 'next-i18next';

export const RowForCartTableHead = () => {
  const { t } = useTranslation();
  return (
    <TitleRow>
      <Typography variant={'averageMob450'} color={'#96A0B5'}>
        {t('app.cart.product')}
      </Typography>
      <Typography variant={'averageMob450'} color={'#96A0B5'}>
        {t('app.cart.price_per_unit')}
      </Typography>
      <Typography variant={'averageMob450'} color={'#96A0B5'}>
        {t('app.cart.quantity')}
      </Typography>
      <Typography variant={'averageMob450'} color={'#96A0B5'}>
        {t('app.cart.amount')}
      </Typography>
    </TitleRow>
  );
};
