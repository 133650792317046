import React from 'react';
import { Container, MainLayoutWrapper } from 'components/Layout/Layout.styled';
import { useRouter } from 'next/router';
import { pathIncludesLayout, pathIncludesProfileLayout, PATHS } from 'utils';
import { Footer, Header } from 'components';
import { useLaptop } from 'hooks';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { pathname } = useRouter();
  const isDisabledContainer = pathIncludesLayout.includes(pathname);
  const isProfilePage = pathIncludesProfileLayout.includes(pathname);
  const isCartPage = pathname === PATHS.cart;

  const { isOverLaptop } = useLaptop();

  return (
    <MainLayoutWrapper>
      <Header />
      <Container isdisabledcontainer={isDisabledContainer ? 'true' : 'false'}>
        {children}
      </Container>
      {!(isCartPage || (isProfilePage && !isOverLaptop)) ? <Footer /> : null}
    </MainLayoutWrapper>
  );
};
