import { Box, styled } from '@mui/material';

export const TitleRow = styled(Box)(({ theme }) => ({
  // display: 'flex',
  display: 'grid',
  gridTemplateColumns: '1.5fr 0.67fr 0.81fr 0.2fr',
  alignItems: 'center',
  '& :nth-of-type(1)': {
    whiteSpace: 'nowrap',
    // flex: '1 0 365px',
  },
  '& :nth-of-type(2)': {
    whiteSpace: 'nowrap',
    // flex: '1 0 90px',
  },
  '& :nth-of-type(3)': {
    whiteSpace: 'nowrap',
    // flex: '1 0 303px',
  },
  '& :nth-of-type(4)': {
    whiteSpace: 'nowrap',
  },

  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));
