import React from 'react';
import { getCurrentUrl } from 'utils';

type getMetaLinksProps = (
  locales: string[] | undefined,
  domain: string,
  asPath: string,
) => React.ReactNode;

export const getMetaLinks: getMetaLinksProps = (locales, domain, asPath) => {
  return (
    locales &&
    locales.map((locale) => {
      return (
        <link
          key={locale}
          rel='alternate'
          href={getCurrentUrl(locale, domain, asPath)}
          hrefLang={locale}
        />
      );
    })
  );
};
