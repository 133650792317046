import React from 'react';
import { NotFoundProductIcon } from '../icons';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Wrapper } from 'components/NotFoundProduct/NotFoundProduct.styled';
import { useTranslation } from 'next-i18next';

export const NotFoundProduct = () => {
  const theme = useTheme();
  const isOverLaptop = useMediaQuery(theme.breakpoints.up('laptop'));
  const { t } = useTranslation();
  return (
    <Wrapper>
      <NotFoundProductIcon
        size={isOverLaptop ? '80' : '56'}
        style={{ marginBottom: isOverLaptop ? '19px' : '12px' }}
      />
      <Typography
        variant={isOverLaptop ? 'big400' : 'big300'}
        mb={isOverLaptop ? '10px' : '4px'}
      >
        {t('app.not_found_product.no_results')}
      </Typography>
      <Typography
        variant={isOverLaptop ? 'average400' : 'average200'}
        color={'#96A0B5'}
        mb={isOverLaptop ? '2px' : '5px'}
      >
        {t('app.not_found_product.search_queries')}
      </Typography>
      <Typography
        variant={isOverLaptop ? 'average400' : 'average200'}
        color={'#4663F4'}
      >
        {t('app.not_found_product.x_s_p')}
      </Typography>
    </Wrapper>
  );
};
