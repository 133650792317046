import React from 'react';
import {
  StyledBadge,
  Wrapper,
} from 'components/uiKit/CustomBadge/CustomBadge.styled';

type CustomBadgeT = {
  value: string | number;
};

export const CustomBadge = ({ value = '0' }: CustomBadgeT) => {
  if (!value) return null;
  return (
    <Wrapper>
      <StyledBadge>{value.toString()}</StyledBadge>
    </Wrapper>
  );
};
