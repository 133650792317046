import React from 'react';
import { Wrapper } from 'components/AlertCustom/AlertCustom.styled';
import { AlertType } from 'types/baseTypes';
import { ResponsiveStyleValue } from '@mui/system';

interface AlertCustomProps {
  type: AlertType;
  children?: React.ReactNode;
  mt?: ResponsiveStyleValue<string>;
  mb?: ResponsiveStyleValue<string>;
  props?: any;
}

export const AlertCustom = ({
  type,
  children,
  mt,
  ...props
}: AlertCustomProps) => {
  return (
    <Wrapper type={type} {...props} mt={mt}>
      {children}
    </Wrapper>
  );
};
