import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { DividerBlock } from 'components/OrDivider/OrDivider.styled';

interface OrDividerProps {
  [key: string]: any;
}

export const OrDivider = ({ ...props }: OrDividerProps) => {
  const { t } = useTranslation();
  return (
    <DividerBlock {...props}>
      <Divider sx={{ width: '100%' }} />
      <Typography variant={'average200'} color={'#96A0B5'}>
        {t('app.auth.or')}
      </Typography>
      <Divider sx={{ width: '100%' }} />
    </DividerBlock>
  );
};
