import React from 'react';
import { ChevronDownIcon } from '../../icons';
import { Box, MenuItem, Select, SxProps } from '@mui/material';
import { optionLangT } from 'types/optionsTypes';
import { DefaultTFuncReturn } from 'i18next';
import Image from 'next/image';

type SelectCustomT = {
  icon?: React.ReactNode;
  option?: optionLangT[];
  value?: string;
  setValue: (e: string) => void;
  size?: 'small' | 'medium';
  placeholder?: string | DefaultTFuncReturn;
  sx?: SxProps;
  disabled?: boolean;
};

export const SelectCustom = ({
  icon,
  option,
  value = '',
  setValue,
  size = 'small',
  placeholder = '',
  sx,
  disabled,
}: SelectCustomT) => {
  function getIcon(opt: optionLangT) {
    if ((opt.icon as any)['$$typeof']) {
      return opt.icon;
    } else if (typeof opt.icon === 'string') {
      return (
        <Image
          src={opt.icon as string}
          alt={'i'}
          width={24}
          height={24}
          unoptimized
        />
      );
    } else {
      return null;
    }
  }

  return (
    <Select
      sx={sx}
      size={size}
      disabled={disabled}
      IconComponent={({ ...rest }) => <ChevronDownIcon {...rest} />}
      displayEmpty
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: size === 'small' ? 45 : 55,
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        PaperProps: {
          sx: {
            background: '#ffffff',
            boxShadow: '0px 20px 64px -10px rgba(55, 55, 72, 0.3)',
            maxHeight: '300px',
            '&::-webkit-scrollbar': {
              borderRadius: '2px',
              width: '15px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#96A0B5',
              borderRadius: '10px',
              border: '5px solid #fff',
            },
          },
        },
      }}
      {...(icon && {
        renderValue: (value: any) => {
          if (!value) return placeholder;
          return (
            <Box>
              <Box sx={{ marginInlineEnd: '8px' }}>{icon}</Box>
              {value.label}
              {value.toUpperCase()}
            </Box>
          );
        },
      })}
      {...(placeholder && {
        renderValue: (value: string) => {
          if (!value) return placeholder;
          return (option as any)?.find(
            (opt: optionLangT) => opt.value === value,
          ).label;
        },
      })}
    >
      {option?.map((option: optionLangT, index: number) => {
        return (
          <MenuItem key={index} value={option.value}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {option?.icon && (
                <Box sx={{ marginInlineEnd: '12px', display: 'flex' }}>
                  {getIcon(option)}
                </Box>
              )}
              {option?.label}
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};
