import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JWT: any;
  Upload: any;
};

export type AddExchangeRateInput = {
  rate: Scalars['Float'];
  source: Scalars['String'];
  target: Scalars['String'];
};

export type AddProductToCartSchema = {
  quantity: Scalars['Int'];
  skuId: Scalars['Int'];
};

export type AddSiteInput = {
  domain: Scalars['String'];
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  user: UserEntity;
};

export type AvailableProductsSchema = {
  __typename?: 'AvailableProductsSchema';
  country: Scalars['String'];
  denominations: Array<DenominationsSchema>;
  name: Scalars['String'];
};

export type BinanceOrderEntity = {
  __typename?: 'BinanceOrderEntity';
  checkoutUrl: Scalars['String'];
  prepayId: Scalars['String'];
  qrContent: Scalars['String'];
  qrcodeLink: Scalars['String'];
};

export type CartEntity = {
  __typename?: 'CartEntity';
  id: Scalars['ID'];
  product?: Maybe<Array<ProductEntity>>;
  quantity: Scalars['Int'];
  sku: SkuEntity;
  skuId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CartForOrderInput = {
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  skuId: Scalars['Int'];
};

export type CartProductData = {
  count: Scalars['Int'];
  productId: Scalars['String'];
  skuId: Scalars['String'];
  value: Scalars['Float'];
};

export type CartProductSchema = {
  __typename?: 'CartProductSchema';
  id: Scalars['ID'];
  product: ProductEntity;
  quantity: Scalars['Int'];
  sku: SkuEntity;
  skuId: Scalars['ID'];
  total: Scalars['Float'];
};

export type CartSchema = {
  __typename?: 'CartSchema';
  products: Array<CartProductSchema>;
  total: Scalars['Float'];
};

export type CartState = {
  productData: Array<CartProductData>;
};

export type CategoriesWithCountSchema = {
  __typename?: 'CategoriesWithCountSchema';
  categories: Array<CategoryEntity>;
  count: Scalars['Int'];
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  children?: Maybe<Array<CategoryEntity>>;
  createdAt: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameAR?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  parent?: Maybe<CategoryEntity>;
  parentId?: Maybe<Scalars['ID']>;
  sorting: Scalars['Int'];
  svgCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type ChangePasswordByUserInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ChangePasswordInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ConfirmInput = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type ConvertCurrencyInput = {
  currencyFromId: Scalars['Int'];
  currencyToId: Scalars['Int'];
  value: Scalars['Float'];
};

export type CreateCartSchema = {
  state: CartState;
};

export type CreateCategoryInput = {
  nameAR?: InputMaybe<Scalars['String']>;
  nameEN?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  sorting: Scalars['Int'];
};

export type CreateInput = {
  carts: Array<CartForOrderInput>;
  /** new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString() */
  clientTime: Scalars['String'];
  currencyId: Scalars['Int'];
  currencyIso: CurrencyEnum;
  languageIso: UserLanguagesEnum;
  /** If you need to update the order sent the id. If you need to create a new order, pass null */
  orderId?: InputMaybe<Scalars['Int']>;
};

export type CreateOrderErrorSchema = {
  __typename?: 'CreateOrderErrorSchema';
  data: Array<DataError>;
  error: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  order?: Maybe<OrderEntity>;
};

export type CreateProductInput = {
  baseCurrencyId: Scalars['Int'];
  categoryId: Scalars['Int'];
  countries?: InputMaybe<Scalars['String']>;
  fullDescriptionAR: Scalars['String'];
  fullDescriptionEN: Scalars['String'];
  instructionAR: Scalars['String'];
  instructionEN: Scalars['String'];
  nameAR: Scalars['String'];
  nameEN: Scalars['String'];
  shortDescriptionAR: Scalars['String'];
  shortDescriptionEN: Scalars['String'];
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  sorting?: InputMaybe<Scalars['Int']>;
  termsAR: Scalars['String'];
  termsEN: Scalars['String'];
};

export type CreateSkuInput = {
  nameAR: Scalars['String'];
  nameEN: Scalars['String'];
  productId: Scalars['Int'];
  providerId?: InputMaybe<Scalars['String']>;
  sorting: Scalars['Int'];
  upayId?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type CurrencyEntity = {
  __typename?: 'CurrencyEntity';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum CurrencyEnum {
  Aed = 'AED',
  Eur = 'EUR',
  Usd = 'USD',
}

export type DataError = {
  __typename?: 'DataError';
  error: Scalars['Boolean'];
  isCanceled: Scalars['Boolean'];
  preOrderStatusCode: PreOrderStatusCodeEnum;
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  skuId: Scalars['Int'];
  transactionUUID?: Maybe<Scalars['String']>;
};

export type DenominationsSchema = {
  __typename?: 'DenominationsSchema';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['String']>;
  rrp?: Maybe<Scalars['Float']>;
};

export type ExchangeCurrencyPairInput = {
  source: Scalars['String'];
  target: Scalars['String'];
};

export type ExchangeRateEntity = {
  __typename?: 'ExchangeRateEntity';
  id: Scalars['ID'];
  rate: Scalars['Float'];
  source: Scalars['String'];
  target: Scalars['String'];
};

export type GetCategoriesInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Int']>;
};

export type GetOrdersInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
};

export type GetProductsInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['Int']>;
};

export type GetSkuInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GetUsersInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
};

export type KycLevelsSchema = {
  __typename?: 'KycLevelsSchema';
  limitLevel1: Scalars['Int'];
  limitLevel2: Scalars['Int'];
  limitLevel3: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addExchangeRate: ExchangeRateEntity;
  addProductToCart: CartSchema;
  changePassword: Scalars['Boolean'];
  changePasswordByUser: UserEntity;
  confirmPhoneNumber: UserEntity;
  confirmRegistration: Scalars['Boolean'];
  confirmResetPassword: Scalars['Boolean'];
  /** Step 3 in ordering. Change order status after successful payment */
  confirmationPreOrder?: Maybe<OrderEntity>;
  convertCurrency: Scalars['Float'];
  createCart: CartSchema;
  createCategory: CategoryEntity;
  /** Step 1 in ordering. Creating an order in the DB + registering an pre-order in the upay system */
  createFiatOrder: CreateOrderErrorSchema;
  createProduct: ProductEntity;
  createSite: SiteEntity;
  createSku: SkuEntity;
  removeCategory: Scalars['Boolean'];
  removeExchangeRate: Scalars['Boolean'];
  removeProduct: Scalars['Boolean'];
  removeProductFromCart: CartSchema;
  removeSite: Scalars['Boolean'];
  removeSku: Scalars['Boolean'];
  requestRefund: OrderEntity;
  resetPassword: Scalars['Boolean'];
  sendOtp: Scalars['String'];
  sendPhoneConfirmationCode: Scalars['Boolean'];
  signIn: Auth;
  signInAdmin: Auth;
  signInByGoogle: Auth;
  signUp: Scalars['Boolean'];
  toggleCodeDisplay: Scalars['Boolean'];
  updateCategory: CategoryEntity;
  updateExchangeRate: ExchangeRateEntity;
  updateOrderStatus: OrderEntity;
  updateProduct: ProductEntity;
  updateProductQuantity: CartSchema;
  updateSettings: Scalars['Boolean'];
  updateSite: SiteEntity;
  updateSku: SkuEntity;
  updateUser: UserEntity;
  verifyOtp: Scalars['String'];
};

export type MutationAddExchangeRateArgs = {
  data: AddExchangeRateInput;
};

export type MutationAddProductToCartArgs = {
  input: AddProductToCartSchema;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationChangePasswordByUserArgs = {
  input: ChangePasswordByUserInput;
};

export type MutationConfirmPhoneNumberArgs = {
  input: VerifyPhoneInput;
};

export type MutationConfirmRegistrationArgs = {
  input: ConfirmInput;
};

export type MutationConfirmResetPasswordArgs = {
  input: ConfirmInput;
};

export type MutationConfirmationPreOrderArgs = {
  signature: Scalars['String'];
};

export type MutationConvertCurrencyArgs = {
  input: ConvertCurrencyInput;
};

export type MutationCreateCartArgs = {
  input: CreateCartSchema;
};

export type MutationCreateCategoryArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  input: CreateCategoryInput;
};

export type MutationCreateFiatOrderArgs = {
  input: CreateInput;
};

export type MutationCreateProductArgs = {
  data: CreateProductInput;
  file?: InputMaybe<Scalars['Upload']>;
};

export type MutationCreateSiteArgs = {
  data: AddSiteInput;
};

export type MutationCreateSkuArgs = {
  data: CreateSkuInput;
};

export type MutationRemoveCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveExchangeRateArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveProductArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveProductFromCartArgs = {
  skuId: Scalars['ID'];
};

export type MutationRemoveSiteArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveSkuArgs = {
  id: Scalars['ID'];
};

export type MutationRequestRefundArgs = {
  id: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSendOtpArgs = {
  phoneNumber: Scalars['String'];
};

export type MutationSendPhoneConfirmationCodeArgs = {
  input: SendPhoneConfirmationInput;
};

export type MutationSignInArgs = {
  input: SignInCustomerInput;
};

export type MutationSignInAdminArgs = {
  input: SignInBaseInput;
};

export type MutationSignInByGoogleArgs = {
  input: SignUpGoogleInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationToggleCodeDisplayArgs = {
  orderSkuId: Scalars['Int'];
};

export type MutationUpdateCategoryArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['String'];
  input: UpdateCategoryInput;
};

export type MutationUpdateExchangeRateArgs = {
  data: UpdateExchangeRateInput;
  id: Scalars['ID'];
};

export type MutationUpdateOrderStatusArgs = {
  id: Scalars['ID'];
  status: OrderStatusEnum;
};

export type MutationUpdateProductArgs = {
  data: UpdateProductInput;
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['ID'];
};

export type MutationUpdateProductQuantityArgs = {
  input: UpdateProductInCartSchema;
  skuId: Scalars['ID'];
};

export type MutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};

export type MutationUpdateSiteArgs = {
  data: AddSiteInput;
  id: Scalars['ID'];
};

export type MutationUpdateSkuArgs = {
  data: UpdateSkuInput;
  id: Scalars['ID'];
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationVerifyOtpArgs = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type OrderEntity = {
  __typename?: 'OrderEntity';
  createdAt: Scalars['String'];
  currency: CurrencyEntity;
  currencyId: Scalars['Int'];
  id: Scalars['ID'];
  isPayed: Scalars['Boolean'];
  orderSkus?: Maybe<Array<OrderSkuEntity>>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  site: SiteEntity;
  siteId: Scalars['Int'];
  /** Default value: CREATED */
  status: OrderStatusEnum;
  totalQuantity: Scalars['Int'];
  ttl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  user: UserEntity;
  userId: Scalars['Int'];
};

export type OrderSkuEntity = {
  __typename?: 'OrderSkuEntity';
  createdAt: Scalars['String'];
  currentPrice: Scalars['Float'];
  currentRate: Scalars['Float'];
  id: Scalars['ID'];
  order: OrderEntity;
  orderId: Scalars['Int'];
  quantity: Scalars['Int'];
  sku: SkuEntity;
  skuId: Scalars['Int'];
  upayIsDisplayed: Scalars['Boolean'];
  upayTransactionId?: Maybe<Scalars['String']>;
  voucherCodes?: Maybe<Array<VoucherCodeEntity>>;
};

/** Default value: CREATED */
export enum OrderStatusEnum {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Refund = 'REFUND',
  RefundRequested = 'REFUND_REQUESTED',
}

export type OrdersWithCountSchema = {
  __typename?: 'OrdersWithCountSchema';
  count: Scalars['Int'];
  orders: Array<OrderEntity>;
};

export type PayOrderInput = {
  id: Scalars['Int'];
  languageIso: UserLanguagesEnum;
  siteId: Scalars['Int'];
};

export enum PreOrderStatusCodeEnum {
  AccountNotFound = 'ACCOUNT_NOT_FOUND',
  AccountNotSpecified = 'ACCOUNT_NOT_SPECIFIED',
  ClientTimeNotSpecified = 'CLIENT_TIME_NOT_SPECIFIED',
  CurrencyRateNotFound = 'CURRENCY_RATE_NOT_FOUND',
  DenominationNotFound = 'DENOMINATION_NOT_FOUND',
  DenominationNotSpecified = 'DENOMINATION_NOT_SPECIFIED',
  NoMoney = 'NO_MONEY',
  NoPins = 'NO_PINS',
  Success = 'SUCCESS',
  TheSkuHasNoProviderId = 'THE_SKU_HAS_NO_PROVIDER_ID',
  WrongQuantity = 'WRONG_QUANTITY',
}

export type PresetEntity = {
  __typename?: 'PresetEntity';
  countries: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PresetInfoSchema = {
  __typename?: 'PresetInfoSchema';
  isPreset: Scalars['Boolean'];
  presetName?: Maybe<Scalars['String']>;
};

export type ProductEntity = {
  __typename?: 'ProductEntity';
  baseCurrency: CurrencyEntity;
  baseCurrencyId: Scalars['Int'];
  carts?: Maybe<Array<CartEntity>>;
  category: CategoryEntity;
  categoryId: Scalars['Int'];
  countries?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  fullDescriptionAR?: Maybe<Scalars['String']>;
  fullDescriptionEN?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  instructionAR?: Maybe<Scalars['String']>;
  instructionEN?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  nameAR: Scalars['String'];
  nameEN: Scalars['String'];
  preset: PresetInfoSchema;
  shortDescriptionAR: Scalars['String'];
  shortDescriptionEN: Scalars['String'];
  siteIds: Array<Scalars['Int']>;
  sites: Array<SiteEntity>;
  skus?: Maybe<Array<SkuEntity>>;
  sorting: Scalars['Int'];
  termsAR?: Maybe<Scalars['String']>;
  termsEN?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type ProductsWithCountSchema = {
  __typename?: 'ProductsWithCountSchema';
  count: Scalars['Int'];
  products: Array<ProductEntity>;
};

export type Query = {
  __typename?: 'Query';
  getAllCurrency: Array<CurrencyEntity>;
  getAllProductFromCart: CartSchema;
  getCategories: CategoriesWithCountSchema;
  getCategoriesDashboard: CategoriesWithCountSchema;
  getCategory: CategoryEntity;
  getCategoryDashboard: CategoryEntity;
  getCurrentUser: UserEntity;
  getExchangeRateByCurrencyPair: ExchangeRateEntity;
  getExchangeRateById: ExchangeRateEntity;
  getExchangeRates: Array<ExchangeRateEntity>;
  getKycLimits: KycLevelsSchema;
  getManyUsers?: Maybe<UsersWithCountSchema>;
  getMyOrders: Array<OrderEntity>;
  getOrder: OrderEntity;
  getOrders: OrdersWithCountSchema;
  getPresets: Array<PresetEntity>;
  getProduct: ProductEntity;
  getProducts: ProductsWithCountSchema;
  getProductsForAdmin: ProductsWithCountSchema;
  getSettings: Array<SettingsEntity>;
  getSite: SiteEntity;
  getSites: Array<SiteEntity>;
  getSku: SkuEntity;
  getSkuFromUpay: Array<AvailableProductsSchema>;
  getSkus: SkusWithCountSchema;
  getSkusByIds: Array<SkuEntity>;
  getSkusByProductId: SkusWithCountSchema;
  getUser?: Maybe<UserEntity>;
  getVoucherCode: VoucherCodeEntity;
  /** Step 2 in ordering. Generate order payment URL */
  payBinanceOrder: BinanceOrderEntity;
  /** Step 2 in ordering. Generate order payment URL */
  payOrder: Scalars['String'];
};

export type QueryGetCategoriesArgs = {
  input: GetCategoriesInput;
};

export type QueryGetCategoriesDashboardArgs = {
  input: GetCategoriesInput;
};

export type QueryGetCategoryArgs = {
  id: Scalars['String'];
};

export type QueryGetCategoryDashboardArgs = {
  id: Scalars['String'];
};

export type QueryGetExchangeRateByCurrencyPairArgs = {
  input: ExchangeCurrencyPairInput;
};

export type QueryGetExchangeRateByIdArgs = {
  exchangeRateId: Scalars['ID'];
};

export type QueryGetKycLimitsArgs = {
  id: Scalars['ID'];
};

export type QueryGetManyUsersArgs = {
  input: GetUsersInput;
};

export type QueryGetOrderArgs = {
  id: Scalars['ID'];
};

export type QueryGetOrdersArgs = {
  input: GetOrdersInput;
};

export type QueryGetProductArgs = {
  id: Scalars['ID'];
  siteId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetProductsArgs = {
  input: GetProductsInput;
};

export type QueryGetProductsForAdminArgs = {
  input: GetProductsInput;
};

export type QueryGetSiteArgs = {
  id: Scalars['ID'];
};

export type QueryGetSkuArgs = {
  id: Scalars['ID'];
};

export type QueryGetSkusArgs = {
  input: GetSkuInput;
};

export type QueryGetSkusByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetSkusByProductIdArgs = {
  input: GetSkuInput;
  productId: Scalars['ID'];
};

export type QueryGetVoucherCodeArgs = {
  id: Scalars['ID'];
};

export type QueryPayBinanceOrderArgs = {
  input: PayOrderInput;
};

export type QueryPayOrderArgs = {
  input: PayOrderInput;
};

export type ResetPasswordInput = {
  email: Scalars['String'];
};

export type SendPhoneConfirmationInput = {
  phone: Scalars['String'];
};

export type Settings = {
  limitLevel1?: InputMaybe<Scalars['String']>;
  limitLevel2?: InputMaybe<Scalars['String']>;
  limitLevel3?: InputMaybe<Scalars['String']>;
  orderTTL?: InputMaybe<Scalars['String']>;
  siteId: Scalars['Int'];
};

export type SettingsEntity = {
  __typename?: 'SettingsEntity';
  id: Scalars['ID'];
  limitLevel1: Scalars['String'];
  limitLevel2: Scalars['String'];
  limitLevel3: Scalars['String'];
  orderTTL: Scalars['String'];
  siteId: Scalars['Int'];
};

export type SignInBaseInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignInCustomerInput = {
  captcha: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpGoogleInput = {
  accessToken: Scalars['String'];
  country: Scalars['String'];
  siteId: Scalars['Int'];
};

export type SignUpInput = {
  captcha: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  language: UserLanguagesEnum;
  password: Scalars['String'];
  siteId: Scalars['Int'];
};

export type SiteEntity = {
  __typename?: 'SiteEntity';
  domain: Scalars['String'];
  id: Scalars['ID'];
  products?: Maybe<Array<ProductEntity>>;
};

export type SkuEntity = {
  __typename?: 'SkuEntity';
  carts?: Maybe<Array<CartEntity>>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  nameAR: Scalars['String'];
  nameEN: Scalars['String'];
  product: ProductEntity;
  productId: Scalars['Int'];
  providerId?: Maybe<Scalars['String']>;
  sorting: Scalars['Int'];
  upayId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type SkusWithCountSchema = {
  __typename?: 'SkusWithCountSchema';
  count: Scalars['Int'];
  skus: Array<SkuEntity>;
};

export type UpdateCategoryInput = {
  icon?: InputMaybe<Scalars['String']>;
  nameAR?: InputMaybe<Scalars['String']>;
  nameEN?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<Scalars['Int']>;
};

export type UpdateExchangeRateInput = {
  rate?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
};

export type UpdateProductInCartSchema = {
  quantity: Scalars['Int'];
};

export type UpdateProductInput = {
  baseCurrencyId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  countries?: InputMaybe<Scalars['String']>;
  fullDescriptionAR?: InputMaybe<Scalars['String']>;
  fullDescriptionEN?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  instructionAR?: InputMaybe<Scalars['String']>;
  instructionEN?: InputMaybe<Scalars['String']>;
  nameAR?: InputMaybe<Scalars['String']>;
  nameEN?: InputMaybe<Scalars['String']>;
  shortDescriptionAR?: InputMaybe<Scalars['String']>;
  shortDescriptionEN?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  sorting?: InputMaybe<Scalars['Int']>;
  termsAR?: InputMaybe<Scalars['String']>;
  termsEN?: InputMaybe<Scalars['String']>;
};

/** Input for settings update */
export type UpdateSettingsInput = {
  settings: Array<Settings>;
};

export type UpdateSkuInput = {
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  nameAR?: InputMaybe<Scalars['String']>;
  nameEN?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
  upayId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  address?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currencyId?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  googleId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  kycLevel: Scalars['Int'];
  language: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phoneIsVerified: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  siteId: Scalars['Int'];
  total?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export enum UserLanguagesEnum {
  Ar = 'AR',
  En = 'EN',
}

export type UsersWithCountSchema = {
  __typename?: 'UsersWithCountSchema';
  count: Scalars['Int'];
  users: Array<UserEntity>;
};

export type VerifyPhoneInput = {
  code: Scalars['String'];
  phone: Scalars['String'];
};

export type VoucherCodeEntity = {
  __typename?: 'VoucherCodeEntity';
  code: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  orderSku: OrderSkuEntity;
  orderSkuId: Scalars['Int'];
};

export type AddProductToCartMutationVariables = Exact<{
  input: AddProductToCartSchema;
}>;

export type AddProductToCartMutation = {
  __typename?: 'Mutation';
  addProductToCart: {
    __typename?: 'CartSchema';
    total: number;
    products: Array<{
      __typename?: 'CartProductSchema';
      total: number;
      quantity: number;
      skuId: string;
      id: string;
      product: {
        __typename?: 'ProductEntity';
        id: string;
        nameEN: string;
        nameAR: string;
        siteIds: Array<number>;
        shortDescriptionEN: string;
        shortDescriptionAR: string;
        fullDescriptionEN?: string | null;
        fullDescriptionAR?: string | null;
        instructionEN?: string | null;
        instructionAR?: string | null;
        termsEN?: string | null;
        termsAR?: string | null;
        sorting: number;
        image?: string | null;
        countries?: string | null;
        baseCurrencyId: number;
        categoryId: number;
        createdAt: string;
        updatedAt: string;
        baseCurrency: {
          __typename?: 'CurrencyEntity';
          id: string;
          name: string;
        };
        category: {
          __typename?: 'CategoryEntity';
          id: string;
          nameEN?: string | null;
          nameAR?: string | null;
          icon?: string | null;
          sorting: number;
          parentId?: string | null;
          createdAt: string;
          updatedAt: string;
          parent?: {
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          children?: Array<{
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          }> | null;
        };
        skus?: Array<{
          __typename?: 'SkuEntity';
          id: string;
          productId: number;
          nameEN: string;
          nameAR: string;
          value: number;
          providerId?: string | null;
          upayId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        }> | null;
        carts?: Array<{
          __typename?: 'CartEntity';
          id: string;
          userId: number;
          skuId: number;
          quantity: number;
        }> | null;
        preset: {
          __typename?: 'PresetInfoSchema';
          isPreset: boolean;
          presetName?: string | null;
        };
      };
      sku: {
        __typename?: 'SkuEntity';
        id: string;
        productId: number;
        nameEN: string;
        nameAR: string;
        value: number;
        providerId?: string | null;
        upayId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    }>;
  };
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword: boolean;
};

export type ChangePasswordByUserMutationVariables = Exact<{
  input: ChangePasswordByUserInput;
}>;

export type ChangePasswordByUserMutation = {
  __typename?: 'Mutation';
  changePasswordByUser: {
    __typename?: 'UserEntity';
    id: string;
    email: string;
    role: string;
    language: string;
    kycLevel: number;
    country: string;
    name?: string | null;
    address?: string | null;
    phoneNumber?: string | null;
    phoneIsVerified: boolean;
    googleId?: string | null;
    siteId: number;
    currencyId?: number | null;
    isVerified: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type ConfirmPhoneNumberMutationVariables = Exact<{
  input: VerifyPhoneInput;
}>;

export type ConfirmPhoneNumberMutation = {
  __typename?: 'Mutation';
  confirmPhoneNumber: {
    __typename?: 'UserEntity';
    id: string;
    email: string;
    role: string;
    language: string;
    kycLevel: number;
    country: string;
    name?: string | null;
    address?: string | null;
    phoneNumber?: string | null;
    googleId?: string | null;
    siteId: number;
    currencyId?: number | null;
    isVerified: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type ConfirmRegistrationMutationVariables = Exact<{
  input: ConfirmInput;
}>;

export type ConfirmRegistrationMutation = {
  __typename?: 'Mutation';
  confirmRegistration: boolean;
};

export type ConfirmResetPasswordMutationVariables = Exact<{
  input: ConfirmInput;
}>;

export type ConfirmResetPasswordMutation = {
  __typename?: 'Mutation';
  confirmResetPassword: boolean;
};

export type ConfirmationPreOrderMutationVariables = Exact<{
  signature: Scalars['String'];
}>;

export type ConfirmationPreOrderMutation = {
  __typename?: 'Mutation';
  confirmationPreOrder?: {
    __typename?: 'OrderEntity';
    id: string;
    siteId: number;
    isPayed: boolean;
    currencyId: number;
    status: OrderStatusEnum;
    userId: number;
    ttl?: string | null;
    createdAt: string;
    updatedAt: string;
    totalQuantity: number;
    orderSkus?: Array<{
      __typename?: 'OrderSkuEntity';
      id: string;
      skuId: number;
      currentPrice: number;
      currentRate: number;
      upayIsDisplayed: boolean;
      orderId: number;
      quantity: number;
      voucherCodes?: Array<{
        __typename?: 'VoucherCodeEntity';
        id: string;
        code: string;
        orderSkuId: number;
        createdAt: string;
        orderSku: {
          __typename?: 'OrderSkuEntity';
          id: string;
          sku: {
            __typename?: 'SkuEntity';
            nameEN: string;
            nameAR: string;
            product: {
              __typename?: 'ProductEntity';
              nameEN: string;
              nameAR: string;
              shortDescriptionEN: string;
              shortDescriptionAR: string;
              image?: string | null;
            };
          };
        };
      }> | null;
    }> | null;
  } | null;
};

export type ConvertCurrencyMutationVariables = Exact<{
  input: ConvertCurrencyInput;
}>;

export type ConvertCurrencyMutation = {
  __typename?: 'Mutation';
  convertCurrency: number;
};

export type CreateCartMutationVariables = Exact<{
  input: CreateCartSchema;
}>;

export type CreateCartMutation = {
  __typename?: 'Mutation';
  createCart: {
    __typename?: 'CartSchema';
    total: number;
    products: Array<{
      __typename?: 'CartProductSchema';
      total: number;
      quantity: number;
      skuId: string;
      id: string;
      product: {
        __typename?: 'ProductEntity';
        id: string;
        nameEN: string;
        nameAR: string;
        siteIds: Array<number>;
        shortDescriptionEN: string;
        shortDescriptionAR: string;
        fullDescriptionEN?: string | null;
        fullDescriptionAR?: string | null;
        instructionEN?: string | null;
        instructionAR?: string | null;
        termsEN?: string | null;
        termsAR?: string | null;
        sorting: number;
        image?: string | null;
        countries?: string | null;
        baseCurrencyId: number;
        categoryId: number;
        createdAt: string;
        updatedAt: string;
        baseCurrency: {
          __typename?: 'CurrencyEntity';
          id: string;
          name: string;
        };
        category: {
          __typename?: 'CategoryEntity';
          id: string;
          nameEN?: string | null;
          nameAR?: string | null;
          icon?: string | null;
          sorting: number;
          parentId?: string | null;
          createdAt: string;
          updatedAt: string;
          parent?: {
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          children?: Array<{
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          }> | null;
        };
        skus?: Array<{
          __typename?: 'SkuEntity';
          id: string;
          productId: number;
          nameEN: string;
          nameAR: string;
          value: number;
          providerId?: string | null;
          upayId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        }> | null;
        carts?: Array<{
          __typename?: 'CartEntity';
          id: string;
          userId: number;
          skuId: number;
          quantity: number;
        }> | null;
        preset: {
          __typename?: 'PresetInfoSchema';
          isPreset: boolean;
          presetName?: string | null;
        };
      };
      sku: {
        __typename?: 'SkuEntity';
        id: string;
        productId: number;
        nameEN: string;
        nameAR: string;
        value: number;
        providerId?: string | null;
        upayId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    }>;
  };
};

export type CreateFiatOrderMutationVariables = Exact<{
  input: CreateInput;
}>;

export type CreateFiatOrderMutation = {
  __typename?: 'Mutation';
  createFiatOrder: {
    __typename?: 'CreateOrderErrorSchema';
    error: boolean;
    message?: string | null;
    order?: {
      __typename?: 'OrderEntity';
      id: string;
      siteId: number;
      paymentTransactionId?: string | null;
      isPayed: boolean;
      currencyId: number;
      status: OrderStatusEnum;
      userId: number;
      ttl?: string | null;
      createdAt: string;
      updatedAt: string;
      totalQuantity: number;
      site: {
        __typename?: 'SiteEntity';
        id: string;
        domain: string;
        products?: Array<{
          __typename?: 'ProductEntity';
          id: string;
          nameEN: string;
          nameAR: string;
          siteIds: Array<number>;
          shortDescriptionEN: string;
          shortDescriptionAR: string;
          fullDescriptionEN?: string | null;
          fullDescriptionAR?: string | null;
          instructionEN?: string | null;
          instructionAR?: string | null;
          termsEN?: string | null;
          termsAR?: string | null;
          sorting: number;
          image?: string | null;
          countries?: string | null;
          baseCurrencyId: number;
          categoryId: number;
          isAvailable?: boolean | null;
          createdAt: string;
          updatedAt: string;
          baseCurrency: {
            __typename?: 'CurrencyEntity';
            id: string;
            name: string;
          };
          category: {
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
            svgCode?: string | null;
            parent?: {
              __typename?: 'CategoryEntity';
              id: string;
              nameEN?: string | null;
              nameAR?: string | null;
              icon?: string | null;
              sorting: number;
              parentId?: string | null;
              createdAt: string;
              updatedAt: string;
              svgCode?: string | null;
            } | null;
            children?: Array<{
              __typename?: 'CategoryEntity';
              id: string;
              nameEN?: string | null;
              nameAR?: string | null;
              icon?: string | null;
              sorting: number;
              parentId?: string | null;
              createdAt: string;
              updatedAt: string;
              svgCode?: string | null;
            }> | null;
          };
          skus?: Array<{
            __typename?: 'SkuEntity';
            id: string;
            productId: number;
            nameEN: string;
            nameAR: string;
            value: number;
            providerId?: string | null;
            upayId?: string | null;
            isAvailable?: boolean | null;
            createdAt?: string | null;
            updatedAt?: string | null;
          }> | null;
          carts?: Array<{
            __typename?: 'CartEntity';
            id: string;
            userId: number;
            skuId: number;
            quantity: number;
          }> | null;
          preset: {
            __typename?: 'PresetInfoSchema';
            isPreset: boolean;
            presetName?: string | null;
          };
        }> | null;
      };
      currency: { __typename?: 'CurrencyEntity'; id: string; name: string };
      user: {
        __typename?: 'UserEntity';
        id: string;
        email: string;
        role: string;
        language: string;
        kycLevel: number;
        country: string;
        name?: string | null;
        address?: string | null;
        phoneNumber?: string | null;
        phoneIsVerified: boolean;
        googleId?: string | null;
        siteId: number;
        currencyId?: number | null;
        isVerified: boolean;
        createdAt: any;
        updatedAt: any;
        total?: number | null;
      };
      orderSkus?: Array<{
        __typename?: 'OrderSkuEntity';
        id: string;
        skuId: number;
        currentPrice: number;
        currentRate: number;
        upayTransactionId?: string | null;
        upayIsDisplayed: boolean;
        orderId: number;
        quantity: number;
        createdAt: string;
        sku: {
          __typename?: 'SkuEntity';
          id: string;
          productId: number;
          nameEN: string;
          nameAR: string;
          value: number;
          providerId?: string | null;
          upayId?: string | null;
          isAvailable?: boolean | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          product: {
            __typename?: 'ProductEntity';
            id: string;
            nameEN: string;
            nameAR: string;
            siteIds: Array<number>;
            shortDescriptionEN: string;
            shortDescriptionAR: string;
            fullDescriptionEN?: string | null;
            fullDescriptionAR?: string | null;
            instructionEN?: string | null;
            instructionAR?: string | null;
            termsEN?: string | null;
            termsAR?: string | null;
            sorting: number;
            image?: string | null;
            countries?: string | null;
            baseCurrencyId: number;
            categoryId: number;
            isAvailable?: boolean | null;
            createdAt: string;
            updatedAt: string;
          };
        };
        voucherCodes?: Array<{
          __typename?: 'VoucherCodeEntity';
          id: string;
          code: string;
          orderSkuId: number;
          createdAt: string;
          orderSku: {
            __typename?: 'OrderSkuEntity';
            id: string;
            skuId: number;
            currentPrice: number;
            currentRate: number;
            upayTransactionId?: string | null;
            upayIsDisplayed: boolean;
            orderId: number;
            quantity: number;
            createdAt: string;
          };
        }> | null;
      }> | null;
    } | null;
    data: Array<{
      __typename?: 'DataError';
      skuId: number;
      productId: number;
      quantity: number;
      transactionUUID?: string | null;
      error: boolean;
      isCanceled: boolean;
      preOrderStatusCode: PreOrderStatusCodeEnum;
    }>;
  };
};

export type RemoveProductFromCartMutationVariables = Exact<{
  skuId: Scalars['ID'];
}>;

export type RemoveProductFromCartMutation = {
  __typename?: 'Mutation';
  removeProductFromCart: {
    __typename?: 'CartSchema';
    total: number;
    products: Array<{
      __typename?: 'CartProductSchema';
      total: number;
      quantity: number;
      skuId: string;
      id: string;
      product: {
        __typename?: 'ProductEntity';
        id: string;
        nameEN: string;
        nameAR: string;
        siteIds: Array<number>;
        shortDescriptionEN: string;
        shortDescriptionAR: string;
        fullDescriptionEN?: string | null;
        fullDescriptionAR?: string | null;
        instructionEN?: string | null;
        instructionAR?: string | null;
        termsEN?: string | null;
        termsAR?: string | null;
        sorting: number;
        image?: string | null;
        countries?: string | null;
        baseCurrencyId: number;
        categoryId: number;
        createdAt: string;
        updatedAt: string;
        baseCurrency: {
          __typename?: 'CurrencyEntity';
          id: string;
          name: string;
        };
        category: {
          __typename?: 'CategoryEntity';
          id: string;
          nameEN?: string | null;
          nameAR?: string | null;
          icon?: string | null;
          sorting: number;
          parentId?: string | null;
          createdAt: string;
          updatedAt: string;
          parent?: {
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          children?: Array<{
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          }> | null;
        };
        skus?: Array<{
          __typename?: 'SkuEntity';
          id: string;
          productId: number;
          nameEN: string;
          nameAR: string;
          value: number;
          providerId?: string | null;
          upayId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        }> | null;
        carts?: Array<{
          __typename?: 'CartEntity';
          id: string;
          userId: number;
          skuId: number;
          quantity: number;
        }> | null;
        preset: {
          __typename?: 'PresetInfoSchema';
          isPreset: boolean;
          presetName?: string | null;
        };
      };
      sku: {
        __typename?: 'SkuEntity';
        id: string;
        productId: number;
        nameEN: string;
        nameAR: string;
        value: number;
        providerId?: string | null;
        upayId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    }>;
  };
};

export type RequestRefundMutationVariables = Exact<{
  requestRefundId: Scalars['ID'];
}>;

export type RequestRefundMutation = {
  __typename?: 'Mutation';
  requestRefund: {
    __typename?: 'OrderEntity';
    id: string;
    siteId: number;
    paymentTransactionId?: string | null;
    isPayed: boolean;
    currencyId: number;
    status: OrderStatusEnum;
    userId: number;
    ttl?: string | null;
    createdAt: string;
    updatedAt: string;
    totalQuantity: number;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: boolean;
};

export type SendPhoneConfirmationCodeMutationVariables = Exact<{
  input: SendPhoneConfirmationInput;
}>;

export type SendPhoneConfirmationCodeMutation = {
  __typename?: 'Mutation';
  sendPhoneConfirmationCode: boolean;
};

export type SignInMutationVariables = Exact<{
  input: SignInCustomerInput;
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signIn: {
    __typename?: 'Auth';
    accessToken: any;
    user: {
      __typename?: 'UserEntity';
      id: string;
      email: string;
      role: string;
      language: string;
      kycLevel: number;
      country: string;
      name?: string | null;
      address?: string | null;
      phoneNumber?: string | null;
      googleId?: string | null;
      siteId: number;
      currencyId?: number | null;
      createdAt: any;
      updatedAt: any;
    };
  };
};

export type SignInByGoogleMutationVariables = Exact<{
  input: SignUpGoogleInput;
}>;

export type SignInByGoogleMutation = {
  __typename?: 'Mutation';
  signInByGoogle: {
    __typename?: 'Auth';
    accessToken: any;
    user: {
      __typename?: 'UserEntity';
      id: string;
      email: string;
      role: string;
      language: string;
      kycLevel: number;
      country: string;
      name?: string | null;
      address?: string | null;
      phoneNumber?: string | null;
      googleId?: string | null;
      siteId: number;
      currencyId?: number | null;
      isVerified: boolean;
      createdAt: any;
      updatedAt: any;
    };
  };
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = { __typename?: 'Mutation'; signUp: boolean };

export type ToggleCodeDisplayMutationVariables = Exact<{
  orderSkuId: Scalars['Int'];
}>;

export type ToggleCodeDisplayMutation = {
  __typename?: 'Mutation';
  toggleCodeDisplay: boolean;
};

export type UpdateProductQuantityMutationVariables = Exact<{
  skuId: Scalars['ID'];
  input: UpdateProductInCartSchema;
}>;

export type UpdateProductQuantityMutation = {
  __typename?: 'Mutation';
  updateProductQuantity: {
    __typename?: 'CartSchema';
    total: number;
    products: Array<{
      __typename?: 'CartProductSchema';
      total: number;
      quantity: number;
      skuId: string;
      id: string;
      product: {
        __typename?: 'ProductEntity';
        id: string;
        nameEN: string;
        nameAR: string;
        siteIds: Array<number>;
        shortDescriptionEN: string;
        shortDescriptionAR: string;
        fullDescriptionEN?: string | null;
        fullDescriptionAR?: string | null;
        instructionEN?: string | null;
        instructionAR?: string | null;
        termsEN?: string | null;
        termsAR?: string | null;
        sorting: number;
        image?: string | null;
        countries?: string | null;
        baseCurrencyId: number;
        categoryId: number;
        createdAt: string;
        updatedAt: string;
        baseCurrency: {
          __typename?: 'CurrencyEntity';
          id: string;
          name: string;
        };
        category: {
          __typename?: 'CategoryEntity';
          id: string;
          nameEN?: string | null;
          nameAR?: string | null;
          icon?: string | null;
          sorting: number;
          parentId?: string | null;
          createdAt: string;
          updatedAt: string;
          parent?: {
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          children?: Array<{
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          }> | null;
        };
        skus?: Array<{
          __typename?: 'SkuEntity';
          id: string;
          productId: number;
          nameEN: string;
          nameAR: string;
          value: number;
          providerId?: string | null;
          upayId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        }> | null;
        carts?: Array<{
          __typename?: 'CartEntity';
          id: string;
          userId: number;
          skuId: number;
          quantity: number;
        }> | null;
        preset: {
          __typename?: 'PresetInfoSchema';
          isPreset: boolean;
          presetName?: string | null;
        };
      };
      sku: {
        __typename?: 'SkuEntity';
        id: string;
        productId: number;
        nameEN: string;
        nameAR: string;
        value: number;
        providerId?: string | null;
        upayId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    }>;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'UserEntity';
    id: string;
    email: string;
    role: string;
    language: string;
    kycLevel: number;
    country: string;
    name?: string | null;
    address?: string | null;
    phoneNumber?: string | null;
    phoneIsVerified: boolean;
    googleId?: string | null;
    siteId: number;
    currencyId?: number | null;
    isVerified: boolean;
    createdAt: any;
    updatedAt: any;
    total?: number | null;
  };
};

export type GetAllCurrencyQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCurrencyQuery = {
  __typename?: 'Query';
  getAllCurrency: Array<{
    __typename?: 'CurrencyEntity';
    id: string;
    name: string;
  }>;
};

export type GetAllProductFromCartQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllProductFromCartQuery = {
  __typename?: 'Query';
  getAllProductFromCart: {
    __typename?: 'CartSchema';
    total: number;
    products: Array<{
      __typename?: 'CartProductSchema';
      total: number;
      quantity: number;
      skuId: string;
      id: string;
      product: {
        __typename?: 'ProductEntity';
        id: string;
        nameEN: string;
        nameAR: string;
        siteIds: Array<number>;
        shortDescriptionEN: string;
        shortDescriptionAR: string;
        fullDescriptionEN?: string | null;
        fullDescriptionAR?: string | null;
        instructionEN?: string | null;
        instructionAR?: string | null;
        termsEN?: string | null;
        termsAR?: string | null;
        sorting: number;
        image?: string | null;
        countries?: string | null;
        baseCurrencyId: number;
        categoryId: number;
        isAvailable?: boolean | null;
        createdAt: string;
        updatedAt: string;
        baseCurrency: {
          __typename?: 'CurrencyEntity';
          id: string;
          name: string;
        };
        category: {
          __typename?: 'CategoryEntity';
          id: string;
          nameEN?: string | null;
          nameAR?: string | null;
          icon?: string | null;
          sorting: number;
          parentId?: string | null;
          createdAt: string;
          updatedAt: string;
          parent?: {
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          children?: Array<{
            __typename?: 'CategoryEntity';
            id: string;
            nameEN?: string | null;
            nameAR?: string | null;
            icon?: string | null;
            sorting: number;
            parentId?: string | null;
            createdAt: string;
            updatedAt: string;
          }> | null;
        };
        skus?: Array<{
          __typename?: 'SkuEntity';
          id: string;
          productId: number;
          nameEN: string;
          nameAR: string;
          value: number;
          providerId?: string | null;
          isAvailable?: boolean | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        }> | null;
        carts?: Array<{
          __typename?: 'CartEntity';
          id: string;
          userId: number;
          skuId: number;
          quantity: number;
        }> | null;
        preset: {
          __typename?: 'PresetInfoSchema';
          isPreset: boolean;
          presetName?: string | null;
        };
      };
      sku: {
        __typename?: 'SkuEntity';
        id: string;
        productId: number;
        nameEN: string;
        nameAR: string;
        value: number;
        providerId?: string | null;
        isAvailable?: boolean | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    }>;
  };
};

export type GetCategoriesQueryVariables = Exact<{
  input: GetCategoriesInput;
}>;

export type GetCategoriesQuery = {
  __typename?: 'Query';
  getCategories: {
    __typename?: 'CategoriesWithCountSchema';
    count: number;
    categories: Array<{
      __typename?: 'CategoryEntity';
      id: string;
      nameEN?: string | null;
      nameAR?: string | null;
      icon?: string | null;
      svgCode?: string | null;
      sorting: number;
    }>;
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  getCurrentUser: {
    __typename?: 'UserEntity';
    id: string;
    email: string;
    role: string;
    language: string;
    kycLevel: number;
    country: string;
    name?: string | null;
    address?: string | null;
    phoneNumber?: string | null;
    phoneIsVerified: boolean;
    googleId?: string | null;
    siteId: number;
    currencyId?: number | null;
    isVerified: boolean;
    createdAt: any;
    updatedAt: any;
    total?: number | null;
  };
};

export type GetExchangeRatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetExchangeRatesQuery = {
  __typename?: 'Query';
  getExchangeRates: Array<{
    __typename?: 'ExchangeRateEntity';
    id: string;
    rate: number;
    target: string;
    source: string;
  }>;
};

export type GetKycLimitsQueryVariables = Exact<{
  getKycLimitsId: Scalars['ID'];
}>;

export type GetKycLimitsQuery = {
  __typename?: 'Query';
  getKycLimits: {
    __typename?: 'KycLevelsSchema';
    limitLevel1: number;
    limitLevel2: number;
    limitLevel3: number;
  };
};

export type GetMyOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyOrdersQuery = {
  __typename?: 'Query';
  getMyOrders: Array<{
    __typename?: 'OrderEntity';
    status: OrderStatusEnum;
    createdAt: string;
    updatedAt: string;
    id: string;
    paymentTransactionId?: string | null;
    orderSkus?: Array<{
      __typename?: 'OrderSkuEntity';
      id: string;
      upayIsDisplayed: boolean;
      voucherCodes?: Array<{
        __typename?: 'VoucherCodeEntity';
        orderSkuId: number;
        id: string;
        code: string;
        orderSku: {
          __typename?: 'OrderSkuEntity';
          orderId: number;
          sku: {
            __typename?: 'SkuEntity';
            product: {
              __typename?: 'ProductEntity';
              termsEN?: string | null;
              termsAR?: string | null;
              nameEN: string;
              nameAR: string;
              id: string;
              shortDescriptionEN: string;
              shortDescriptionAR: string;
              image?: string | null;
              createdAt: string;
              updatedAt: string;
            };
          };
        };
      }> | null;
    }> | null;
  }>;
};

export type GetProductQueryVariables = Exact<{
  getProductId: Scalars['ID'];
  siteId?: InputMaybe<Scalars['ID']>;
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  getProduct: {
    __typename?: 'ProductEntity';
    id: string;
    nameEN: string;
    nameAR: string;
    siteIds: Array<number>;
    shortDescriptionEN: string;
    shortDescriptionAR: string;
    fullDescriptionEN?: string | null;
    fullDescriptionAR?: string | null;
    instructionEN?: string | null;
    instructionAR?: string | null;
    termsEN?: string | null;
    termsAR?: string | null;
    image?: string | null;
    countries?: string | null;
    baseCurrencyId: number;
    baseCurrency: { __typename?: 'CurrencyEntity'; id: string; name: string };
    category: {
      __typename?: 'CategoryEntity';
      id: string;
      nameEN?: string | null;
      nameAR?: string | null;
    };
    skus?: Array<{
      __typename?: 'SkuEntity';
      id: string;
      nameEN: string;
      nameAR: string;
      value: number;
    }> | null;
    preset: {
      __typename?: 'PresetInfoSchema';
      isPreset: boolean;
      presetName?: string | null;
    };
  };
};

export type GetProductsQueryVariables = Exact<{
  input: GetProductsInput;
}>;

export type GetProductsQuery = {
  __typename?: 'Query';
  getProducts: {
    __typename?: 'ProductsWithCountSchema';
    count: number;
    products: Array<{
      __typename?: 'ProductEntity';
      id: string;
      nameEN: string;
      nameAR: string;
      siteIds: Array<number>;
      shortDescriptionEN: string;
      shortDescriptionAR: string;
      fullDescriptionEN?: string | null;
      fullDescriptionAR?: string | null;
      instructionEN?: string | null;
      instructionAR?: string | null;
      termsEN?: string | null;
      termsAR?: string | null;
      image?: string | null;
      countries?: string | null;
      baseCurrencyId: number;
      categoryId: number;
      baseCurrency: { __typename?: 'CurrencyEntity'; id: string; name: string };
      category: { __typename?: 'CategoryEntity'; id: string };
      preset: {
        __typename?: 'PresetInfoSchema';
        isPreset: boolean;
        presetName?: string | null;
      };
    }>;
  };
};

export type GetSitesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSitesQuery = {
  __typename?: 'Query';
  getSites: Array<{ __typename?: 'SiteEntity'; id: string; domain: string }>;
};

export type GetSkusByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetSkusByIdsQuery = {
  __typename?: 'Query';
  getSkusByIds: Array<{
    __typename?: 'SkuEntity';
    id: string;
    productId: number;
    nameEN: string;
    nameAR: string;
    value: number;
    providerId?: string | null;
    upayId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    product: {
      __typename?: 'ProductEntity';
      id: string;
      nameEN: string;
      nameAR: string;
      siteIds: Array<number>;
      shortDescriptionEN: string;
      shortDescriptionAR: string;
      fullDescriptionEN?: string | null;
      fullDescriptionAR?: string | null;
      instructionEN?: string | null;
      instructionAR?: string | null;
      termsEN?: string | null;
      termsAR?: string | null;
      sorting: number;
      image?: string | null;
      countries?: string | null;
      baseCurrencyId: number;
      categoryId: number;
      createdAt: string;
      updatedAt: string;
      baseCurrency: { __typename?: 'CurrencyEntity'; id: string; name: string };
      category: {
        __typename?: 'CategoryEntity';
        id: string;
        nameEN?: string | null;
        nameAR?: string | null;
        icon?: string | null;
        sorting: number;
        parentId?: string | null;
        createdAt: string;
        updatedAt: string;
        parent?: {
          __typename?: 'CategoryEntity';
          id: string;
          nameEN?: string | null;
          nameAR?: string | null;
          icon?: string | null;
          sorting: number;
          parentId?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        children?: Array<{
          __typename?: 'CategoryEntity';
          id: string;
          nameEN?: string | null;
          nameAR?: string | null;
          icon?: string | null;
          sorting: number;
          parentId?: string | null;
          createdAt: string;
          updatedAt: string;
        }> | null;
      };
      skus?: Array<{
        __typename?: 'SkuEntity';
        id: string;
        productId: number;
        nameEN: string;
        nameAR: string;
        value: number;
        providerId?: string | null;
        upayId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      }> | null;
      preset: {
        __typename?: 'PresetInfoSchema';
        isPreset: boolean;
        presetName?: string | null;
      };
    };
    carts?: Array<{
      __typename?: 'CartEntity';
      id: string;
      userId: number;
      skuId: number;
      quantity: number;
      sku: {
        __typename?: 'SkuEntity';
        id: string;
        productId: number;
        nameEN: string;
        nameAR: string;
        value: number;
        providerId?: string | null;
        upayId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
    }> | null;
  }>;
};

export type GetVoucherCodeQueryVariables = Exact<{
  getVoucherCodeId: Scalars['ID'];
}>;

export type GetVoucherCodeQuery = {
  __typename?: 'Query';
  getVoucherCode: {
    __typename?: 'VoucherCodeEntity';
    id: string;
    code: string;
    orderSkuId: number;
    createdAt: string;
  };
};

export type PayOrderQueryVariables = Exact<{
  input: PayOrderInput;
}>;

export type PayOrderQuery = { __typename?: 'Query'; payOrder: string };

export const AddProductToCartDocument = gql`
  mutation AddProductToCart($input: AddProductToCartSchema!) {
    addProductToCart(input: $input) {
      products {
        product {
          id
          nameEN
          nameAR
          siteIds
          shortDescriptionEN
          shortDescriptionAR
          fullDescriptionEN
          fullDescriptionAR
          instructionEN
          instructionAR
          termsEN
          termsAR
          sorting
          image
          countries
          baseCurrencyId
          categoryId
          createdAt
          updatedAt
          baseCurrency {
            id
            name
          }
          category {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
            parent {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
            children {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
          }
          skus {
            id
            productId
            nameEN
            nameAR
            value
            providerId
            upayId
            createdAt
            updatedAt
          }
          carts {
            id
            userId
            skuId
            quantity
          }
          preset {
            isPreset
            presetName
          }
        }
        total
        quantity
        skuId
        sku {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          upayId
          createdAt
          updatedAt
        }
        id
      }
      total
    }
  }
`;
export type AddProductToCartMutationFn = Apollo.MutationFunction<
  AddProductToCartMutation,
  AddProductToCartMutationVariables
>;

/**
 * __useAddProductToCartMutation__
 *
 * To run a mutation, you first call `useAddProductToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductToCartMutation, { data, loading, error }] = useAddProductToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductToCartMutation,
    AddProductToCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductToCartMutation,
    AddProductToCartMutationVariables
  >(AddProductToCartDocument, options);
}
export type AddProductToCartMutationHookResult = ReturnType<
  typeof useAddProductToCartMutation
>;
export type AddProductToCartMutationResult =
  Apollo.MutationResult<AddProductToCartMutation>;
export type AddProductToCartMutationOptions = Apollo.BaseMutationOptions<
  AddProductToCartMutation,
  AddProductToCartMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ChangePasswordByUserDocument = gql`
  mutation ChangePasswordByUser($input: ChangePasswordByUserInput!) {
    changePasswordByUser(input: $input) {
      id
      email
      role
      language
      kycLevel
      country
      name
      address
      phoneNumber
      phoneIsVerified
      googleId
      siteId
      currencyId
      isVerified
      createdAt
      updatedAt
    }
  }
`;
export type ChangePasswordByUserMutationFn = Apollo.MutationFunction<
  ChangePasswordByUserMutation,
  ChangePasswordByUserMutationVariables
>;

/**
 * __useChangePasswordByUserMutation__
 *
 * To run a mutation, you first call `useChangePasswordByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordByUserMutation, { data, loading, error }] = useChangePasswordByUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordByUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordByUserMutation,
    ChangePasswordByUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordByUserMutation,
    ChangePasswordByUserMutationVariables
  >(ChangePasswordByUserDocument, options);
}
export type ChangePasswordByUserMutationHookResult = ReturnType<
  typeof useChangePasswordByUserMutation
>;
export type ChangePasswordByUserMutationResult =
  Apollo.MutationResult<ChangePasswordByUserMutation>;
export type ChangePasswordByUserMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordByUserMutation,
  ChangePasswordByUserMutationVariables
>;
export const ConfirmPhoneNumberDocument = gql`
  mutation ConfirmPhoneNumber($input: VerifyPhoneInput!) {
    confirmPhoneNumber(input: $input) {
      id
      email
      role
      language
      kycLevel
      country
      name
      address
      phoneNumber
      googleId
      siteId
      currencyId
      isVerified
      createdAt
      updatedAt
    }
  }
`;
export type ConfirmPhoneNumberMutationFn = Apollo.MutationFunction<
  ConfirmPhoneNumberMutation,
  ConfirmPhoneNumberMutationVariables
>;

/**
 * __useConfirmPhoneNumberMutation__
 *
 * To run a mutation, you first call `useConfirmPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPhoneNumberMutation, { data, loading, error }] = useConfirmPhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPhoneNumberMutation,
    ConfirmPhoneNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmPhoneNumberMutation,
    ConfirmPhoneNumberMutationVariables
  >(ConfirmPhoneNumberDocument, options);
}
export type ConfirmPhoneNumberMutationHookResult = ReturnType<
  typeof useConfirmPhoneNumberMutation
>;
export type ConfirmPhoneNumberMutationResult =
  Apollo.MutationResult<ConfirmPhoneNumberMutation>;
export type ConfirmPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPhoneNumberMutation,
  ConfirmPhoneNumberMutationVariables
>;
export const ConfirmRegistrationDocument = gql`
  mutation ConfirmRegistration($input: ConfirmInput!) {
    confirmRegistration(input: $input)
  }
`;
export type ConfirmRegistrationMutationFn = Apollo.MutationFunction<
  ConfirmRegistrationMutation,
  ConfirmRegistrationMutationVariables
>;

/**
 * __useConfirmRegistrationMutation__
 *
 * To run a mutation, you first call `useConfirmRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRegistrationMutation, { data, loading, error }] = useConfirmRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmRegistrationMutation,
    ConfirmRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmRegistrationMutation,
    ConfirmRegistrationMutationVariables
  >(ConfirmRegistrationDocument, options);
}
export type ConfirmRegistrationMutationHookResult = ReturnType<
  typeof useConfirmRegistrationMutation
>;
export type ConfirmRegistrationMutationResult =
  Apollo.MutationResult<ConfirmRegistrationMutation>;
export type ConfirmRegistrationMutationOptions = Apollo.BaseMutationOptions<
  ConfirmRegistrationMutation,
  ConfirmRegistrationMutationVariables
>;
export const ConfirmResetPasswordDocument = gql`
  mutation ConfirmResetPassword($input: ConfirmInput!) {
    confirmResetPassword(input: $input)
  }
`;
export type ConfirmResetPasswordMutationFn = Apollo.MutationFunction<
  ConfirmResetPasswordMutation,
  ConfirmResetPasswordMutationVariables
>;

/**
 * __useConfirmResetPasswordMutation__
 *
 * To run a mutation, you first call `useConfirmResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmResetPasswordMutation, { data, loading, error }] = useConfirmResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmResetPasswordMutation,
    ConfirmResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmResetPasswordMutation,
    ConfirmResetPasswordMutationVariables
  >(ConfirmResetPasswordDocument, options);
}
export type ConfirmResetPasswordMutationHookResult = ReturnType<
  typeof useConfirmResetPasswordMutation
>;
export type ConfirmResetPasswordMutationResult =
  Apollo.MutationResult<ConfirmResetPasswordMutation>;
export type ConfirmResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ConfirmResetPasswordMutation,
  ConfirmResetPasswordMutationVariables
>;
export const ConfirmationPreOrderDocument = gql`
  mutation ConfirmationPreOrder($signature: String!) {
    confirmationPreOrder(signature: $signature) {
      id
      siteId
      isPayed
      currencyId
      status
      userId
      ttl
      createdAt
      updatedAt
      orderSkus {
        id
        skuId
        currentPrice
        currentRate
        upayIsDisplayed
        orderId
        quantity
        voucherCodes {
          id
          code
          orderSkuId
          orderSku {
            id
            sku {
              nameEN
              nameAR
              product {
                nameEN
                nameAR
                shortDescriptionEN
                shortDescriptionAR
                image
              }
            }
          }
          createdAt
        }
      }
      totalQuantity
    }
  }
`;
export type ConfirmationPreOrderMutationFn = Apollo.MutationFunction<
  ConfirmationPreOrderMutation,
  ConfirmationPreOrderMutationVariables
>;

/**
 * __useConfirmationPreOrderMutation__
 *
 * To run a mutation, you first call `useConfirmationPreOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmationPreOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmationPreOrderMutation, { data, loading, error }] = useConfirmationPreOrderMutation({
 *   variables: {
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useConfirmationPreOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmationPreOrderMutation,
    ConfirmationPreOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmationPreOrderMutation,
    ConfirmationPreOrderMutationVariables
  >(ConfirmationPreOrderDocument, options);
}
export type ConfirmationPreOrderMutationHookResult = ReturnType<
  typeof useConfirmationPreOrderMutation
>;
export type ConfirmationPreOrderMutationResult =
  Apollo.MutationResult<ConfirmationPreOrderMutation>;
export type ConfirmationPreOrderMutationOptions = Apollo.BaseMutationOptions<
  ConfirmationPreOrderMutation,
  ConfirmationPreOrderMutationVariables
>;
export const ConvertCurrencyDocument = gql`
  mutation ConvertCurrency($input: ConvertCurrencyInput!) {
    convertCurrency(input: $input)
  }
`;
export type ConvertCurrencyMutationFn = Apollo.MutationFunction<
  ConvertCurrencyMutation,
  ConvertCurrencyMutationVariables
>;

/**
 * __useConvertCurrencyMutation__
 *
 * To run a mutation, you first call `useConvertCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertCurrencyMutation, { data, loading, error }] = useConvertCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertCurrencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertCurrencyMutation,
    ConvertCurrencyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConvertCurrencyMutation,
    ConvertCurrencyMutationVariables
  >(ConvertCurrencyDocument, options);
}
export type ConvertCurrencyMutationHookResult = ReturnType<
  typeof useConvertCurrencyMutation
>;
export type ConvertCurrencyMutationResult =
  Apollo.MutationResult<ConvertCurrencyMutation>;
export type ConvertCurrencyMutationOptions = Apollo.BaseMutationOptions<
  ConvertCurrencyMutation,
  ConvertCurrencyMutationVariables
>;
export const CreateCartDocument = gql`
  mutation CreateCart($input: CreateCartSchema!) {
    createCart(input: $input) {
      products {
        product {
          id
          nameEN
          nameAR
          siteIds
          shortDescriptionEN
          shortDescriptionAR
          fullDescriptionEN
          fullDescriptionAR
          instructionEN
          instructionAR
          termsEN
          termsAR
          sorting
          image
          countries
          baseCurrencyId
          categoryId
          createdAt
          updatedAt
          baseCurrency {
            id
            name
          }
          category {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
            parent {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
            children {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
          }
          skus {
            id
            productId
            nameEN
            nameAR
            value
            providerId
            upayId
            createdAt
            updatedAt
          }
          carts {
            id
            userId
            skuId
            quantity
          }
          preset {
            isPreset
            presetName
          }
        }
        total
        quantity
        skuId
        sku {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          upayId
          createdAt
          updatedAt
        }
        id
      }
      total
    }
  }
`;
export type CreateCartMutationFn = Apollo.MutationFunction<
  CreateCartMutation,
  CreateCartMutationVariables
>;

/**
 * __useCreateCartMutation__
 *
 * To run a mutation, you first call `useCreateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartMutation, { data, loading, error }] = useCreateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCartMutation,
    CreateCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCartMutation, CreateCartMutationVariables>(
    CreateCartDocument,
    options,
  );
}
export type CreateCartMutationHookResult = ReturnType<
  typeof useCreateCartMutation
>;
export type CreateCartMutationResult =
  Apollo.MutationResult<CreateCartMutation>;
export type CreateCartMutationOptions = Apollo.BaseMutationOptions<
  CreateCartMutation,
  CreateCartMutationVariables
>;
export const CreateFiatOrderDocument = gql`
  mutation CreateFiatOrder($input: CreateInput!) {
    createFiatOrder(input: $input) {
      error
      order {
        id
        siteId
        paymentTransactionId
        isPayed
        currencyId
        status
        userId
        ttl
        createdAt
        updatedAt
        site {
          id
          domain
          products {
            id
            nameEN
            nameAR
            siteIds
            shortDescriptionEN
            shortDescriptionAR
            fullDescriptionEN
            fullDescriptionAR
            instructionEN
            instructionAR
            termsEN
            termsAR
            sorting
            image
            countries
            baseCurrencyId
            categoryId
            isAvailable
            createdAt
            updatedAt
            baseCurrency {
              id
              name
            }
            category {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
              svgCode
              parent {
                id
                nameEN
                nameAR
                icon
                sorting
                parentId
                createdAt
                updatedAt
                svgCode
              }
              children {
                id
                nameEN
                nameAR
                icon
                sorting
                parentId
                createdAt
                updatedAt
                svgCode
              }
            }
            skus {
              id
              productId
              nameEN
              nameAR
              value
              providerId
              upayId
              isAvailable
              createdAt
              updatedAt
            }
            carts {
              id
              userId
              skuId
              quantity
            }
            preset {
              isPreset
              presetName
            }
          }
        }
        currency {
          id
          name
        }
        user {
          id
          email
          role
          language
          kycLevel
          country
          name
          address
          phoneNumber
          phoneIsVerified
          googleId
          siteId
          currencyId
          isVerified
          createdAt
          updatedAt
          total
        }
        orderSkus {
          id
          skuId
          currentPrice
          currentRate
          upayTransactionId
          upayIsDisplayed
          orderId
          quantity
          createdAt
          sku {
            id
            productId
            nameEN
            nameAR
            value
            providerId
            upayId
            isAvailable
            createdAt
            updatedAt
            product {
              id
              nameEN
              nameAR
              siteIds
              shortDescriptionEN
              shortDescriptionAR
              fullDescriptionEN
              fullDescriptionAR
              instructionEN
              instructionAR
              termsEN
              termsAR
              sorting
              image
              countries
              baseCurrencyId
              categoryId
              isAvailable
              createdAt
              updatedAt
            }
          }
          voucherCodes {
            id
            code
            orderSkuId
            createdAt
            orderSku {
              id
              skuId
              currentPrice
              currentRate
              upayTransactionId
              upayIsDisplayed
              orderId
              quantity
              createdAt
            }
          }
        }
        totalQuantity
      }
      message
      data {
        skuId
        productId
        quantity
        transactionUUID
        error
        isCanceled
        preOrderStatusCode
      }
    }
  }
`;
export type CreateFiatOrderMutationFn = Apollo.MutationFunction<
  CreateFiatOrderMutation,
  CreateFiatOrderMutationVariables
>;

/**
 * __useCreateFiatOrderMutation__
 *
 * To run a mutation, you first call `useCreateFiatOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFiatOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFiatOrderMutation, { data, loading, error }] = useCreateFiatOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFiatOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFiatOrderMutation,
    CreateFiatOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFiatOrderMutation,
    CreateFiatOrderMutationVariables
  >(CreateFiatOrderDocument, options);
}
export type CreateFiatOrderMutationHookResult = ReturnType<
  typeof useCreateFiatOrderMutation
>;
export type CreateFiatOrderMutationResult =
  Apollo.MutationResult<CreateFiatOrderMutation>;
export type CreateFiatOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateFiatOrderMutation,
  CreateFiatOrderMutationVariables
>;
export const RemoveProductFromCartDocument = gql`
  mutation RemoveProductFromCart($skuId: ID!) {
    removeProductFromCart(skuId: $skuId) {
      products {
        product {
          id
          nameEN
          nameAR
          siteIds
          shortDescriptionEN
          shortDescriptionAR
          fullDescriptionEN
          fullDescriptionAR
          instructionEN
          instructionAR
          termsEN
          termsAR
          sorting
          image
          countries
          baseCurrencyId
          categoryId
          createdAt
          updatedAt
          baseCurrency {
            id
            name
          }
          category {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
            parent {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
            children {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
          }
          skus {
            id
            productId
            nameEN
            nameAR
            value
            providerId
            upayId
            createdAt
            updatedAt
          }
          carts {
            id
            userId
            skuId
            quantity
          }
          preset {
            isPreset
            presetName
          }
        }
        total
        quantity
        skuId
        sku {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          upayId
          createdAt
          updatedAt
        }
        id
      }
      total
    }
  }
`;
export type RemoveProductFromCartMutationFn = Apollo.MutationFunction<
  RemoveProductFromCartMutation,
  RemoveProductFromCartMutationVariables
>;

/**
 * __useRemoveProductFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveProductFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductFromCartMutation, { data, loading, error }] = useRemoveProductFromCartMutation({
 *   variables: {
 *      skuId: // value for 'skuId'
 *   },
 * });
 */
export function useRemoveProductFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProductFromCartMutation,
    RemoveProductFromCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProductFromCartMutation,
    RemoveProductFromCartMutationVariables
  >(RemoveProductFromCartDocument, options);
}
export type RemoveProductFromCartMutationHookResult = ReturnType<
  typeof useRemoveProductFromCartMutation
>;
export type RemoveProductFromCartMutationResult =
  Apollo.MutationResult<RemoveProductFromCartMutation>;
export type RemoveProductFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveProductFromCartMutation,
  RemoveProductFromCartMutationVariables
>;
export const RequestRefundDocument = gql`
  mutation RequestRefund($requestRefundId: ID!) {
    requestRefund(id: $requestRefundId) {
      id
      siteId
      paymentTransactionId
      isPayed
      currencyId
      status
      userId
      ttl
      createdAt
      updatedAt
      totalQuantity
    }
  }
`;
export type RequestRefundMutationFn = Apollo.MutationFunction<
  RequestRefundMutation,
  RequestRefundMutationVariables
>;

/**
 * __useRequestRefundMutation__
 *
 * To run a mutation, you first call `useRequestRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRefundMutation, { data, loading, error }] = useRequestRefundMutation({
 *   variables: {
 *      requestRefundId: // value for 'requestRefundId'
 *   },
 * });
 */
export function useRequestRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestRefundMutation,
    RequestRefundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestRefundMutation,
    RequestRefundMutationVariables
  >(RequestRefundDocument, options);
}
export type RequestRefundMutationHookResult = ReturnType<
  typeof useRequestRefundMutation
>;
export type RequestRefundMutationResult =
  Apollo.MutationResult<RequestRefundMutation>;
export type RequestRefundMutationOptions = Apollo.BaseMutationOptions<
  RequestRefundMutation,
  RequestRefundMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SendPhoneConfirmationCodeDocument = gql`
  mutation SendPhoneConfirmationCode($input: SendPhoneConfirmationInput!) {
    sendPhoneConfirmationCode(input: $input)
  }
`;
export type SendPhoneConfirmationCodeMutationFn = Apollo.MutationFunction<
  SendPhoneConfirmationCodeMutation,
  SendPhoneConfirmationCodeMutationVariables
>;

/**
 * __useSendPhoneConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useSendPhoneConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPhoneConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPhoneConfirmationCodeMutation, { data, loading, error }] = useSendPhoneConfirmationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPhoneConfirmationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPhoneConfirmationCodeMutation,
    SendPhoneConfirmationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPhoneConfirmationCodeMutation,
    SendPhoneConfirmationCodeMutationVariables
  >(SendPhoneConfirmationCodeDocument, options);
}
export type SendPhoneConfirmationCodeMutationHookResult = ReturnType<
  typeof useSendPhoneConfirmationCodeMutation
>;
export type SendPhoneConfirmationCodeMutationResult =
  Apollo.MutationResult<SendPhoneConfirmationCodeMutation>;
export type SendPhoneConfirmationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    SendPhoneConfirmationCodeMutation,
    SendPhoneConfirmationCodeMutationVariables
  >;
export const SignInDocument = gql`
  mutation SignIn($input: SignInCustomerInput!) {
    signIn(input: $input) {
      user {
        id
        email
        role
        language
        kycLevel
        country
        name
        address
        phoneNumber
        googleId
        siteId
        currencyId
        createdAt
        updatedAt
      }
      accessToken
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<
  SignInMutation,
  SignInMutationVariables
>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInMutation,
    SignInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
    options,
  );
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const SignInByGoogleDocument = gql`
  mutation SignInByGoogle($input: SignUpGoogleInput!) {
    signInByGoogle(input: $input) {
      user {
        id
        email
        role
        language
        kycLevel
        country
        name
        address
        phoneNumber
        googleId
        siteId
        currencyId
        isVerified
        createdAt
        updatedAt
      }
      accessToken
    }
  }
`;
export type SignInByGoogleMutationFn = Apollo.MutationFunction<
  SignInByGoogleMutation,
  SignInByGoogleMutationVariables
>;

/**
 * __useSignInByGoogleMutation__
 *
 * To run a mutation, you first call `useSignInByGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInByGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInByGoogleMutation, { data, loading, error }] = useSignInByGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInByGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInByGoogleMutation,
    SignInByGoogleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignInByGoogleMutation,
    SignInByGoogleMutationVariables
  >(SignInByGoogleDocument, options);
}
export type SignInByGoogleMutationHookResult = ReturnType<
  typeof useSignInByGoogleMutation
>;
export type SignInByGoogleMutationResult =
  Apollo.MutationResult<SignInByGoogleMutation>;
export type SignInByGoogleMutationOptions = Apollo.BaseMutationOptions<
  SignInByGoogleMutation,
  SignInByGoogleMutationVariables
>;
export const SignUpDocument = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input)
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options,
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const ToggleCodeDisplayDocument = gql`
  mutation ToggleCodeDisplay($orderSkuId: Int!) {
    toggleCodeDisplay(orderSkuId: $orderSkuId)
  }
`;
export type ToggleCodeDisplayMutationFn = Apollo.MutationFunction<
  ToggleCodeDisplayMutation,
  ToggleCodeDisplayMutationVariables
>;

/**
 * __useToggleCodeDisplayMutation__
 *
 * To run a mutation, you first call `useToggleCodeDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCodeDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCodeDisplayMutation, { data, loading, error }] = useToggleCodeDisplayMutation({
 *   variables: {
 *      orderSkuId: // value for 'orderSkuId'
 *   },
 * });
 */
export function useToggleCodeDisplayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleCodeDisplayMutation,
    ToggleCodeDisplayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleCodeDisplayMutation,
    ToggleCodeDisplayMutationVariables
  >(ToggleCodeDisplayDocument, options);
}
export type ToggleCodeDisplayMutationHookResult = ReturnType<
  typeof useToggleCodeDisplayMutation
>;
export type ToggleCodeDisplayMutationResult =
  Apollo.MutationResult<ToggleCodeDisplayMutation>;
export type ToggleCodeDisplayMutationOptions = Apollo.BaseMutationOptions<
  ToggleCodeDisplayMutation,
  ToggleCodeDisplayMutationVariables
>;
export const UpdateProductQuantityDocument = gql`
  mutation UpdateProductQuantity(
    $skuId: ID!
    $input: UpdateProductInCartSchema!
  ) {
    updateProductQuantity(skuId: $skuId, input: $input) {
      products {
        product {
          id
          nameEN
          nameAR
          siteIds
          shortDescriptionEN
          shortDescriptionAR
          fullDescriptionEN
          fullDescriptionAR
          instructionEN
          instructionAR
          termsEN
          termsAR
          sorting
          image
          countries
          baseCurrencyId
          categoryId
          createdAt
          updatedAt
          baseCurrency {
            id
            name
          }
          category {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
            parent {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
            children {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
          }
          skus {
            id
            productId
            nameEN
            nameAR
            value
            providerId
            upayId
            createdAt
            updatedAt
          }
          carts {
            id
            userId
            skuId
            quantity
          }
          preset {
            isPreset
            presetName
          }
        }
        total
        quantity
        skuId
        sku {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          upayId
          createdAt
          updatedAt
        }
        id
      }
      total
    }
  }
`;
export type UpdateProductQuantityMutationFn = Apollo.MutationFunction<
  UpdateProductQuantityMutation,
  UpdateProductQuantityMutationVariables
>;

/**
 * __useUpdateProductQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateProductQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductQuantityMutation, { data, loading, error }] = useUpdateProductQuantityMutation({
 *   variables: {
 *      skuId: // value for 'skuId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductQuantityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductQuantityMutation,
    UpdateProductQuantityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProductQuantityMutation,
    UpdateProductQuantityMutationVariables
  >(UpdateProductQuantityDocument, options);
}
export type UpdateProductQuantityMutationHookResult = ReturnType<
  typeof useUpdateProductQuantityMutation
>;
export type UpdateProductQuantityMutationResult =
  Apollo.MutationResult<UpdateProductQuantityMutation>;
export type UpdateProductQuantityMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductQuantityMutation,
  UpdateProductQuantityMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      role
      language
      kycLevel
      country
      name
      address
      phoneNumber
      phoneIsVerified
      googleId
      siteId
      currencyId
      isVerified
      createdAt
      updatedAt
      total
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const GetAllCurrencyDocument = gql`
  query GetAllCurrency {
    getAllCurrency {
      id
      name
    }
  }
`;

/**
 * __useGetAllCurrencyQuery__
 *
 * To run a query within a React component, call `useGetAllCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCurrencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCurrencyQuery,
    GetAllCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCurrencyQuery, GetAllCurrencyQueryVariables>(
    GetAllCurrencyDocument,
    options,
  );
}
export function useGetAllCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCurrencyQuery,
    GetAllCurrencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCurrencyQuery, GetAllCurrencyQueryVariables>(
    GetAllCurrencyDocument,
    options,
  );
}
export type GetAllCurrencyQueryHookResult = ReturnType<
  typeof useGetAllCurrencyQuery
>;
export type GetAllCurrencyLazyQueryHookResult = ReturnType<
  typeof useGetAllCurrencyLazyQuery
>;
export type GetAllCurrencyQueryResult = Apollo.QueryResult<
  GetAllCurrencyQuery,
  GetAllCurrencyQueryVariables
>;
export const GetAllProductFromCartDocument = gql`
  query GetAllProductFromCart {
    getAllProductFromCart {
      products {
        product {
          id
          nameEN
          nameAR
          siteIds
          shortDescriptionEN
          shortDescriptionAR
          fullDescriptionEN
          fullDescriptionAR
          instructionEN
          instructionAR
          termsEN
          termsAR
          sorting
          image
          countries
          baseCurrencyId
          categoryId
          isAvailable
          createdAt
          updatedAt
          baseCurrency {
            id
            name
          }
          category {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
            parent {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
            children {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
          }
          skus {
            id
            productId
            nameEN
            nameAR
            value
            providerId
            isAvailable
            createdAt
            updatedAt
          }
          carts {
            id
            userId
            skuId
            quantity
          }
          preset {
            isPreset
            presetName
          }
        }
        total
        quantity
        skuId
        sku {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          isAvailable
          createdAt
          updatedAt
        }
        id
      }
      total
    }
  }
`;

/**
 * __useGetAllProductFromCartQuery__
 *
 * To run a query within a React component, call `useGetAllProductFromCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductFromCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductFromCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductFromCartQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllProductFromCartQuery,
    GetAllProductFromCartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllProductFromCartQuery,
    GetAllProductFromCartQueryVariables
  >(GetAllProductFromCartDocument, options);
}
export function useGetAllProductFromCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllProductFromCartQuery,
    GetAllProductFromCartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllProductFromCartQuery,
    GetAllProductFromCartQueryVariables
  >(GetAllProductFromCartDocument, options);
}
export type GetAllProductFromCartQueryHookResult = ReturnType<
  typeof useGetAllProductFromCartQuery
>;
export type GetAllProductFromCartLazyQueryHookResult = ReturnType<
  typeof useGetAllProductFromCartLazyQuery
>;
export type GetAllProductFromCartQueryResult = Apollo.QueryResult<
  GetAllProductFromCartQuery,
  GetAllProductFromCartQueryVariables
>;
export const GetCategoriesDocument = gql`
  query GetCategories($input: GetCategoriesInput!) {
    getCategories(input: $input) {
      categories {
        id
        nameEN
        nameAR
        icon
        svgCode
        sorting
      }
      count
    }
  }
`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCategoriesQuery,
    GetCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    options,
  );
}
export function useGetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoriesQuery,
    GetCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    options,
  );
}
export type GetCategoriesQueryHookResult = ReturnType<
  typeof useGetCategoriesQuery
>;
export type GetCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetCategoriesLazyQuery
>;
export type GetCategoriesQueryResult = Apollo.QueryResult<
  GetCategoriesQuery,
  GetCategoriesQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      role
      language
      kycLevel
      country
      name
      address
      phoneNumber
      phoneIsVerified
      googleId
      siteId
      currencyId
      isVerified
      createdAt
      updatedAt
      total
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetExchangeRatesDocument = gql`
  query GetExchangeRates {
    getExchangeRates {
      id
      rate
      target
      source
    }
  }
`;

/**
 * __useGetExchangeRatesQuery__
 *
 * To run a query within a React component, call `useGetExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExchangeRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExchangeRatesQuery,
    GetExchangeRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeRatesQuery, GetExchangeRatesQueryVariables>(
    GetExchangeRatesDocument,
    options,
  );
}
export function useGetExchangeRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExchangeRatesQuery,
    GetExchangeRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExchangeRatesQuery,
    GetExchangeRatesQueryVariables
  >(GetExchangeRatesDocument, options);
}
export type GetExchangeRatesQueryHookResult = ReturnType<
  typeof useGetExchangeRatesQuery
>;
export type GetExchangeRatesLazyQueryHookResult = ReturnType<
  typeof useGetExchangeRatesLazyQuery
>;
export type GetExchangeRatesQueryResult = Apollo.QueryResult<
  GetExchangeRatesQuery,
  GetExchangeRatesQueryVariables
>;
export const GetKycLimitsDocument = gql`
  query GetKycLimits($getKycLimitsId: ID!) {
    getKycLimits(id: $getKycLimitsId) {
      limitLevel1
      limitLevel2
      limitLevel3
    }
  }
`;

/**
 * __useGetKycLimitsQuery__
 *
 * To run a query within a React component, call `useGetKycLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKycLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKycLimitsQuery({
 *   variables: {
 *      getKycLimitsId: // value for 'getKycLimitsId'
 *   },
 * });
 */
export function useGetKycLimitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKycLimitsQuery,
    GetKycLimitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKycLimitsQuery, GetKycLimitsQueryVariables>(
    GetKycLimitsDocument,
    options,
  );
}
export function useGetKycLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKycLimitsQuery,
    GetKycLimitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKycLimitsQuery, GetKycLimitsQueryVariables>(
    GetKycLimitsDocument,
    options,
  );
}
export type GetKycLimitsQueryHookResult = ReturnType<
  typeof useGetKycLimitsQuery
>;
export type GetKycLimitsLazyQueryHookResult = ReturnType<
  typeof useGetKycLimitsLazyQuery
>;
export type GetKycLimitsQueryResult = Apollo.QueryResult<
  GetKycLimitsQuery,
  GetKycLimitsQueryVariables
>;
export const GetMyOrdersDocument = gql`
  query GetMyOrders {
    getMyOrders {
      orderSkus {
        id
        upayIsDisplayed
        voucherCodes {
          orderSkuId
          id
          code
          orderSku {
            sku {
              product {
                termsEN
                termsAR
                nameEN
                nameAR
                id
                shortDescriptionEN
                shortDescriptionAR
                image
                createdAt
                updatedAt
              }
            }
            orderId
          }
        }
      }
      status
      createdAt
      updatedAt
      id
      paymentTransactionId
    }
  }
`;

/**
 * __useGetMyOrdersQuery__
 *
 * To run a query within a React component, call `useGetMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyOrdersQuery,
    GetMyOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(
    GetMyOrdersDocument,
    options,
  );
}
export function useGetMyOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyOrdersQuery,
    GetMyOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(
    GetMyOrdersDocument,
    options,
  );
}
export type GetMyOrdersQueryHookResult = ReturnType<typeof useGetMyOrdersQuery>;
export type GetMyOrdersLazyQueryHookResult = ReturnType<
  typeof useGetMyOrdersLazyQuery
>;
export type GetMyOrdersQueryResult = Apollo.QueryResult<
  GetMyOrdersQuery,
  GetMyOrdersQueryVariables
>;
export const GetProductDocument = gql`
  query GetProduct($getProductId: ID!, $siteId: ID) {
    getProduct(id: $getProductId, siteId: $siteId) {
      id
      nameEN
      nameAR
      siteIds
      shortDescriptionEN
      shortDescriptionAR
      fullDescriptionEN
      fullDescriptionAR
      instructionEN
      instructionAR
      termsEN
      termsAR
      image
      countries
      baseCurrencyId
      baseCurrency {
        id
        name
      }
      category {
        id
        nameEN
        nameAR
      }
      skus {
        id
        nameEN
        nameAR
        value
      }
      preset {
        isPreset
        presetName
      }
    }
  }
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      getProductId: // value for 'getProductId'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options,
  );
}
export function useGetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options,
  );
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<
  typeof useGetProductLazyQuery
>;
export type GetProductQueryResult = Apollo.QueryResult<
  GetProductQuery,
  GetProductQueryVariables
>;
export const GetProductsDocument = gql`
  query GetProducts($input: GetProductsInput!) {
    getProducts(input: $input) {
      products {
        id
        nameEN
        nameAR
        siteIds
        shortDescriptionEN
        shortDescriptionAR
        fullDescriptionEN
        fullDescriptionAR
        instructionEN
        instructionAR
        termsEN
        termsAR
        image
        countries
        baseCurrencyId
        categoryId
        baseCurrency {
          id
          name
        }
        category {
          id
        }
        preset {
          isPreset
          presetName
        }
      }
      count
    }
  }
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options,
  );
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options,
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<
  typeof useGetProductsLazyQuery
>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const GetSitesDocument = gql`
  query GetSites {
    getSites {
      id
      domain
    }
  }
`;

/**
 * __useGetSitesQuery__
 *
 * To run a query within a React component, call `useGetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSitesQuery, GetSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSitesQuery, GetSitesQueryVariables>(
    GetSitesDocument,
    options,
  );
}
export function useGetSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSitesQuery,
    GetSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSitesQuery, GetSitesQueryVariables>(
    GetSitesDocument,
    options,
  );
}
export type GetSitesQueryHookResult = ReturnType<typeof useGetSitesQuery>;
export type GetSitesLazyQueryHookResult = ReturnType<
  typeof useGetSitesLazyQuery
>;
export type GetSitesQueryResult = Apollo.QueryResult<
  GetSitesQuery,
  GetSitesQueryVariables
>;
export const GetSkusByIdsDocument = gql`
  query GetSkusByIds($ids: [ID!]!) {
    getSkusByIds(ids: $ids) {
      id
      productId
      nameEN
      nameAR
      value
      providerId
      upayId
      createdAt
      updatedAt
      product {
        id
        nameEN
        nameAR
        siteIds
        shortDescriptionEN
        shortDescriptionAR
        fullDescriptionEN
        fullDescriptionAR
        instructionEN
        instructionAR
        termsEN
        termsAR
        sorting
        image
        countries
        baseCurrencyId
        categoryId
        createdAt
        updatedAt
        baseCurrency {
          id
          name
        }
        category {
          id
          nameEN
          nameAR
          icon
          sorting
          parentId
          createdAt
          updatedAt
          parent {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
          }
          children {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
          }
        }
        skus {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          upayId
          createdAt
          updatedAt
        }
        preset {
          isPreset
          presetName
        }
      }
      carts {
        id
        userId
        skuId
        quantity
        sku {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          upayId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetSkusByIdsQuery__
 *
 * To run a query within a React component, call `useGetSkusByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkusByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkusByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetSkusByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSkusByIdsQuery,
    GetSkusByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSkusByIdsQuery, GetSkusByIdsQueryVariables>(
    GetSkusByIdsDocument,
    options,
  );
}
export function useGetSkusByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSkusByIdsQuery,
    GetSkusByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSkusByIdsQuery, GetSkusByIdsQueryVariables>(
    GetSkusByIdsDocument,
    options,
  );
}
export type GetSkusByIdsQueryHookResult = ReturnType<
  typeof useGetSkusByIdsQuery
>;
export type GetSkusByIdsLazyQueryHookResult = ReturnType<
  typeof useGetSkusByIdsLazyQuery
>;
export type GetSkusByIdsQueryResult = Apollo.QueryResult<
  GetSkusByIdsQuery,
  GetSkusByIdsQueryVariables
>;
export const GetVoucherCodeDocument = gql`
  query GetVoucherCode($getVoucherCodeId: ID!) {
    getVoucherCode(id: $getVoucherCodeId) {
      id
      code
      orderSkuId
      createdAt
    }
  }
`;

/**
 * __useGetVoucherCodeQuery__
 *
 * To run a query within a React component, call `useGetVoucherCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoucherCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoucherCodeQuery({
 *   variables: {
 *      getVoucherCodeId: // value for 'getVoucherCodeId'
 *   },
 * });
 */
export function useGetVoucherCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVoucherCodeQuery,
    GetVoucherCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVoucherCodeQuery, GetVoucherCodeQueryVariables>(
    GetVoucherCodeDocument,
    options,
  );
}
export function useGetVoucherCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVoucherCodeQuery,
    GetVoucherCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVoucherCodeQuery, GetVoucherCodeQueryVariables>(
    GetVoucherCodeDocument,
    options,
  );
}
export type GetVoucherCodeQueryHookResult = ReturnType<
  typeof useGetVoucherCodeQuery
>;
export type GetVoucherCodeLazyQueryHookResult = ReturnType<
  typeof useGetVoucherCodeLazyQuery
>;
export type GetVoucherCodeQueryResult = Apollo.QueryResult<
  GetVoucherCodeQuery,
  GetVoucherCodeQueryVariables
>;
export const PayOrderDocument = gql`
  query PayOrder($input: PayOrderInput!) {
    payOrder(input: $input)
  }
`;

/**
 * __usePayOrderQuery__
 *
 * To run a query within a React component, call `usePayOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayOrderQuery(
  baseOptions: Apollo.QueryHookOptions<PayOrderQuery, PayOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayOrderQuery, PayOrderQueryVariables>(
    PayOrderDocument,
    options,
  );
}
export function usePayOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayOrderQuery,
    PayOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayOrderQuery, PayOrderQueryVariables>(
    PayOrderDocument,
    options,
  );
}
export type PayOrderQueryHookResult = ReturnType<typeof usePayOrderQuery>;
export type PayOrderLazyQueryHookResult = ReturnType<
  typeof usePayOrderLazyQuery
>;
export type PayOrderQueryResult = Apollo.QueryResult<
  PayOrderQuery,
  PayOrderQueryVariables
>;
