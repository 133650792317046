import React from 'react';
import {
  BadgeItem,
  CardItemWrapper,
  DescriptionBlock,
  DescriptionTypography,
  ProductBlock,
} from 'components/CardItem/CardItem.styled';
import { Maybe, ProductEntity } from 'graphql/generated';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import Image from 'next/image';

interface CardItemProps {
  data: ProductEntity;
  onClick: (val: string) => void;
  favoriteClick?: (val: React.MouseEvent) => void;
  badge: boolean;
  imgToken?: Maybe<string>;
}

export const CardItem = ({
  data,
  onClick = () => {},
  badge = false,
  imgToken,
}: CardItemProps) => {
  const { isENLang } = useCurrentLanguage();
  return (
    <CardItemWrapper
      onClick={() => onClick(data?.id)}
      role={'presentation'}
      component={'article'}
    >
      <ProductBlock>
        {imgToken && (
          <Image
            alt={''}
            src={`${process.env.NEXT_PUBLIC_BASE_URL}/uploads/images/products/${imgToken}`}
            width={1}
            height={1}
            priority={true}
            sizes={'auto'}
          />
        )}
      </ProductBlock>
      <DescriptionBlock>
        <DescriptionTypography variant={'average500'}>
          {isENLang ? data.nameEN : data.nameAR}
        </DescriptionTypography>
        <DescriptionTypography variant={'averageMob450'} color={'#96A0B5'}>
          {isENLang ? data.shortDescriptionEN : data.shortDescriptionAR}
        </DescriptionTypography>
      </DescriptionBlock>

      {/*! WHEN YOU NEED THE CURRENT COMPONENT JUST UNCOMMENT THIS CODE */}
      {/*<FavoriteButton sx={{ position: 'absolute' }} onClick={favoriteClick}>*/}
      {/*  <FavoriteIcon />*/}
      {/*</FavoriteButton>*/}
      {badge ? <BadgeItem /> : null}
    </CardItemWrapper>
  );
};
