import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const StreamingIcon = ({ color = '#96A0B5' }: IconsTypes) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.25 4.5C1.25 2.70507 2.70507 1.25 4.5 1.25H19.5C21.2949 1.25 22.75 2.70507 22.75 4.5V15.5C22.75 17.2949 21.2949 18.75 19.5 18.75H4.5C2.70507 18.75 1.25 17.2949 1.25 15.5V4.5ZM4.5 2.75C3.5335 2.75 2.75 3.5335 2.75 4.5V15.5C2.75 16.4665 3.5335 17.25 4.5 17.25H19.5C20.4665 17.25 21.25 16.4665 21.25 15.5V4.5C21.25 3.5335 20.4665 2.75 19.5 2.75H4.5ZM10.901 7.5567C10.7344 7.46327 10.5288 7.58372 10.5288 7.77477V12.2063C10.5288 12.3982 10.7361 12.5186 10.9028 12.4233L14.8178 10.1868C14.9865 10.0904 14.9856 9.84671 14.816 9.75166L10.901 7.5567ZM9.02876 7.77477C9.02876 6.43741 10.468 5.59429 11.6346 6.24831L15.5496 8.44326C16.7363 9.10862 16.7432 10.8144 15.5618 11.4892L11.6468 13.7258C10.4802 14.3923 9.02876 13.5499 9.02876 12.2063V7.77477ZM1.25 22C1.25 21.5858 1.58579 21.25 2 21.25H14C14.4142 21.25 14.75 21.5858 14.75 22C14.75 22.4142 14.4142 22.75 14 22.75H2C1.58579 22.75 1.25 22.4142 1.25 22ZM16.75 22C16.75 21.5858 17.0858 21.25 17.5 21.25H18C18.4142 21.25 18.75 21.5858 18.75 22C18.75 22.4142 18.4142 22.75 18 22.75H17.5C17.0858 22.75 16.75 22.4142 16.75 22ZM20.75 22C20.75 21.5858 21.0858 21.25 21.5 21.25H22C22.4142 21.25 22.75 21.5858 22.75 22C22.75 22.4142 22.4142 22.75 22 22.75H21.5C21.0858 22.75 20.75 22.4142 20.75 22Z'
        fill={color}
      />
    </svg>
  );
};
