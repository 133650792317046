import { Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import { CloseDialogIcon, SearchIcon } from '../../icons';
import {
  DividerStyled,
  MultiSearchWrapper,
  SelectBlock,
  SelectBlockMobileCss,
  SelectCloseCSS,
} from 'components/uiKit/MultiSearch/MultiSearch.styled';
import {
  CircularProgressCustom,
  CountrySelect,
  SelectCustom,
} from 'components';
import { countriesData } from 'utils';
import { useLaptop } from 'hooks';

interface MultiSearchProps {
  loading: boolean;
  textValue: string;
  setTextValue: (str: string) => void;
  countriesValue: string;
  setCountriesValue: (str: string) => void;
  placeholderTextField: string;
  categoryFilterData: any;
  setCategoryFilterData: (str: string) => void;
  categoryFilterValue: string;
}

export const MultiSearch = ({
  loading = false,
  textValue = '',
  setTextValue = () => {},
  countriesValue,
  setCountriesValue = () => {},
  placeholderTextField = 'Product search...',
  categoryFilterData = [],
  setCategoryFilterData,
  categoryFilterValue,
}: MultiSearchProps) => {
  const [focus, setFocus] = useState(false);
  const border = focus || !!textValue || !!countriesValue;
  const { isOverLaptop } = useLaptop();

  const clearCountryField = () => {
    setCountriesValue('');
  };

  return isOverLaptop ? (
    <>
      <MultiSearchWrapper border={border ? 'border' : ''}>
        <TextField
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          placeholder={placeholderTextField}
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          fullWidth
          size={'medium'}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
          }}
        />
        <Box display={'flex'}>
          <CircularProgressCustom loading={loading} />
        </Box>
        <SelectBlock>
          <DividerStyled />

          <CountrySelect
            options={countriesData}
            value={countriesValue}
            setValue={setCountriesValue}
            setFocus={setFocus}
          />

          {countriesValue && (
            <SelectCloseCSS onClick={clearCountryField}>
              <CloseDialogIcon size={'18'} />
            </SelectCloseCSS>
          )}
        </SelectBlock>
      </MultiSearchWrapper>
    </>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <SelectBlockMobileCss>
        <CountrySelect
          options={countriesData}
          value={countriesValue}
          setValue={setCountriesValue}
          setFocus={setFocus}
          border={true}
        />
        {countriesValue && (
          <SelectCloseCSS onClick={clearCountryField}>
            <CloseDialogIcon size={'18'} />
          </SelectCloseCSS>
        )}
      </SelectBlockMobileCss>
      <SelectCustom
        sx={{ marginBottom: '12px' }}
        option={categoryFilterData}
        setValue={setCategoryFilterData}
        value={categoryFilterValue}
      />
      <TextField
        size={'small'}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        placeholder={placeholderTextField}
        sx={{ '.MuiInputBase-root': { height: '42px' } }}
        disabled={false}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
    </Box>
  );
};
