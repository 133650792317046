import React from 'react';
import { BinancePayIcon } from '../icons/BinancePayIcon';
import { useTranslation } from 'next-i18next';
import { TabBtn, TabsBox } from 'components/TabsCustom/TabsCustom.styled';

interface TabsCustomProps {
  tabNum: string;
}

export const TabsCustom = ({ tabNum = '1' }: TabsCustomProps) => {
  const { t } = useTranslation();

  return (
    <TabsBox>
      <TabBtn target={tabNum === '1' ? 'true' : 'false'}>
        {t('app.cart.default_wallet')}
      </TabBtn>
      <TabBtn target={tabNum === '2' ? 'true' : 'false'}>
        <BinancePayIcon />
      </TabBtn>
    </TabsBox>
  );
};
