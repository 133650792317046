import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(({ theme }) => {
  return {
    border: '1px solid #ECEFF4',
    borderRadius: '8px',
    padding: '19px 20px 15px 20px',
    [theme.breakpoints.down('laptop')]: {
      padding: '12px 15px 11px 15px',
    },
  };
});
export const Flex = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
  };
});
