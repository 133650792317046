import shallow from 'zustand/shallow';
import { useCartStore as useCartStoreDef, useCartStoreProps } from 'Store/cart';
import { StateSelector } from 'zustand';
import {
  useInnerHeaderShowStore as useInnerHeaderShowStoreDef,
  useInnerHeaderShowStoreProps,
} from 'Store/InnerHeaderShow';

import {
  useAuthVariablesStore as useAuthVariablesStoreDef,
  useAuthVariablesStoreProps,
} from 'Store/authVariables';
import {
  useIsDisabledProductRowStore as useIsDisabledProductRowStoreDef,
  useIsDisabledProductRowStoreProps,
} from 'Store/isDisabledProductRow';

export const useCartStore: <T>(
  selector: StateSelector<useCartStoreProps, T>,
) => T = (selector) => useCartStoreDef(selector, shallow);

export const useInnerHeaderShowStore: <T>(
  selector: StateSelector<useInnerHeaderShowStoreProps, T>,
) => T = (selector) => useInnerHeaderShowStoreDef(selector, shallow);

export const useAuthVariablesStore: <T>(
  selector: StateSelector<useAuthVariablesStoreProps, T>,
) => T = (selector) => useAuthVariablesStoreDef(selector, shallow);

export const useIsDisabledProductRowStore: <T>(
  selector: StateSelector<useIsDisabledProductRowStoreProps, T>,
) => T = (selector) => useIsDisabledProductRowStoreDef(selector, shallow);
