import * as React from 'react';
import Box from '@mui/material/Box';
import { ChevronDownIcon } from '../../icons';
import {
  FormLabel,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import { ImageBlock, WrapperStyled } from './CountrySelect.styled';
import { useField, useFormikContext } from 'formik';
import { ErrorMessageS } from '../TextFieldFormik/TextFieldFormik.styled';
import { DefaultTFuncReturn } from 'i18next';
import Image from 'next/image';

interface CountrySelectFormikProps {
  fullWidth?: boolean;
  options: any[];
  name: string;
  sx?: SxProps;
  isOverLaptop?: boolean;
  label?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
}

export const CountrySelectFormik = ({
  fullWidth = true,
  options = [],
  name,
  sx,
  isOverLaptop,
  label,
  placeholder,
}: CountrySelectFormikProps) => {
  const [field, meta] = useField(name);
  const { handleChange } = useFormikContext();

  const hasError = Boolean(meta.error && meta.touched);

  return (
    <WrapperStyled haserror={hasError ? 'true' : 'false'}>
      {label ? (
        <FormLabel
          htmlFor={name}
          sx={{ marginBottom: isOverLaptop ? '6px' : '4px' }}
        >
          <Typography variant={isOverLaptop ? 'tf' : 'tfs'}>{label}</Typography>
        </FormLabel>
      ) : null}
      <Select
        displayEmpty
        id={name}
        size={isOverLaptop ? 'medium' : 'small'}
        name={name}
        renderValue={
          field.value !== ''
            ? undefined
            : () => (
                <Typography variant={'average400'} color={'#96A0B5'}>
                  {placeholder}
                </Typography>
              )
        }
        IconComponent={({ ...rest }) => <ChevronDownIcon {...rest} />}
        value={field.value}
        fullWidth={fullWidth}
        onChange={handleChange}
        sx={{
          ...sx,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 50,
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },

          PaperProps: {
            sx: {
              background: '#ffffff',
              boxShadow: '0px 20px 64px -10px rgba(55, 55, 72, 0.3)',
              maxHeight: '350px',
              overflowY: 'auto',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                borderRadius: '2px',
                width: '15px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#96A0B5',
                borderRadius: '10px',
                border: '5px solid #fff',
              },
            },
          },
        }}
      >
        {options?.map((option, index) => {
          const src = `https://flagcdn.com/w20/${option.code.toLowerCase()}.png`;
          return (
            <MenuItem key={index} value={option.code}>
              <Box display={'flex'}>
                <ImageBlock>
                  <Image
                    width={20}
                    height={20}
                    src={src}
                    loader={() => src}
                    alt={''}
                    unoptimized
                  />
                </ImageBlock>
                <Box display={'flex'} fontWeight={300}>
                  {option.label}
                </Box>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
      {hasError && <ErrorMessageS>{meta.error || ''}</ErrorMessageS>}
    </WrapperStyled>
  );
};
