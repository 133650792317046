import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const HomeIcon = ({ color = '#96A0B5' }: IconsTypes) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_452_7285)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.1339 2.85205C12.9106 1.78711 11.0894 1.78711 9.86606 2.85206L3.02266 8.80948C2.53179 9.2368 2.25 9.85572 2.25 10.5065V18.5C2.25 20.2949 3.70507 21.75 5.5 21.75H18.5C20.2949 21.75 21.75 20.2949 21.75 18.5V10.5065C21.75 9.85572 21.4682 9.2368 20.9773 8.80948L14.1339 2.85205ZM8.88117 1.72069C10.6691 0.16423 13.3309 0.164229 15.1188 1.72069L21.9622 7.67812C22.7803 8.39031 23.25 9.42185 23.25 10.5065V18.5C23.25 21.1234 21.1234 23.25 18.5 23.25H5.5C2.87665 23.25 0.75 21.1234 0.75 18.5V10.5065C0.75 9.42185 1.21966 8.39031 2.03777 7.67812L8.88117 1.72069ZM8.45 16.5235C8.45 16.1092 8.78579 15.7735 9.2 15.7735H14.8C15.2142 15.7735 15.55 16.1092 15.55 16.5235C15.55 16.9377 15.2142 17.2735 14.8 17.2735H9.2C8.78579 17.2735 8.45 16.9377 8.45 16.5235Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_452_7285'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
