import React from 'react';
import { Box, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { optionsMenuType } from 'types/optionsTypes';

type MenuCustomT = {
  anchorElUser: null | HTMLElement;
  handleCloseUserMenu: (e: React.MouseEvent) => void;
  options?: optionsMenuType[];
  onClick?: () => void;
};

export const MenuCustom = ({
  anchorElUser,
  handleCloseUserMenu,
  options = [],
  onClick,
}: MenuCustomT) => {
  return (
    <Menu
      sx={{ mt: '45px' }}
      id='menu-appbar'
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClick={onClick}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          boxShadow: '0px 20px 64px -10px rgba(55, 55, 72, 0.3)',
          width: '188px',
        },
      }}
      MenuListProps={{
        sx: {
          '& .MuiButtonBase-root': {
            paddingInlineStart: '22px',
          },
        },
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {options.map((option, i: number) => {
        return [
          option.label === 'Logout' && <Divider />,
          <MenuItem key={i} id={option.value} onClick={handleCloseUserMenu}>
            <Box display={'flex'} sx={{ marginInlineEnd: '14px' }}>
              {option.icon}
            </Box>
            <Typography textAlign='center'>{option.label}</Typography>
          </MenuItem>,
        ];
      })}
    </Menu>
  );
};
