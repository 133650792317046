import React from 'react';
import { CircularProgress, Fade } from '@mui/material';

interface CircularProgressCustomProps {
  loading: boolean;
  color?: string;
  size?: string;
}

export const CircularProgressCustom = ({
  loading,
  color = '#96A0B5',
  size = '24px',
}: CircularProgressCustomProps) => {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '800ms' : '0ms',
        width: size,
        height: size,
      }}
      unmountOnExit
    >
      <CircularProgress sx={{ color: color }} />
    </Fade>
  );
};
