import { createTheme } from '@mui/material';
import * as foundations from './foundations';
import React from 'react';

export const theme = createTheme({
  ...foundations,
  components: {
    MuiUseMediaQuery: {},
    MuiDialog: {
      styleOverrides: {
        root: {},
        container: {},
        paper: {
          borderRadius: '8px',
          boxShadow: '0px 32px 64px -20px rgba(55, 55, 72, 0.2)',
          background: '#FFFFFF',
          width: '100%',
          maxWidth: '560px',
          '&::-webkit-scrollbar': {
            borderRadius: '2px',
            width: '15px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(150,160,181,0.4)',
            borderRadius: '10px',
            border: '5px solid #fff',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            padding: 0,
          },
        },
      ],
    },
    MuiDivider: {
      defaultProps: {
        variant: 'fullWidth',
      },
      variants: [
        {
          props: { variant: 'fullWidth' },
          style: {
            height: 'auto',
            flexShrink: 1,
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'large',
      },
      variants: [
        {
          props: {
            variant: 'contained',
          },
          style: {
            position: 'relative',
            // background: '#4663F4',
            padding: '8px 20px',
            gap: '4px',
            boxShadow: '0px 8px 16px rgba(70, 99, 244, 0.15)',
            color: '#ffffff',
            ':hover': {
              // background: '#2B43B7',
            },
            '&:disabled': {
              backgroundColor: '#96A0B5',
              opacity: 0.6,
              color: '#ffffff',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            position: 'relative',
            border: '1px solid #4663F4',
            color: '#4663F4',
            fontWeight: 300,
            ':hover': {
              borderColor: '#4663F4',
            },
          },
        },
        {
          props: {
            variant: 'back',
          },
          style: {
            position: 'relative',
            border: '1px solid rgba(150, 160, 181, 0.4)',
            color: '#373748',
            fontWeight: 300,
            ':hover': {
              borderColor: 'rgba(150, 160, 181, 0.4)',
            },
          },
        },
        {
          props: {
            variant: 'tab',
          },
          style: {
            position: 'relative',
            border: 'none',
            background: '#FFFFFF',
            color: '#373748',
            fontWeight: 300,
            fontSize: '13px',
            lineHeight: '20px',
            ':hover': {
              background: '#FFFFFF',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'auto',
          },
          textTransform: 'none',
          fontSize: '15px',
          lineHeight: '22px',
          borderRadius: '6px',
          whiteSpace: 'nowrap',
          width: 'fit-content',
          '.MuiButton-endIcon': {
            marginLeft: 0,
            marginInlineStart: '14px',
          },
          '.MuiButton-startIcon': {
            marginRight: 0,
            marginInlineEnd: '8px',
          },
          svg: {
            pointerEvents: 'none',
          },
        },
        sizeLarge: {
          height: '48px',
          fontWeight: 500,
        },
        sizeMedium: {
          height: '40px',
          fontWeight: 400,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            height: '40px',
            borderRadius: '6px',
            overflow: 'hidden',
            border: '1px solid rgba(150, 160, 181, 0.4)',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            color: '#373748',
            fontWeight: 300,
            lineHeight: '22px',
            '& .MuiSelect-select': {
              // padding: '8px 10px',
            },
            '& .MuiBox-root': {
              display: 'flex',
              alignItems: 'center',
              // marginInlineEnd: '8px',
              // '& :first-of-type': {
              //   marginInlineEnd: '8px',
              // },
            },
            '& .MuiSelect-icon': {
              insetInlineEnd: '15px',
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '& .MuiSelect-select': {
              paddingTop: '12px',
              paddingBottom: '12px',
            },
            overflow: 'hidden',
            height: '48px',
            borderRadius: '6px',
            border: '1px solid rgba(150, 160, 181, 0.4)',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            color: '#373748',
            fontWeight: 300,
            lineHeight: '22px',
            '& .MuiSelect-icon': {
              insetInlineEnd: '20px',
              // insetInlineStart: '15px',
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            height: '56px',
            borderRadius: '6px',
            border: '1px solid rgba(150, 160, 181, 0.4)',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            color: '#373748',
            fontWeight: 300,
            lineHeight: '22px',
          },
        },
      ] as any,
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            background: '#F7F9FC',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'medium',
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            input: {
              fontWeight: 300,
              fontSize: '15px',
              lineHeight: '22px',
              color: '#373748',
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '16px',
              paddingInlineEnd: '14px',
              height: '48px',
              '&.MuiInputBase-adornedStart svg': {
                marginInlineEnd: '16px',
              },
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            input: {
              fontWeight: 300,
              fontSize: '14px',
              lineHeight: '21px',
              color: '#373748',
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '16px',
              height: '42px',
              padding: '0 14px',
              '&.MuiInputBase-adornedStart svg': {
                marginInlineEnd: '10px',
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& [placeholder]::placeholder': {
            color: '#96A0B5',
            fontWeight: 300,
            fontSize: '15px',
            lineHeight: '22px',
            opacity: 1,
          },
          '.MuiInputBase-root': {
            borderRadius: '6px',
            '.MuiOutlinedInput-notchedOutline': {
              border: '1px solid rgba(150, 160, 181, 0.4)',
            },
            ':hover .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #96A0B5',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #96A0B5',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #F03758',
            },
            '& .MuiInputAdornment-root': {
              marginRight: 0,
            },
            paddingInlineStart: '20px',
            svg: {
              // marginInlineEnd: '20px',
            },
            '&.Mui-disabled': {
              borderColor: 'rgba(150, 160, 181, 0.4)',
              background: '#F7F9FC',
            },
          },
        },
      },
    },
    MuiAccordion: {
      variants: [
        {
          props: {
            variant: 'elevation',
          },
          style: {
            boxShadow: '0px 16px 30px -10px rgba(150, 160, 181, 0.15)',
            borderRadius: '8px',
            '&:before': {
              display: 'none',
            },
            '&:not(:last-child)': {
              borderBottom: 0,
            },
          },
        },
      ],
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 30px',
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          gap: '12px',
          '& .MuiAccordionSummary-content': {
            margin: '24px 0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '24px 30px',
          borderTop: '1px solid rgba(150, 160, 181, 0.4);',
        },
      },
    },
    MuiLinearProgress: {
      variants: [
        {
          props: { variant: 'determinate' },
          style: { height: '3px', background: '#ECEFF4', borderRadius: '30px' },
        },
      ],
    },
    MuiPagination: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            // '.Mui-selected': {
            //   background: '#4663F4',
            // },
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
  },
  mixins: {
    centerXY: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    centerY: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  palette: {
    primary: {
      main: '#4663F4',
    },
    secondary: {
      main: '#30D05A',
    },
    grey: {
      500: '#EFEFF7',
    },
    purple: {
      500: '#D0C1EC',
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    back: true;
    tab: true;
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    centerXY?: React.CSSProperties;
    centerY?: React.CSSProperties;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    purple: Palette['primary'];
  }

  interface PaletteOptions {
    purple: PaletteOptions['primary'];
  }
}
