import React from 'react';
import { Typography } from '@mui/material';
import { Wrapper } from 'components/PurchaseCodeCardRow/PurchaseCodeCardRow.styled';
import { DefaultTFuncReturn } from 'i18next';

interface PurchaseCodeCardRowProps {
  title: string | DefaultTFuncReturn;
  subTitle: string | DefaultTFuncReturn;
}

export const PurchaseCodeCardRow = ({
  title,
  subTitle,
}: PurchaseCodeCardRowProps) => {
  return (
    <Wrapper>
      <Typography variant={'titleForm'}>{title}</Typography>
      <Typography variant={'min500'}>{subTitle}</Typography>
    </Wrapper>
  );
};
