import React, { useState } from 'react';
import { ButtonFW, FormWrapper } from 'components/Form/SignInForm.styled';
import { useCartContext, useModalContext } from 'context';
import { useTranslation } from 'next-i18next';
import { TextFieldFormik } from 'components/uiKit';
import { Form, Formik } from 'formik';
import { ArrowIcon } from '../../icons';
import { useRouter } from 'next/router';
import { MODAL_NAME, AUTH_PATHS, getErrorName } from 'utils';
import { useLaptop } from 'hooks';
import { AlertCustom, CircularProgressCustom } from 'components';
import { FormHeader } from '..';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useResetPasswordMutation } from 'graphql/generated';
import { forgotPasswordSchema } from 'validation';
import { useAuthVariablesStore } from 'Store';

export const ForgotPasswordForm = () => {
  const [error, setError] = useState(false);
  const router = useRouter();
  const { isOverLaptop } = useLaptop();

  const { onOpenModal } = useModalContext();
  const { isProductInTheCart } = useCartContext();
  const { t } = useTranslation();
  const { isENLang } = useCurrentLanguage();

  const [setEmail] = useAuthVariablesStore((state) => [state.setEmail]);

  const [resetPasswordMutation, { data, loading, error: err }] =
    useResetPasswordMutation();

  const handleSubmit = async (values: { email: string }) => {
    if (data || loading) return;
    setError(false);
    await resetPasswordMutation({
      variables: {
        input: {
          email: values.email,
        },
      },
      onCompleted: (data) => {
        setEmail(values.email);
        if (!data.resetPassword) {
          setError(true);
        } else {
          // * if mobile and cart is empty
          if (!isOverLaptop || !isProductInTheCart) {
            router.push(AUTH_PATHS.confirmPassword);
          } else {
            onOpenModal(MODAL_NAME.confirmPasswordForm);
          }
        }
      },
    });
  };

  return (
    <FormWrapper>
      <FormHeader
        isOverLaptop={isOverLaptop}
        title={t('app.auth.forgot_password')}
        subTitle={t('app.auth.pe_your_email_address')}
      />
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={forgotPasswordSchema(isENLang)}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextFieldFormik
            name={'email'}
            label={t('app.auth.email_address')}
            placeholder={t('app.auth.ey_email_address')}
            size={!isOverLaptop ? 'small' : 'medium'}
          />
          <ButtonFW
            type={'submit'}
            endIcon={
              loading ? (
                <CircularProgressCustom loading={true} color={'#fff'} />
              ) : (
                <ArrowIcon />
              )
            }
            size={!isOverLaptop ? 'medium' : 'large'}
          >
            {t('app.send')}
          </ButtonFW>
        </Form>
      </Formik>
      {error || err ? (
        <AlertCustom type={'error'} mt={isOverLaptop ? '24px' : '16px'}>
          {error
            ? getErrorName('notUserOrNotVerify')
            : getErrorName(err?.graphQLErrors[0].message || '')}
        </AlertCustom>
      ) : null}
    </FormWrapper>
  );
};
