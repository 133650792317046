import { useEffect, useState } from 'react';

export const useCount = () => {
  const [start, setStart] = useState(false);
  const [countDown, setCountDown] = useState(30);

  useEffect(() => {
    if (!start) return;
    const interval = setInterval(() => {
      if (countDown <= 0) {
        clearInterval(interval);
        setStart(false);
        return;
      }
      if (countDown <= 0) {
        clearInterval(interval);
      }

      setCountDown((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown, start]);

  return { countDown, setStart, start, setCountDown };
};
