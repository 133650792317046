import React from 'react';
import { IconsTypes } from 'types/baseTypes';

export const NotFoundProductIcon = ({ size = '80', ...props }: IconsTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M68.75 17.9087H15C12.9288 17.9087 11.25 19.5874 11.25 21.6587V29.1587H68.75V17.9087Z'
        fill='url(#paint0_linear_217_27995)'
      />
      <path
        d='M68.75 15.7998C68.75 13.7286 67.0712 12.0498 65 12.0498C59.7137 12.0498 50.1837 12.0498 45.4012 12.0498C43.6075 12.0498 41.95 13.0111 41.06 14.5686C39.5837 17.1536 37.5 20.7998 37.5 20.7998H68.75V15.7998Z'
        fill='url(#paint1_linear_217_27995)'
      />
      <path
        d='M11.25 21.4224H68.75V23.7511H11.25V21.4224Z'
        fill='url(#paint2_linear_217_27995)'
      />
      <path
        d='M72.96 28.6385C73.1588 27.206 72.7275 25.7572 71.7788 24.6672C70.8288 23.576 69.4538 22.9497 68.0075 22.9497C55.2625 22.9497 24.7375 22.9497 11.9925 22.9497C10.5463 22.9497 9.17125 23.576 8.22125 24.6672C7.2725 25.7572 6.84125 27.206 7.04 28.6385C8.3075 37.7635 10.7675 55.4735 11.9012 63.6385C12.245 66.1097 14.3575 67.9497 16.8538 67.9497H63.1462C65.6425 67.9497 67.755 66.1097 68.0988 63.6385C69.2325 55.4735 71.6925 37.7635 72.96 28.6385Z'
        fill='url(#paint3_linear_217_27995)'
      />
      <path
        d='M7.46 31.6635C7.55375 30.6222 7.97125 29.6272 8.67 28.8247C9.61875 27.7347 10.9937 27.1085 12.44 27.1085H67.56C69.0063 27.1085 70.3813 27.7347 71.33 28.8247C72.0287 29.6272 72.4463 30.6222 72.54 31.6635L72.96 28.6385C73.1588 27.206 72.7275 25.7572 71.7788 24.6672C70.8288 23.576 69.4538 22.9497 68.0075 22.9497C55.2625 22.9497 24.7375 22.9497 11.9925 22.9497C10.5463 22.9497 9.17125 23.576 8.22125 24.6672C7.2725 25.7572 6.84125 27.206 7.04 28.6385L7.46 31.6635Z'
        fill='#E9F3FC'
      />
      <path
        d='M34.3763 55.4824C34.3763 54.1024 33.2575 52.9824 31.8763 52.9824C29.1025 52.9824 24.6512 52.9824 21.8763 52.9824C20.4963 52.9824 19.3763 54.1024 19.3763 55.4824V57.9824C19.3763 59.3637 20.4963 60.4824 21.8763 60.4824H31.8763C33.2575 60.4824 34.3763 59.3637 34.3763 57.9824C34.3763 57.1787 34.3763 56.2874 34.3763 55.4824Z'
        fill='url(#paint4_linear_217_27995)'
      />
      <path
        d='M53.125 32C45.8812 32 40 37.8812 40 45.125C40 52.3687 45.8812 58.25 53.125 58.25C60.3687 58.25 66.25 52.3687 66.25 45.125C66.25 37.8812 60.3687 32 53.125 32ZM53.125 35.75C58.2987 35.75 62.5 39.9512 62.5 45.125C62.5 50.2987 58.2987 54.5 53.125 54.5C47.9513 54.5 43.75 50.2987 43.75 45.125C43.75 39.9512 47.9513 35.75 53.125 35.75Z'
        fill='url(#paint5_linear_217_27995)'
      />
      <path
        d='M59.9612 54.1885L71.6212 65.8485C72.3537 66.581 73.5412 66.581 74.2737 65.8485C75.005 65.1172 75.005 63.9285 74.2737 63.1972L62.6137 51.5372C61.8812 50.806 60.6937 50.806 59.9612 51.5372C59.23 52.2685 59.23 53.4572 59.9612 54.1885Z'
        fill='url(#paint6_linear_217_27995)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_217_27995'
          x1='11.25'
          y1='21.0837'
          x2='68.75'
          y2='21.0837'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CADCF0' />
          <stop offset='1' stopColor='#A4BBDB' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_217_27995'
          x1='5.92'
          y1='21.5973'
          x2='69.0813'
          y2='21.5973'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CADCF0' />
          <stop offset='1' stopColor='#A4BBDB' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_217_27995'
          x1='11.25'
          y1='22.5861'
          x2='68.75'
          y2='22.5861'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A4BBDB' />
          <stop offset='1' stopColor='#8DA3BE' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_217_27995'
          x1='6.9925'
          y1='45.4497'
          x2='73.0075'
          y2='45.4497'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E9F3FC' />
          <stop offset='1' stopColor='#CADCF0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_217_27995'
          x1='19.3763'
          y1='52.9824'
          x2='34.3763'
          y2='60.4824'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#559AFF' />
          <stop offset='1' stopColor='#4663F4' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_217_27995'
          x1='28.4863'
          y1='48.1'
          x2='70.105'
          y2='48.1'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#559AFF' />
          <stop offset='1' stopColor='#4663F4' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_217_27995'
          x1='21.6425'
          y1='48.9272'
          x2='74.8225'
          y2='48.9272'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#559AFF' />
          <stop offset='1' stopColor='#4663F4' />
        </linearGradient>
      </defs>
    </svg>
  );
};
